<template>
<el-dialog
  class="reviewer-dialog"
  :model-value="isReviewDialogVisible"
  width="800px"
  height="600px"
  title="Review us on G2"
  @close="handleClose"
  align-center>
  <iframe class="reviewer-iframe" :src="reviewUrl" title="Review us on G2" @load="handleLoad"></iframe>
</el-dialog>
</template>
<script>
import { useReviewStore } from '../../stores/review';
import API from '../../services/api'
import { mapState, mapActions } from 'pinia';
import { DateTime } from 'luxon';
export default{
    name: 'reviewerDialog',
    props: {
      isReviewDialogVisible: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
        iframeLoaded: false,
      }
    },
    computed: {
      ...mapState(useReviewStore, {
        reviewUrl: state => state.reviewUrl,
        isManuallyInitiated: state => state.isManuallyInitiated,
        dismissCount: state => state.dismissCount,
      })
    },
    methods: {
      ...mapActions(useReviewStore, ['SET_REVIEW_STATUS', 'SET_IS_REVIEWED', 'UPDATE_DISMISS_LOCALLY']),
      async handleClose(){
        try {
          if (!this.isManuallyInitiated && this.dismissCount < 3){
            let userId = JSON.parse(localStorage.getItem('user'))?.user_id;
            let dismissedOn = DateTime.utc().toISO({ includeOffset: false })
            let patchData = {
              review_dismissed_on: dismissedOn,
              review_dismissed_count: this.dismissCount + 1
            }
            await API.USERS.PATCH_USER_DATA(userId, patchData)
            this.UPDATE_DISMISS_LOCALLY(this.dismissCount + 1, dismissedOn)
          }
          this.iframeLoaded = false
          this.reviewUrl = ''
        } catch (err) {
          console.error(err)
        }
        this.SET_REVIEW_STATUS(false)
      },
      handleLoad(){
        if (this.iframeLoaded){
          const patchData = {
            is_review_collected: true
          }
          try {
            API.USERS.PATCH_USER_DATA(JSON.parse(localStorage.getItem('user'))?.user_id, patchData)
            this.SET_IS_REVIEWED(true)
          } catch (err) {
            console.error(err)
          }
        }
        this.iframeLoaded = true
      }
    }
}
</script>
<style scoped>
.reviewer-iframe{
    width: 600px;
}
</style>