import symbols from "../../pages/currency-symbol-name-map";
function getCurrencySymbol(countryCode) {
  try {
    let locale = COUNTRY_LOCALES[countryCode];

    if (!locale) {
      console.warn(`Locale not found for country code: '${countryCode}'. Defaulting to 'en-US'..`)
      locale = 'en-US'
    }
    let options = {};
    options.style = "currency";
    options.currency = CURRENCY_CODES[countryCode];
    options.maximumFractionDigits = 0;
    const formatter = new Intl.NumberFormat(locale, options);
    const formattedNumber = formatter.format(0);
    const currencySymbol = formattedNumber.replace(/[\d,]+/g, ""); 
    return currencySymbol;
  } catch (error) {
    console.error("Error getting currency symbol:", error);
    return null;
  }
}
function getLocaleAbbreviations(country, amount, isLabel, isNotRounded,twoDecimals) {
  //isLabel - get rounded values upto 1 decimal point
  //isNotRounded - get unrounded value upto 1 decimal point
  let options = {};
  if (isLabel) {
    options = {
      notation: "compact",
      compactDisplay: "short",
    };
  } else if (isNotRounded) {
    if (amount > 999) {
      options = {
        notation: "compact",
        compactDisplay: "short",
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      };
    } else {
      options = {
        notation: "compact",
        compactDisplay: "short",
      };
    }
  }else if(twoDecimals) {
    options = {
      notation: "compact",
      compactDisplay: "short",
      maximumFractionDigits: 2,
    };
  }
  else {
    options = {
      notation: "compact",
      compactDisplay: "short",
      maximumFractionDigits: 1,
    };
  }
  let result;
  if (country === "INR" && amount < 99950) {
    result = new Intl.NumberFormat("en-US", {
      ...options,
    }).format(amount);
  } else {
    country = separateFirstTwo(country);
    result = new Intl.NumberFormat(COUNTRY_LOCALES[country], {
      ...options,
    }).format(amount);
  }

  if (result.toString().split(".")[1]) {
    if (isNotRounded) {
      return formatGrand(getOneDigitAfterDecimalAndNonNumeric(result));
    }
  }
  return formatGrand(result);
}

function formatGrand(str) {
  str = str.toString();
  if (str.charAt(str.length - 1) === "K") {
    str = str.split("");
    str[str.length - 1] = "k";
    return str.join("");
  }
  return str;
}

//returns with currency symbol
function getFormattedCurrencyComas(currencyCode, amount) {
  let country = separateFirstTwo(currencyCode);
  let countryLocale = COUNTRY_LOCALES[country]
  if(currencyCode=='ANG'){
    countryLocale = "nl-CW"; // Curaçao
  }
  let result = new Intl.NumberFormat(countryLocale || "en-US", {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 0,
  }).format(amount);
  if (result.toString().split(".")[1]) {
    return getOneDigitAfterDecimal(result);
  }
  return result;
}

//returns without currency symbol
function getFormattedComas(country, amount) {
  country = separateFirstTwo(country);
  return new Intl.NumberFormat(COUNTRY_LOCALES[country], {
    minimumFractionDigits: 0,
  }).format(amount);
}

function getFormattedComasWithDecimal(country, amount, num) {
  country = separateFirstTwo(country);
  return new Intl.NumberFormat(COUNTRY_LOCALES[country], {
    minimumFractionDigits: num,
  }).format(amount);
}
function getFormattedComasWithMaxDecimal(country, amount, num,maxNum) {
  country = separateFirstTwo(country);
  return new Intl.NumberFormat(COUNTRY_LOCALES[country], {
    minimumFractionDigits: num,
    maximumFractionDigits:maxNum,
  }).format(amount);
}

function getFormattedNumberWithCurrency(num, isDecimal) {
  const newNum = num.split(".")[0];
  // num = num.toFixed(2);
  // let cc = this.countryCode
  //   ? getCurrencySymbol(this.countryCode.currency_code)
  //   : "USD";
  let formattedFirst = getFormattedComas("USD", newNum);

  let isDot = formattedFirst.toString().includes(".");

  if (!isDot && isDecimal) return formattedFirst;
  return formattedFirst;
}

function separateFirstTwo(country) {
  if (country.length > 2) {
    country = country.split("");
    country.pop();
    country = country.join("").toUpperCase();
  }
  return country;
}
function getOneDigitAfterDecimalAndNonNumeric(str) {
  const match1 = str.match(/\.(\d)[^\d]*/);
  const match2 = str.match(/\.(\d+)(\D*)$/);
  if (match1 && match2) {
    const digitAfterDecimal = match1[1];
    const nonNumericCharacters = match2[2];
    let digitBeforeDecimal = str.split(".")[0];
    if (digitAfterDecimal === "0") {
      return `${digitBeforeDecimal}${nonNumericCharacters}`;
    }
    return `${digitBeforeDecimal}.${digitAfterDecimal}${nonNumericCharacters}`;
  }
  return null;
}
function getOneDigitAfterDecimal(str) {
  const match1 = str.match(/\.(\d)[^\d]*/);
  if (match1) {
    const digitAfterDecimal = match1[1];
    let digitBeforeDecimal = str.split(".")[0];
    if (digitAfterDecimal === "0") {
      return `${digitBeforeDecimal}`;
    }
    return `${digitBeforeDecimal}.${digitAfterDecimal}`;
  }
  return null;
}


function formatNumber(value, style, countryCode= 'US', decimalPoints = 2) {
  let options = {};
  if(value == null) {
    return "-";
  }
  // Ensure input value is a valid numeric or string value
  if (typeof value === 'string') {
    value = parseFloat(value.replace(/[^\d.]/g, ''));
  } else if (typeof value === 'number') {
    value = parseFloat(value);
  } else {
    throw new Error("Invalid value type. value should be a string, number, or float.");
  }

  // Define predefined style rules based on the 'style' parameter
  switch (style) {
    case 'decimal':
      options.style = 'decimal';
      options.minimumFractionDigits = decimalPoints;
      options.maximumFractionDigits = decimalPoints;
      break;
    case 'percent':
      options.minimumFractionDigits = decimalPoints;
      options.maximumFractionDigits = decimalPoints;
      options.style = 'percent';
      break;
    case 'currency':
      options.style = 'currency';
      options.minimumFractionDigits = decimalPoints;
      options.maximumFractionDigits = decimalPoints;
      options.currency = CURRENCY_CODES[countryCode]; // You can set the default currency here
      break;
    case 'compact':
      // Use 'compact' style provided by Intl.NumberFormat
      options.notation = 'compact';
      options.minimumFractionDigits = 0;
      options.maximumFractionDigits = 1;
      break;
    default:
      throw new Error(`Invalid style: '${style}'. Supported styles are 'decimal', 'percent', 'currency', and 'compact'.`);
  }

  // Get the locale based on the provided country code
  let locale = COUNTRY_LOCALES[countryCode];

  // Fallback for if locale is not found, using en-US as the default locale
  if (!locale) {
    console.warn(`Locale not found for country code: '${countryCode}'. Defaulting to 'en-US'..`)
    locale = 'en-US'
  }

  // For using only latin numerals and not other language numerals
  options.numberingSystem = 'latn'

  // Create an Intl.NumberFormat object with the specified locale and style options
  const formatter = new Intl.NumberFormat(locale, options);

  // Format the number using the formatter
  const formattedNumber = formatter.format(value);
  return formattedNumber;
}

function currencyAndNumberFormat(country_code,amount){
let currency_code= CURRENCY_CODES[country_code];
let currency_symbole=symbols[currency_code];
let comma_formate=getFormattedComas(country_code,amount)
let currencyNumber=currency_symbole+comma_formate
return currencyNumber;

}

export {
  getLocaleAbbreviations,
  getFormattedComas,
  getFormattedCurrencyComas,
  getFormattedNumberWithCurrency,
  getFormattedComasWithDecimal,
  getFormattedComasWithMaxDecimal,
  formatNumber,
  currencyAndNumberFormat,
  getCurrencySymbol
};

const COUNTRY_LOCALES = {
  AF: "ps-AF", // Afghanistan
  AL: "sq-AL", // Albania
  DZ: "ar-DZ", // Algeria
  AS: "en-US", // American Samoa
  AD: "ca-AD", // Andorra
  AO: "pt-AO", // Angola
  AI: "en-AI", // Anguilla
  AQ: "en-US", // Antarctica
  AG: "en-AG", // Antigua and Barbuda
  AR: "es-AR", // Argentina
  AM: "hy-AM", // Armenia
  AW: "nl-AW", // Aruba
  AU: "en-AU", // Australia
  AT: "de-AT", // Austria
  AZ: "az-AZ", // Azerbaijan
  BS: "en-BS", // Bahamas
  BH: "ar-BH", // Bahrain
  BD: "bn-BD", // Bangladesh
  BB: "en-BB", // Barbados
  BY: "be-BY", // Belarus
  BE: "nl-BE", // Belgium
  BZ: "en-BZ", // Belize
  BJ: "fr-BJ", // Benin
  BM: "en-BM", // Bermuda
  BT: "dz-BT", // Bhutan
  BO: "es-BO", // Bolivia
  BQ: "nl-BQ", // Bonaire, Sint Eustatius and Saba
  BA: "bs-BA", // Bosnia and Herzegovina
  BW: "en-BW", // Botswana
  BR: "pt-BR", // Brazil
  BN: "ms-BN", // Brunei Darussalam
  BG: "bg-BG", // Bulgaria
  BF: "fr-BF", // Burkina Faso
  BI: "fr-BI", // Burundi
  CV: "pt-CV", // Cabo Verde
  KH: "km-KH", // Cambodia
  CM: "fr-CM", // Cameroon
  CA: "en-CA", // Canada
  KY: "en-KY", // Cayman Islands
  CF: "fr-CF", // Central African Republic
  TD: "fr-TD", // Chad
  CL: "es-CL", // Chile
  CN: "zh-CN", // China
  CO: "es-CO", // Colombia
  KM: "ar-KM", // Comoros
  CG: "fr-CG", // Congo
  CD: "fr-CD", // Congo, DRC
  CK: "en-CK", // Cook Islands
  CR: "es-CR", // Costa Rica
  HR: "hr-HR", // Croatia
  CU: "es-CU", // Cuba
  CW: "nl-CW", // Curaçao
  CY: "el-CY", // Cyprus
  CZ: "cs-CZ", // Czech Republic
  DK: "da-DK", // Denmark
  DJ: "fr-DJ", // Djibouti
  DM: "en-DM", // Dominica
  DO: "es-DO", // Dominican Republic
  EC: "es-EC", // Ecuador
  EG: "ar-EG", // Egypt
  SV: "es-SV", // El Salvador
  GQ: "es-GQ", // Equatorial Guinea
  ER: "ti-ER", // Eritrea
  EE: "et-EE", // Estonia
  ET: "am-ET", // Ethiopia
  FO: "fo-FO", // Faroe Islands
  FJ: "en-FJ", // Fiji
  FI: "fi-FI", // Finland
  FR: "fr-FR", // France
  PF: "fr-PF", // French Polynesia
  GA: "fr-GA", // Gabon
  GM: "en-GM", // Gambia
  GE: "ka-GE", // Georgia
  DE: "de-DE", // Germany
  GH: "en-GH", // Ghana
  GI: "en-GI", // Gibraltar
  GR: "el-GR", // Greece
  GL: "kl-GL", // Greenland
  GD: "en-GD", // Grenada
  GU: "en-US", // Guam
  GT: "es-GT", // Guatemala
  GG: "en-GB", // Guernsey
  GN: "fr-GN", // Guinea
  GW: "pt-GW", // Guinea-Bissau
  GY: "en-GY", // Guyana
  HT: "ht-HT", // Haiti
  HN: "es-HN", // Honduras
  HK: "zh-HK", // Hong Kong
  HU: "hu-HU", // Hungary
  IS: "is-IS", // Iceland
  IN: "en-IN", // India
  ID: "id-ID", // Indonesia
  IR: "fa-IR", // Iran
  IQ: "ar-IQ", // Iraq
  IE: "en-IE", // Ireland
  IM: "en-GB", // Isle of Man
  IL: "he-IL", // Israel
  IT: "it-IT", // Italy
  JM: "en-JM", // Jamaica
  JP: "ja-JP", // Japan
  JE: "en-GB", // Jersey
  JO: "ar-JO", // Jordan
  KZ: "kk-KZ", // Kazakhstan
  KE: "sw-KE", // Kenya
  KI: "en-KI", // Kiribati
  KP: "ko-KP", // Korea (Democratic People's Republic of)
  KR: "ko-KR", // Korea (Republic of)
  KW: "ar-KW", // Kuwait
  KG: "ky-KG", // Kyrgyzstan
  LA: "lo-LA", // Lao People's Democratic Republic
  LV: "lv-LV", // Latvia
  LB: "ar-LB", // Lebanon
  LS: "en-LS", // Lesotho
  LR: "en-LR", // Liberia
  LY: "ar-LY", // Libya
  LI: "de-LI", // Liechtenstein
  LT: "lt-LT", // Lithuania
  LU: "fr-LU", // Luxembourg
  MO: "zh-MO", // Macao
  MG: "mg-MG", // Madagascar
  MW: "ny-MW", // Malawi
  MY: "ms-MY", // Malaysia
  MV: "dv-MV", // Maldives
  ML: "fr-ML", // Mali
  MT: "mt-MT", // Malta
  MH: "en-MH", // Marshall Islands
  MR: "ar-MR", // Mauritania
  MU: "en-MU", // Mauritius
  YT: "fr-YT", // Mayotte
  MX: "es-MX", // Mexico
  FM: "en-FM", // Micronesia (Federated States of)
  MD: "ro-MD", // Moldova
  MC: "fr-MC", // Monaco
  MN: "mn-MN", // Mongolia
  ME: "sr-ME", // Montenegro
  MS: "en-MS", // Montserrat
  MA: "ar-MA", // Morocco
  MZ: "pt-MZ", // Mozambique
  MM: "my-MM", // Myanmar
  NA: "en-NA", // Namibia
  NR: "en-NR", // Nauru
  NP: "ne-NP", // Nepal
  NL: "nl-NL", // Netherlands
  NC: "fr-NC", // New Caledonia
  NZ: "en-NZ", // New Zealand
  NI: "es-NI", // Nicaragua
  NE: "fr-NE", // Niger
  NG: "en-NG", // Nigeria
  NU: "en-NU", // Niue
  MP: "en-MP", // Northern Mariana Islands
  NO: "nb-NO", // Norway
  OM: "ar-OM", // Oman
  PK: "ur-PK", // Pakistan
  PW: "en-PW", // Palau
  PS: "ar-PS", // Palestine, State of
  PA: "es-PA", // Panama
  PG: "en-PG", // Papua New Guinea
  PY: "es-PY", // Paraguay
  PE: "es-PE", // Peru
  PH: "fil-PH", // Philippines
  PN: "en-PN", // Pitcairn
  PL: "pl-PL", // Poland
  PT: "pt-PT", // Portugal
  PR: "es-PR", // Puerto Rico
  QA: "ar-QA", // Qatar
  RO: "ro-RO", // Romania
  RU: "ru-RU", // Russian Federation
  RW: "rw-RW", // Rwanda
  SH: "en-SH", // Saint Helena, Ascension and Tristan da Cunha
  KN: "en-KN", // Saint Kitts and Nevis
  LC: "en-LC", // Saint Lucia
  MF: "fr-MF", // Saint Martin (French part)
  PM: "fr-PM", // Saint Pierre and Miquelon
  VC: "en-VC", // Saint Vincent and the Grenadines
  WS: "en-WS", // Samoa
  SM: "it-SM", // San Marino
  ST: "pt-ST", // Sao Tome and Principe
  SA: "ar-SA", // Saudi Arabia
  SN: "fr-SN", // Senegal
  RS: "sr-RS", // Serbia
  SC: "fr-SC", // Seychelles
  SL: "en-SL", // Sierra Leone
  SG: "en-SG", // Singapore
  SX: "nl-SX", // Sint Maarten (Dutch part)
  SK: "sk-SK", // Slovakia
  SI: "sl-SI", // Slovenia
  SB: "en-SB", // Solomon Islands
  SO: "so-SO", // Somalia
  ZA: "en-ZA", // South Africa
  SS: "en-SS", // South Sudan
  ES: "es-ES", // Spain
  LK: "si-LK", // Sri Lanka
  SD: "ar-SD", // Sudan
  SR: "nl-SR", // Suriname
  SZ: "en-SZ", // Eswatini
  SE: "sv-SE", // Sweden
  CH: "de-CH", // Switzerland
  SY: "ar-SY", // Syrian Arab Republic
  TW: "zh-TW", // Taiwan
  TJ: "tg-TJ", // Tajikistan
  TZ: "sw-TZ", // Tanzania
  TH: "th-TH", // Thailand
  TL: "pt-TL", // Timor-Leste
  TG: "fr-TG", // Togo
  TK: "tk-TK", // Tokelau
  TO: "en-TO", // Tonga
  TT: "en-TT", // Trinidad and Tobago
  TN: "ar-TN", // Tunisia
  TR: "tr-TR", // Turkey
  TM: "tk-TM", // Turkmenistan
  TC: "en-TC", // Turks and Caicos Islands
  TV: "en-TV", // Tuvalu
  UG: "en-UG", // Uganda
  UA: "uk-UA", // Ukraine
  AE: "ar-AE", // United Arab Emirates
  GB: "en-GB", // United Kingdom of Great Britain and Northern Ireland
  US: "en-US", // United States of America
  UM: "en-US", // United States Minor Outlying Islands
  UY: "es-UY", // Uruguay
  UZ: "uz-UZ", // Uzbekistan
  VU: "bi-VU", // Vanuatu
  VE: "es-VE", // Venezuela
  VN: "vi-VN", // Vietnam
  VG: "en-VG", // Virgin Islands (British)
  VI: "en-VI", // Virgin Islands (U.S.)
  WF: "fr-WF", // Wallis and Futuna
  EH: "ar-EH", // Western Sahara
  YE: "ar-YE", // Yemen
  ZM: "en-ZM", // Zambia
  ZW: "sn-ZW", // Zimbabwe
};

const CURRENCY_CODES = {
  AD: 'EUR',
  AE: 'AED',
  AF: 'AFN',
  AG: 'XCD',
  AI: 'XCD',
  AL: 'ALL',
  AM: 'AMD',
  AN: 'ANG',
  AO: 'AOA',
  AQ: 'USD',
  AR: 'ARS',
  AS: 'USD',
  AT: 'EUR',
  AU: 'AUD',
  AW: 'AWG',
  AX: 'EUR',
  AZ: 'AZN',
  BA: 'BAM',
  BB: 'BBD',
  BD: 'BDT',
  BE: 'EUR',
  BF: 'XOF',
  BG: 'BGN',
  BH: 'BHD',
  BI: 'BIF',
  BJ: 'XOF',
  BL: 'EUR',
  BM: 'BMD',
  BN: 'BND',
  BO: 'BOB',
  BQ: 'USD',
  BR: 'BRL',
  BS: 'BSD',
  BT: 'BTN',
  BV: 'NOK',
  BW: 'BWP',
  BY: 'BYN',
  BZ: 'BZD',
  CA: 'CAD',
  CC: 'AUD',
  CD: 'CDF',
  CF: 'XAF',
  CG: 'CDF',
  CH: 'CHF',
  CI: 'XOF',
  CK: 'NZD',
  CL: 'CLP',
  CM: 'XAF',
  CN: 'CNY',
  CO: 'COP',
  CR: 'CRC',
  CU: 'CUC',
  CV: 'CVE',
  CW: 'ANG',
  CX: 'AUD',
  CY: 'EUR',
  CZ: 'CZK',
  DE: 'EUR',
  DJ: 'DJF',
  DK: 'DKK',
  DM: 'DOP',
  DO: 'DOP',
  DZ: 'DZD',
  EC: 'USD',
  EE: 'EUR',
  EG: 'EGP',
  EH: 'MAD',
  ER: 'ERN',
  ES: 'EUR',
  ET: 'ETB',
  FI: 'EUR',
  FJ: 'FJD',
  FK: 'FKP',
  FM: 'USD',
  FO: 'DKK',
  FR: 'EUR',
  GA: 'XAF',
  GB: 'GBP',
  GD: 'XCD',
  GE: 'GEL',
  GF: 'EUR',
  GG: 'GBP',
  GH: 'GHS',
  GI: 'GIP',
  GL: 'DKK',
  GM: 'GMD',
  GN: 'GNF',
  GP: 'EUR',
  GQ: 'XAF',
  GR: 'EUR',
  GS: 'GEL',
  GT: 'GTQ',
  GU: 'USD',
  GW: 'XOF',
  GY: 'GYD',
  HK: 'HKD',
  HM: 'AUD',
  HN: 'HNL',
  HR: 'EUR',
  HT: 'HTG',
  HU: 'HUF',
  ID: 'IDR',
  IE: 'EUR',
  IL: 'ILS',
  IM: 'GBP',
  IN: 'INR',
  IO: 'USD',
  IQ: 'IQD',
  IR: 'IRR',
  IS: 'ISK',
  IT: 'EUR',
  JE: 'GBP',
  JM: 'JMD',
  JO: 'JOD',
  JP: 'JPY',
  KE: 'KES',
  KG: 'KGS',
  KH: 'KHR',
  KI: 'AUD',
  KM: 'KMF',
  KN: 'XCD',
  KP: 'KPW',
  KR: 'KRW',
  KW: 'KWD',
  KY: 'KYD',
  KZ: 'KZT',
  LA: 'LAK',
  LB: 'LBP',
  LC: 'XCD',
  LI: 'CHF',
  LK: 'LKR',
  LR: 'LRD',
  LS: 'LSL',
  LT: 'EUR',
  LU: 'EUR',
  LV: 'EUR',
  LY: 'LYD',
  MA: 'MAD',
  MC: 'EUR',
  MD: 'MDL',
  ME: 'EUR',
  MF: 'EUR',
  MG: 'MGA',
  MH: 'USD',
  MK: 'MKD',
  ML: 'XOF',
  MM: 'MMK',
  MN: 'MNT',
  MO: 'MOP',
  MP: 'USD',
  MQ: 'EUR',
  MR: 'MRU',
  MS: 'XCD',
  MT: 'EUR',
  MU: 'MUR',
  MV: 'MVR',
  MW: 'MWK',
  MX: 'MXN',
  MY: 'MYR',
  MZ: 'MZN',
  NA: 'NAD',
  NC: 'XPF',
  NE: 'NGN',
  NF: 'AUD',
  NG: 'NGN',
  NI: 'NIO',
  NL: 'EUR',
  NO: 'NOK',
  NP: 'NPR',
  NR: 'AUD',
  NU: 'NZD',
  NZ: 'NZD',
  OM: 'OMR',
  PA: 'PAB',
  PE: 'PEN',
  PF: 'XPF',
  PG: 'PGK',
  PH: 'PHP',
  PK: 'PKR',
  PL: 'PLN',
  PM: 'EUR',
  PN: 'NZD',
  PR: 'USD',
  PS: 'ILS',
  PT: 'EUR',
  PW: 'USD',
  PY: 'PYG',
  QA: 'QAR',
  RE: 'EUR',
  RO: 'RON',
  RS: 'RSD',
  RU: 'RUB',
  RW: 'RWF',
  SA: 'SAR',
  SB: 'SBD',
  SC: 'SCR',
  SD: 'SDG',
  SE: 'SEK',
  SG: 'SGD',
  SH: 'SHP',
  SI: 'EUR',
  SJ: 'NOK',
  SK: 'EUR',
  SL: 'SLL',
  SM: 'EUR',
  SN: 'XOF',
  SO: 'SOS',
  SR: 'SRD',
  SS: 'SSP',
  ST: 'STN',
  SV: 'USD',
  SX: 'ANG',
  SY: 'SYP',
  SZ: 'SZL',
  TC: 'USD',
  TD: 'XAF',
  TF: 'EUR',
  TG: 'XOF',
  TH: 'THB',
  TJ: 'TJS',
  TK: 'NZD',
  TL: 'USD',
  TM: 'TMT',
  TN: 'TND',
  TO: 'TOP',
  TR: 'TRY',
  TT: 'TTD',
  TV: 'AUD',
  TW: 'TWD',
  TZ: 'TZS',
  UA: 'UAH',
  UG: 'UGX',
  UM: 'USD',
  US: 'USD',
  UY: 'UYU',
  UZ: 'UZS',
  VA: 'EUR',
  VC: 'XCD',
  VE: 'VES',
  VG: 'USD',
  VI: 'USD',
  VN: 'VND',
  VU: 'VUV',
  WF: 'XPF',
  WS: 'USD',
  YE: 'YER',
  YT: 'EUR',
  ZA: 'ZAR',
  ZM: 'ZMW',
  ZW: 'ZWL'
}
