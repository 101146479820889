import * as constants from '../constants';
import { PANEL_ORIENTATION_PORTRAIT } from '../../coreConstants';
import Subarray from '../../objects/subArray/Subarray';

export function getTemplate(templateName) {
    return Object.values(constants.TEMPLATES).filter(obj => obj.NAME === templateName)[0];
}

export function getSubarrayPropertiesFromSubarray(subarray) {
    const subarrayPanelOrientation = subarray.panelOrientation;
    const moduleDimensions = {
        length: subarray.moduleProperties !== undefined ?
            subarray.moduleProperties.moduleLength : subarray.moduleLength,
        width: subarray.moduleProperties !== undefined ?
            subarray.moduleProperties.moduleWidth : subarray.moduleWidth,
    };
    const moduleid = subarray.moduleProperties !== undefined ?
        subarray.moduleProperties.moduleId : subarray.moduleId;

    let moduleHorizontalLength;
    let moduleVerticalLength;

    if (subarrayPanelOrientation === PANEL_ORIENTATION_PORTRAIT) {
        moduleHorizontalLength = moduleDimensions.width;
        moduleVerticalLength = moduleDimensions.length;
    }
    else {
        moduleHorizontalLength = moduleDimensions.length;
        moduleVerticalLength = moduleDimensions.width;
    }

    const tableVerticalLength = (moduleVerticalLength * subarray.tableSizeUp) +
        (subarray.moduleSpacingUp * (subarray.tableSizeUp - 1));

    return {
        azimuth: subarray.azimuth,
        tilt: subarray.tilt,
        orientation: subarrayPanelOrientation,
        moduleDimensions,
        moduleid,
        moduleSpacing: { up: subarray.moduleSpacingUp, wide: subarray.moduleSpacingWide },
        tableSize: { up: subarray.tableSizeUp, wide: subarray.tableSizeWide },
        parentTilt: (subarray instanceof Subarray) ? subarray?.getParent()?.getTilt() : 0,
        mountHeight: subarray.mountHeight,
        rowSpacing: subarray.rowSpacing,
        tableSpacing: subarray.tableSpacing,
        moduleHorizontalLength,
        moduleVerticalLength,
        pitch: subarray.rowSpacing + moduleDimensions.width,
        mountType: subarray.mountType,
        tableVerticalLength,
        axisTilt: (subarray.axisTilt !== undefined ? subarray.axisTilt : 0), // TODO: check if this is correct (default to 0 if undefined)
        axisAzimuth: (subarray.axisAzimuth !== undefined ? subarray.axisAzimuth : 0), // TODO: check if this is correct (default to 0 if undefined)
        //for dualaxis Structure
        axisMinTilt: subarray.axisMinTilt ? subarray.axisMinTilt : -45,
        axisMaxTilt: subarray.axisMaxTilt ? subarray.axisMaxTilt : 45,
        axisMinAzimuth: subarray.axisMinAzimuth ? subarray.axisMinAzimuth : -90,
        axisMaxAzimuth: subarray.axisMaxAzimuth ? subarray.axisMaxAzimuth : 90,
        id: subarray.id,
    };
}

export function getSubarrayPropertiesForDefaultSettings(subarray) {
    const subarrayPanelOrientation = subarray.panelOrientation;
    const moduleDimensions = {
        length: subarray.moduleProperties !== undefined ?
            subarray.moduleProperties.moduleLength : subarray.moduleLength,
        width: subarray.moduleProperties !== undefined ?
            subarray.moduleProperties.moduleWidth : subarray.moduleWidth,
    };
    const moduleid = subarray.moduleProperties !== undefined ?
        subarray.moduleProperties.moduleId : subarray.moduleId;

    let moduleHorizontalLength;
    let moduleVerticalLength;

    if (subarrayPanelOrientation === PANEL_ORIENTATION_PORTRAIT) {
        moduleHorizontalLength = moduleDimensions.width;
        moduleVerticalLength = moduleDimensions.length;
    }
    else {
        moduleHorizontalLength = moduleDimensions.length;
        moduleVerticalLength = moduleDimensions.width;
    }

    const tableVerticalLength = (moduleVerticalLength * subarray.tableSizeUp) +
        (subarray.moduleSpacingUp * (subarray.tableSizeUp - 1));

    return {
        azimuth: subarray.azimuth,
        tilt: subarray.tilt,
        orientation: subarrayPanelOrientation,
        moduleDimensions,
        moduleid,
        moduleSpacing: { up: subarray.moduleSpacingUp, wide: subarray.moduleSpacingWide },
        tableSize: { up: subarray.tableSizeUp, wide: subarray.tableSizeWide },
        parentTilt: 0,
        mountHeight: subarray.mountHeight,
        rowSpacing: subarray.rowSpacing,
        tableSpacing: subarray.tableSpacing,
        moduleHorizontalLength,
        moduleVerticalLength,
        pitch: subarray.rowSpacing + moduleDimensions.width,
        mountType: 'Fixed Tilt',
        tableVerticalLength,
    };
}
