<template>
    <div>
        <leadsDrawer v-if="isLeadDrawerOpen" :drawer="isLeadDrawerOpen" :lead="drawerData" :activeTab="'first'" :focusNote="focusLeadDrawerNote"
            @close="onCloseDrawer" />

        <GlobalDrawer :title="'View Task'" :isOpen="isTaskDrawerOpen" :drawer-size="750"
            :handleCloseDialog="onCloseDrawer">
            <template v-if="isTaskDrawerOpen" v-slot:body>
                <taskDrawer v-if="isTaskDrawerOpen" @deleteTask="onCloseDrawer" :mode="'view'"
                    :taskData="drawerData"
                />
            </template>
        </GlobalDrawer>

        <GlobalDrawer :isOpen="isOrderDrawerOpen && drawerData ? true : false" :title="''"
            :handleCloseDialog="onCloseDrawer">
            <template v-slot:body>
                <DesignOrdersDrawer :propsData="{
                    order: drawerData,
                    users: drawerData?.users || [],
                    orderDetailsPopVisible: true,
                    orderStatusOptions: [],
                    handleOrderUpdate: handleOrderUpdate,
                    drawer: isOrderDrawerOpen,
                }" :drawerSize="700" />
            </template>
        </GlobalDrawer>
    </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import debounce from "debounce";

export default {
    emits: ["update:drawerData", "update:activeDrawer"],
    components: {
        leadsDrawer: defineAsyncComponent(() => import("../pages/leadManagement/components/leadsDrawer.vue")),
        taskDrawer: defineAsyncComponent(() => import("../pages/taskManagement/components/taskDrawerDynamic.vue")),
        DesignOrdersDrawer: defineAsyncComponent(() => import("../pages/designOrders/components/designOrdersDrawer.vue")),
        GlobalDrawer: defineAsyncComponent(() => import("../pages/commonComponents/allDrawer/globalDrawer.vue")),
    },
    props: {
        activeDrawer: {
            type: String
        },
        drawerData: {
            type: Object
        },
    },
    computed: {
        isLeadDrawerOpen() {
            return this.activeDrawer == "lead"
        },
        isTaskDrawerOpen() {
            return this.activeDrawer == "task"
        },
        isOrderDrawerOpen() {
            return this.activeDrawer == "order"
        },
    },
    methods: {
        onCloseDrawer() {
            this.$emit('update:activeDrawer', "")
            this.$emit('update:drawerData', null)
        },
        handleOrderUpdate() {
        },
    },
    watch: {
        drawerData() {
            this.focusLeadDrawerNote = this.drawerData?.focusNote
        }
    },
}
</script>

<style scoped></style>