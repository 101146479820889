import * as THREE from 'three';
import BaseObject from '../BaseObject'
import CylinderModel from '../model/CylinderModel';
import PolygonModel from "../model/PolygonModel";
import GroundImage from './GroundImage';
import Subarray from "../subArray/Subarray";
import * as notificationsAssistant from '../../../componentManager/notificationsAssistant';
import * as exporters from '../../utils/exporters';
import * as raycastingUtils from '../../utils/raycastingUtils';
import { roofMapExporter } from '../../utils/exporters';
import InfiniteGridHelper from '../../lib/InfiniteGridHelper';
import { getInverters } from '../../utils/exporters';

import {
    BASE_URL,
    PANEL_ORIENTATION_LANDSCAPE,
    INVALID_SCALE,
} from '../../coreConstants';
import {
    VISUAL_STATES,
    MATERIAL_STATES,
    COLOR_MAPPINGS,
    GROUND_EDGE_LINE_WIDTH,
    GROUND_EDGE_LINE_COLOR,
    ONE_PIXEL_IMAGE_DATA,
} from '../visualConstants';
import axios from 'axios';
import loBind from 'lodash/bind';
import Walkway from '../model/Walkway';
import SafetyLine from '../model/SafetyLine';
import Handrail from '../model/Handrail';
import Property from '../model/Property';
import * as visualUtils from '../../utils/visualUtils';
import Tree from '../model/Tree';
import {
    scaleMetersToRatio,
    getAspectRatio,
    getDefaultGroundSize,
} from '../../utils/customImageEditUtils';
import Inverter from '../ac/Inverter';
import microInverter from '../ac/MicroInverter';
import DCDB from '../ac/DCDB';
import ACDB from '../ac/ACDB';
import Conduit from '../ac/conduits/Conduit';
import DoubleConduit from '../ac/conduits/DoubleConduit';
import DoubleSeparateConduit from '../ac/conduits/DoubleSeparateConduit';
import SingleCableTray from '../ac/cableTrays/SingleCableTray';
import DoubleCableTray from '../ac/cableTrays/DoubleCableTray';
import DoubleSeparateCableTray from '../ac/cableTrays/DoubleSeparateCableTray';
import TextBox from '../subObjects/TextBox';
import AcCable from '../model/cable/AcCable';
import DcCable from '../model/cable/DcCable';
import API from '@/services/api/';
import { SmartroofModel } from '../model/smartroof/SmartroofModel';
import MicroInverter from '../ac/MicroInverter';
import CombinerBox from '../ac/CombinerBox';
import Dormer from '../model/smartroof/Dormer';
import Gazebo from '../../lib/PowerGazebo';
import Drawface from '../model/smartroof/DrawFace';
import utils from '../../../services/api/utils';
import * as coreUtils from '../../utils/utils';
import RectangleObstruction from '../model/Rectangle';
import Sky from '../../lib/Sky';
import { loadFonts } from '../subObjects/textUtils';
import EastWestRack from '../../lib/EastWestRacking';
import { useMapImagesStore } from '../../../stores/mapImages';
import Patio from '../subArray/PowerPatio';
import AttachObject from '../subObjects/AttachObject';
import HybridInverter from '../ac/HybridInverter';
import PenToolRoofModel from '../model/smartroof/PenToolRoofModel';
import PenTool from '../model/smartroof/PenTool';
// import Patio from '../subArray/PowerPatio';
// import SalesModePolygonModel from '../../salesModeStudio/salesModelib/salesModePolygonModel';
// import SalesModeCylinderModel from '../../salesModeStudio/salesModelib/salesModeCylinderModel';
import { useTilesStore } from '../../../stores/tilesStore';
import PowerRoof from '../model/powerRoof/PowerRoof';
import GroundMount from '../../salesModeStudio/salesModelib/GroundMount';
export default class Ground extends BaseObject {

    constructor(stage) {
        super(stage);

        this.stage = stage;
        this.id = 0;
        this.name = 'Ground';

        //microInverter array ;
        this.microInverters = [];

        this.material = new THREE.MeshBasicMaterial({
            color: COLOR_MAPPINGS
                .GROUND[MATERIAL_STATES.TRANSLUCENT][VISUAL_STATES.DEFAULT_STATES.DEFAULT]
                .MESH_COLOR,
        });

        //Shadow Material
        this.shadowMaterial = new THREE.MeshLambertMaterial({
            color: COLOR_MAPPINGS
                .GROUND[MATERIAL_STATES.SOLID][VISUAL_STATES.DEFAULT_STATES.DEFAULT].MESH_COLOR,
        });
        this.shadowMaterial.defines = this.shadowMaterial.defines || {};
        this.shadowMaterial.defines.CUSTOM = "";

        const planeGeometry = new THREE.PlaneGeometry(
            stage.imageDimensions.width,
            stage.imageDimensions.height,
        );
        this.plane = new THREE.Mesh(planeGeometry, this.material);
        this.plane.position.z = -0.065;

        const edgeMaterial = new THREE.LineBasicMaterial({
            color: GROUND_EDGE_LINE_COLOR,
            linewidth: GROUND_EDGE_LINE_WIDTH,
        });
        const edgeGeometry = new THREE.EdgesGeometry(planeGeometry);
        this.edgesMesh = new THREE.LineSegments(edgeGeometry, edgeMaterial);
        this.edgesMesh.visible = false;
        this.edgesMesh.position.z = 1;

        this.groundImage = null;
        this.gridLines = null;

        this.plane.castShadow = true;
        this.objectsGroup = new THREE.Group();
        this.objectsGroup.container = this;
        stage.sceneManager.scene.add(this.objectsGroup);

        this.measurementTextMesh = [];

        this.objectsGroup.add(this.plane);
        this.objectsGroup.add(this.edgesMesh);

        this.canvas = stage.rendererManager.getDomElement();

        let gridObj= new InfiniteGridHelper(10, 100);
        this.grid = gridObj.getmesh();
        this.stage.sceneManager.scene.add(this.grid);
        this.sky = new Sky();

        this.wires = {};
        this.allCables = [];
        this.allConduitAndCabletary = [];
        this.allAcdbs = [];
        this.loadingPromises = [];

        this.roofTexture = null;
        this.roofTextureMat = new THREE.MeshLambertMaterial({
            color: 0xffffff,
        });

        this.initSkyGrid();
        this.initGridLines();
        this.loadingPromises.push(this.loadGroundImage(this.stage.getGroundImage(),true));

        this.updateVisualsAfterLoadingAndCreation();

        this.selectedPreliminaryInverters = [];
        this.faces = new Set();
        this.patiosForModelConstraint = [];
        this.smartroofs = new Set();
        this.powerRoofs = [];
    }

    getSmartroofById(id) {
        for (let smartroof of this.smartroofs) {
            if (smartroof.id === id) {
                return smartroof;
            }
        }
        return null;
    }

    initSkyGrid() {
        this.sky.scale.setScalar(4500);
        this.stage.sceneManager.scene.add(this.sky);
        let effectController = {
            turbidity: 10,
            rayleigh: 2,
            mieCoefficient: 0.003,
            mieDirectionalG: 0.8,
            inclination: 0.49, // elevation / inclination
            azimuth: 0.25, // Facing front,
            sun: !true
        };
        let uniforms = this.sky.material.uniforms;
        uniforms["turbidity"].value = effectController.turbidity;
        uniforms["rayleigh"].value = effectController.rayleigh;
        uniforms["mieCoefficient"].value = effectController.mieCoefficient;
        uniforms["mieDirectionalG"].value = effectController.mieDirectionalG;
        uniforms["sunPosition"].value.set(400000, 400000, 400000);
        //uniforms["up"].value = new THREE.Vector3(0, 0, 1);

        this.grid.castShadow = true;
        this.grid.rotation.x = Math.PI / 2;
        this.grid.position.y = -0.075;
        this.grid.position.z = -3;

        this.stage.sceneManager.scene.add(this.grid);
    }
    hideSkyAndGround() {
        this.grid.visible = false;
        this.sky.visible = false;
    }
    showSkyAndGround() {
        this.grid.visible = true;
        this.sky.visible = true;
    }

    /* Hides the whole ground */
    hideGround() {
        this.hideEdges();
        this.hideGroundImage();
        this.hideGrid();
        this.plane.visible = false;
        this.grid.visible = false;
    }

    /* shows the whole ground */
    showGround(showGroundEdges = true) {
        if (showGroundEdges) this.showEdges();
        this.showGroundImage();
        this.showGrid();
        this.plane.visible = true;
        this.grid.visible = true;
    }

    showGroundWithOutHidingImage() {
        this.showGroundImage();
        this.showGrid();
        this.plane.visible = true;
        this.grid.visible = true;
    }

    exportAsSTL() {
        const allObjects = [];
        let tempPlane = this.plane.clone();
        tempPlane.geometry = this.plane.geometry.clone().toNonIndexed();
        allObjects.push({
            mesh: tempPlane,
            name: this.name,
        });
        tempPlane.remove()

        const children = this.getChildren();
        for (let i = 0, len = children.length; i < len; i += 1) {
            if (!(children[i] instanceof TextBox || children[i] instanceof Property)) {
                const objects = children[i].exportAsSTL();
                allObjects.push(...objects);
            }
        }
        return allObjects;
    }

    exportAsCollada() {
        // const texture = (new THREE.TextureLoader()).load(ONE_PIXEL_IMAGE_DATA);
        const mesh = this.plane.clone();

        mesh.material = new THREE.MeshLambertMaterial({
            color: COLOR_MAPPINGS
                .GROUND[MATERIAL_STATES.SOLID][VISUAL_STATES.DEFAULT_STATES.DEFAULT].MESH_COLOR,
        });
        mesh.name = this.name;

        const subArrays = [];

        const children = this.getChildren();
        for (let i = 0, len = children.length; i < len; i += 1) {
            if (children[i] instanceof Subarray) {
                const obj = children[i].exportAsCollada();
                subArrays.push(...obj);
            } else if (children[i] instanceof TextBox || children[i] instanceof Property) {
                // do nothing
            } else {
                const obj = children[i].exportAsCollada();
                mesh.children.push(obj.model);
                subArrays.push(...obj.subarray);
            }
        }

        return {
            model: mesh,
            subarray: subArrays,
        };
    }

    saveObject() {
        let groundData = {
            type: Ground.getObjectType(),
            children: []
        };

        groundData.wires = this.wires;
        groundData.selectedPreliminaryInverters = this.selectedPreliminaryInverters;

        // saving children
        for (let child of this.getChildren()) {
            if (child instanceof TextBox && child.isStringText === true) {
                continue;
            } else {
                groundData.children.push(child.saveObject());
            }
        }

        groundData.errors = this.errors;

        groundData.microInverters = this.getMicroinvertersData();

        groundData.powerRoofs = [];
        for (let powerRoof of this.powerRoofs) {
            groundData.powerRoofs.push(powerRoof.saveObject());
        }

        return groundData;
    }

    getMicroinvertersData() {
        const microInvertersData = [];
        for (let i = 0, l = this.microInverters.length; i < l; i += 1) {
            microInvertersData.push(this.microInverters[i].saveObject());
        }
        return microInvertersData;
    }

    updateRoofTexture(material) {
        if (this.roofTextureMat) {
            this.roofTextureMat.dispose();
        }
        this.roofTextureMat = material || new THREE.MeshLambertMaterial({color: 0xffffff});
        if (this.stage.visualManager.in3D && !this.stage.sldView && this.stage.designSettings.drawing_defaults.texture) {
            this.addRoofTexture();
        }
    }

    addRoofTexture() {
        this.children.forEach(modelObj => {
            if (modelObj instanceof SmartroofModel || this.stage.checkInstanceOfPolygonModel(modelObj) || modelObj instanceof Dormer || this.stage.checkInstanceOfCylinderModel(modelObj)) {
                modelObj.removeRoofTexture();
                modelObj.addMapTexture();
            }
        });
    }
    removeRoofTexture() {
        this.children.forEach(modelObj => {
            if (modelObj instanceof SmartroofModel ||this.stage.checkInstanceOfPolygonModel(modelObj) || modelObj instanceof Dormer || this.stage.checkInstanceOfCylinderModel(modelObj)) {
                modelObj.removeRoofTexture();
            }
        });
    }

    showRoofTexture() {
        this.children.forEach(modelObj => {
            if (modelObj instanceof SmartroofModel || this.stage.checkInstanceOfPolygonModel(modelObj) || modelObj instanceof Dormer || this.stage.checkInstanceOfCylinderModel(modelObj)) {
                modelObj.showRoofTexture();
            }
        });
    }

    hideRoofTexture() {
        this.children.forEach(modelObj => {
            if (modelObj instanceof SmartroofModel || this.stage.checkInstanceOfPolygonModel(modelObj) || modelObj instanceof Dormer || this.stage.checkInstanceOfCylinderModel(modelObj)) {
                modelObj.hideRoofTexture();
            }
        });
    }

    hideImage() {
        this.groundImage.hide();
    }

    showImage() {
        this.groundImage.show();
    }

    getNoOfModulesSalesMode() {
        const subarrayList = [];
        let totalNoOfModules = 0;
        exporters.getSubarrays(this, subarrayList);
        subarrayList.forEach((subarray) => {
            totalNoOfModules += subarray.getNumberOfPanels();
        });
        return totalNoOfModules;
    }

    async loadGroundImage(groundImageData, onLoadFlag = false) {
        if (this.groundImage) {
            this.groundImage.remove();
            delete this.groundImage;
        }
        const oldDimensions = this.stage.imageDimensions;
        if(!useMapImagesStore().hasOldImage && !onLoadFlag){
            this.stage.imageDimensions = coreUtils.getImageDimensions(useMapImagesStore().latitude, useMapImagesStore().longitude, useMapImagesStore().zoomLevel, useMapImagesStore().dimensions, useMapImagesStore().dimensions);
        }

        this.groundImage = new GroundImage(this.stage, groundImageData, groundImageData.id, this);
        this.loadingPromises.push(...this.groundImage.textureLoadPromise);
        const aspectRatio = await getAspectRatio(groundImageData.url);
        const scale = scaleMetersToRatio(
            (groundImageData.scale === INVALID_SCALE) ?
            getDefaultGroundSize(this.stage).width :
            groundImageData.scale,
            aspectRatio,
            oldDimensions.width
        );
        if(!useMapImagesStore().hasOldImage && !onLoadFlag){
            this.groundImage.loadObject({
                scale: 1,
                rotation: groundImageData.rotation,
                offset: [0,0],
            });
        }else{
            let offsetVal = [0,0];
            if(groundImageData.source == 'nearmap') offsetVal = [0,0];
            else if(groundImageData.location && Object.keys(groundImageData.location).length!==0){
                offsetVal = [groundImageData.location.x, groundImageData.location.y];
            }else if(groundImageData.offset && groundImageData.offset.length !== 0){
                offsetVal = groundImageData.offset;
            }
            this.groundImage.loadObject({
                scale,
                rotation: groundImageData.rotation,
                offset: offsetVal,
            });
        }
        //for updation of ground size
        if(!useMapImagesStore().hasOldImage && !onLoadFlag){
            this.resizeGround(scale);
        }
    }

    initGridLines() {
        this.gridLines = new THREE.GridHelper(
            this.stage.getImageDimensions().width,
            this.stage.getImageDimensions().height,
        );
        this.gridLines.material.transparent = true;
        this.gridLines.material.opacity = 0.25;
        this.gridLines.material.color.set(0xbbbbbb);
        this.gridLines.rotateX(Math.PI / 2);
        this.gridLines.position.setZ(-0.06);
        this.objectsGroup.add(this.gridLines);
    }

    reset() {
        this.resetGridLines();
        this.resetEdges();
        this.resetGroundPlane();
    }

    resetEdges() {
        this.hideEdges();
        const planeVertices = coreUtils.getVerticesFromBufferGeometry(this.plane.geometry);
        const scaleMultiplier = this.stage.getImageDimensions().width /
            (planeVertices[1].x * 2);
        this.edgesMesh.geometry.scale(scaleMultiplier, scaleMultiplier, 1);
    }

    resetGroundPlane() {
        const planeVertices = coreUtils.getVerticesFromBufferGeometry(this.plane.geometry);
        const scaleMultiplier = this.stage.getImageDimensions().width /
            (planeVertices[1].x * 2);
        this.plane.geometry.scale(scaleMultiplier, scaleMultiplier, 1);
    }

    resetGridLines() {
        this.objectsGroup.remove(this.gridLines);
        this.initGridLines();
    }

    resizeGround(scaleMultiplier) {
        this.plane.geometry.scale(scaleMultiplier, scaleMultiplier, 1);
        this.gridLines.geometry.scale(scaleMultiplier, 1, scaleMultiplier);
        this.edgesMesh.geometry.scale(scaleMultiplier, scaleMultiplier, 1);
    }

    async loadObject(groundData) {
        let requiredFonts = {Helvetica : {isPresent: false, bold: false, italics: false}, Arial : {isPresent: false, bold :false, italics :false}, Gentilis : {isPresent: false, bold :false, italics :false},};
        // load properties
        this.wires = groundData.wires === undefined ? {} : groundData.wires;
        let fontsLoaded = false;
        const groundChildren = groundData.children === undefined ? [] : groundData.children;
        for (let ind = 0, len = groundChildren.length; ind < len; ind += 1) {
            if (groundChildren[ind].type === TextBox.getObjectType()) {
                if(groundChildren[ind].font == "Helvetica"){
                    requiredFonts.Helvetica.isPresent = true;
                    requiredFonts.Helvetica.boldItalic = requiredFonts.Helvetica.boldItalic || (groundChildren[ind].fontBold && groundChildren[ind].fontItalics);
                    if(!groundChildren[ind].fontBold || !groundChildren[ind].fontItalics){
                        requiredFonts.Helvetica.bold = requiredFonts.Helvetica.bold || groundChildren[ind].fontBold;
                        requiredFonts.Helvetica.italics = requiredFonts.Helvetica.italics || groundChildren[ind].fontItalics;
                    }
                }
                else if(groundChildren[ind].font == "Arial"){
                    requiredFonts.Arial.isPresent = true;
                    requiredFonts.Arial.boldItalic = requiredFonts.Arial.boldItalic || (groundChildren[ind].fontBold && groundChildren[ind].fontItalics);
                    if(!groundChildren[ind].fontBold || !groundChildren[ind].fontItalics){
                        requiredFonts.Arial.bold = requiredFonts.Arial.bold || groundChildren[ind].fontBold;
                        requiredFonts.Arial.italics = requiredFonts.Arial.italics || groundChildren[ind].fontItalics;
                    }
                }
                else if(groundChildren[ind].font == "Gentilis"){
                    requiredFonts.Gentilis.isPresent = true;
                    requiredFonts.Gentilis.boldItalic = requiredFonts.Gentilis.boldItalic || (groundChildren[ind].fontBold && groundChildren[ind].fontItalics);
                    if(!groundChildren[ind].fontBold || !groundChildren[ind].fontItalics){
                        requiredFonts.Gentilis.bold = requiredFonts.Gentilis.bold || groundChildren[ind].fontBold;
                        requiredFonts.Gentilis.italics = requiredFonts.Gentilis.italics || groundChildren[ind].fontItalics;
                    }
                }
            }
        }
        // Store all the errors in this array
        this.errors = groundData.errors ? groundData.errors : [];
        for (let i = 0, len = groundChildren.length; i < len; i += 1) {
            let currentObject;
            try {            
                if (this.stage.checkObjectTypePolygonModel(groundChildren[i])) {
                    const polygonModel = this.stage.initializePolygonModel();
                    currentObject = polygonModel;
                    this.addChild(polygonModel);
                    polygonModel.loadObject(groundChildren[i]);
                    if (polygonModel.getParent() !== this) {
                        console.error('Ground: Mismatch in parent while loading PolygonModel');
                    }
                } 
             
                else if (groundChildren[i].type === RectangleObstruction.getObjectType()) {
                    const rectangleObstruction = new RectangleObstruction(this.stage);
                    currentObject = rectangleObstruction;
                    this.addChild(rectangleObstruction);
                    rectangleObstruction.loadObject(groundChildren[i]);
                    if (rectangleObstruction.getParent() !== this) {
                        console.error('Ground: Mismatch in parent while loading RectangleObstruction');
                    }
                } else if (this.stage.checkObjectTypeCylinderModel(groundChildren[i])) {
                    const cylinderModel = this.stage.initializeCylinderModel();
                    currentObject = cylinderModel;
                    this.addChild(cylinderModel);
                    cylinderModel.loadObject(groundChildren[i]);
                    if (cylinderModel.getParent() !== this) {
                        console.error('Ground: Mismatch in parent while loading CylinderModel');
                    }
                }
                 else if (this.stage.checkObjectTypeDrawFace(groundChildren[i])) {
                    const drawFace = this.stage.initializeDrawFace(this);
                    currentObject = drawFace;
                    this.addChild(drawFace);
                    drawFace.loadObject(groundChildren[i]);
                    if (drawFace.getParent() !== this) {
                        console.error('Ground: Mismatch in parent while loading drawFace');
                    } 
                }
                else if (groundChildren[i].type === PenToolRoofModel.getObjectType()) {
                    let pentoolRoofModel = null;
                    if(this.stage.penTool === null) {
                        this.stage.penTool = new PenTool(this.stage);
                        this.stage.penTool.penToolRoofModel.isDummy = false;
                        pentoolRoofModel = this.stage.penTool.penToolRoofModel;
                    }
                    else pentoolRoofModel = new PenToolRoofModel(this.stage);

                    currentObject = pentoolRoofModel;
                    // temp fix, fix the flow
                    // this.addChild(pentoolRoofModel);
                    pentoolRoofModel.loadObject(groundChildren[i]);
                    if (pentoolRoofModel.getParent() !== this.stage.penTool) {
                        console.error('Ground: Mismatch in parent while loading penToolRoofModel');
                    }
                }            
                else if (groundChildren[i].type === SmartroofModel.getObjectType()) {
                    const smartroofModel = new SmartroofModel(this.stage);
                    currentObject = smartroofModel;
                    this.addChild(smartroofModel);
                    smartroofModel.loadObject(groundChildren[i]);
                    if (smartroofModel.getParent() !== this) {
                        console.error('Ground: Mismatch in parent while loading smartroofModel');
                    }
                } 
                else if (groundChildren[i].type === EastWestRack.getObjectType()) {
                    if (groundChildren[i].eastWestSubarraydata) {
                        const ewRack = new EastWestRack(this.stage);
                        currentObject = ewRack;
                        ewRack.loadObject(groundChildren[i], this);
                    }
                    else {
                        continue;
                    }
                } else if (groundChildren[i].type === Subarray.getObjectType()) {
                    const subarray = new Subarray(this.stage);
                    currentObject = subarray;
                    subarray.loadObject(groundChildren[i], this);
                }else if (groundChildren[i].type === GroundMount.getObjectType()) {
                    const groundMount = this.stage.initializeGroundMount(this);
                    groundMount.loadObject(groundChildren[i], this);
                }else if (groundChildren[i].type === Gazebo.getObjectType()) {
                    const gazebo = new Gazebo(this.stage);
                    currentObject = gazebo;
                    gazebo.loadObject(groundChildren[i], this);
                }else if (groundChildren[i].type === Patio.getObjectType()) {
                    const patio = new Patio(this.stage);
                    currentObject = patio;
                    patio.loadObject(groundChildren[i], this);
                }else if (groundChildren[i].type === Walkway.getObjectType()) {
                    const walkway = new Walkway(this.stage);
                    currentObject = walkway;
                    this.addChild(walkway);
                    walkway.loadObject(groundChildren[i]);
                } else if (groundChildren[i].type === SafetyLine.getObjectType()) {
                    const safetyLine = new SafetyLine(this.stage);
                    currentObject = safetyLine;
                    this.addChild(safetyLine);
                    safetyLine.loadObject(groundChildren[i]);
                } else if (groundChildren[i].type === Handrail.getObjectType()) {
                    const handrail = new Handrail(this.stage);
                    currentObject = handrail;
                    this.addChild(handrail);
                    handrail.loadObject(groundChildren[i]);
                } else if (groundChildren[i].type === Property.getObjectType()) {
                    const property = new Property(this.stage);
                    currentObject = property;
                    this.addChild(property);
                    property.loadObject(groundChildren[i]);
                } else if (groundChildren[i].type === AcCable.getObjectType()) {
                    // try {
                    // const acCable = new AcCable(this.stage);
                    // this.addChild(acCable);
                    // acCable.loadObject(groundChildren[i]);
                    // } catch (error) {
                    //     console.error('Ground.js: error in loading AC cable', error);
                    // }

                    //     // this.allCables.push({data:groundChildren[i], cable:acCable, isPaste:false});
                } else if (groundChildren[i].type === DcCable.getObjectType()) {
                    // const dcCable = new DcCable(this.stage);
                    // this.addChild(dcCable);
                    // this.allCables.push({data:groundChildren[i], cable:dcCable, isPaste:false});
                } else if (groundChildren[i].type === Conduit.getObjectType()) {
                    // const conduit = new Conduit(this.stage);
                    // this.addChild(conduit);
                    // conduit.loadObject(groundChildren[i]);
                    // this.allConduitAndCabletary.push(conduit);
                }
                // else if (groundChildren[i].type === DoubleConduit.getObjectType()) {
                //     const doubleConduit = new DoubleConduit(this.stage);
                //     this.addChild(doubleConduit);
                //     doubleConduit.loadObject(groundChildren[i]);
                //     this.allConduitAndCabletary.push(doubleConduit);
                // }
                // else if (groundChildren[i].type === DoubleSeparateConduit.getObjectType()) {
                //     const doubleSeparateconduit = new DoubleSeparateConduit(this.stage);
                //     this.addChild(doubleSeparateconduit);
                //     doubleSeparateconduit.loadObject(groundChildren[i]);
                //     this.allConduitAndCabletary.push(doubleSeparateconduit);
                // }
                // else if (groundChildren[i].type === SingleCableTray.getObjectType()) {
                //     const singleCableTray = new SingleCableTray(this.stage);
                //     this.addChild(singleCableTray);
                //     singleCableTray.loadObject(groundChildren[i]);
                //     this.allConduitAndCabletary.push(singleCableTray);
                // }
                // else if (groundChildren[i].type === DoubleCableTray.getObjectType()) {
                //     const doubleCableTray = new DoubleCableTray(this.stage);
                //     this.addChild(doubleCableTray);
                //     doubleCableTray.loadObject(groundChildren[i]);
                //     this.allConduitAndCabletary.push(doubleCableTray);
                // }
                // else if (groundChildren[i].type === DoubleSeparateCableTray.getObjectType()) {
                //     const doubleSeparateCableTray = new DoubleSeparateCableTray(this.stage);
                //     this.addChild(doubleSeparateCableTray);
                //     doubleSeparateCableTray.loadObject(groundChildren[i]);
                //     this.allConduitAndCabletary.push(doubleSeparateCableTray);
                // }
                else if (this.stage.checkObjectTypeTree(groundChildren[i])) {
                    const tree = this.stage.initializeTree(this);
                    currentObject = tree;
                    this.addChild(tree);
                    tree.loadObject(groundChildren[i]);
                } else if (groundChildren[i].type === Inverter.getObjectType()) {
                    const inverter = new Inverter(this.stage);
                    currentObject = inverter;
                    this.addChild(inverter);
                    inverter.loadObject(groundChildren[i]);
                } else if (groundChildren[i].type === HybridInverter.getObjectType()) {
                    const inverter = new HybridInverter(this.stage);
                    currentObject = inverter;
                    this.addChild(inverter);
                    inverter.loadObject(groundChildren[i]);
                } else if (groundChildren[i].type === CombinerBox.getObjectType()) {
                    const combinerBox = new CombinerBox(this.stage);
                    currentObject = combinerBox;
                    this.addChild(combinerBox);
                    combinerBox.loadObject(groundChildren[i]);
                } else if (groundChildren[i].type === ACDB.getObjectType()) {
                    try {
                        const acdb = new ACDB(this.stage);
                        currentObject = acdb;
                        this.addChild(acdb);
                        acdb.loadObject(groundChildren[i]);
                    } catch (error) {
                        console.error('Ground.js: error in loading Acdb', error);
                    }
                    // this.allAcdbs.push({data:groundChildren[i], acdb:acdb, isPaste:false});
                } else if (groundChildren[i].type === DCDB.getObjectType()) {
                    const dcdb = new DCDB(this.stage);
                    currentObject = dcdb;
                    this.addChild(dcdb);
                    dcdb.loadObject(groundChildren[i]);
                } else if (groundChildren[i].type === TextBox.getObjectType()) {
                    if(!fontsLoaded){
                        // this.loadingPromises.push(getFont().then(()=>{
                        //     fontsLoaded = true;
                        //     const textBox = new TextBox(this.stage);
                        //     currentObject = textBox;
                        //     this.addChild(textBox);
                        //     textBox.loadObject(groundChildren[i]);
                        // }));
                        this.loadingPromises.push(new Promise(resolve => {
                            loadFonts(requiredFonts).then(()=>{
                                fontsLoaded = true;
                                const textBox = new TextBox(this.stage);
                                currentObject = textBox;
                                this.addChild(textBox);
                                textBox.loadObject(groundChildren[i]);
                                resolve();
                            })
                        }));
                    }
                    else{
                        const textBox = new TextBox(this.stage);
                        currentObject = textBox;
                        this.addChild(textBox);
                        textBox.loadObject(groundChildren[i]);
                    }
                } else {
                    console.error('Ground: Invalid object type in loadObject', groundChildren[i].type);
                }
            } catch (error) {
                console.error('error: ', error);
                notificationsAssistant.error({
                    title: 'Load Error',
                    message: 'Error loading object. Please contact support.',
                });  
                this.errors.push(groundChildren[i])
                currentObject.removeObject(); 
            }
        }

        //load all custom images present in scene
        this.stage.customImageManager.loadCustomImagesOnLoad();

        // backward compatibility for inverters
        // Release 2021 feb
        // this code should/can? be removed in 2-3 months.
        try {
            if (groundData.hasOwnProperty('selectedPreliminaryInverters')) {
                if (groundData.selectedPreliminaryInverters.length > 0) {
                    const subarrays = [];
                    exporters.getSubarrays(this, subarrays);
                    if (subarrays.length === 0) {
                        throw 'No subarrays to load the old inverters into.';
                    }
                    const selectedSubarray = subarrays[0];
                    let inverterDataResponse = {};
                    for (let i = 0, l = groundData.selectedPreliminaryInverters.length; i < l; i += 1) {
                        try {
                            inverterDataResponse =
                                await API.MASTER_DATA_INVERTER.FETCH_MASTER_INVERTER_BY_OLD_ID(
                                    groundData.selectedPreliminaryInverters[i].inverterDetails.id
                                );
                        } catch (error) {
                            console.error(
                                'Ground.js: Inverter Mapping not found with id',
                                groundData.selectedPreliminaryInverters[i].inverterDetails.id,
                                error,
                            );
                            const inverterDetails = {
                                Size: parseInt(groundData.selectedPreliminaryInverters[i].inverterDetails.characteristics.paco) / 1000,
                                Make: groundData.selectedPreliminaryInverters[i].inverterDetails.characteristics.name,
                                Manufacturer: '',
                                id: 0,
                            }
                            const inverterData = {
                                inverterDetails,
                                quantity: groundData.selectedPreliminaryInverters[i].quantity,
                                isDCDB: false,
                            }
                            selectedSubarray.updateInverterAdditionForUnmappedInverters(inverterData);
                        }
                        if (inverterDataResponse.data !== undefined) {
                            const inverterData = {
                                inverterDetails: inverterDataResponse.data[0],
                                quantity: groundData.selectedPreliminaryInverters[i].quantity,
                                isDCDB: false,
                            }
                            selectedSubarray.updateInverterAddition(inverterData);
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Ground.js: Cannot load old inverter properly', error);
        }

        this.updateRoofIntersections();

        if (groundData.microInverters !== undefined) {
            for (let i = 0, l = groundData.microInverters.length; i < l; i += 1) {
                const microInverter = new MicroInverter(this.stage, groundData.electricalProperties);
                microInverter.loadObject(groundData.microInverters[i]);
                microInverter.setMaxStringLength();
                this.microInverters.push(microInverter);
            }
        }
        if(groundData.powerRoofs !== undefined){
            for (let i = 0, l = groundData.powerRoofs.length; i < l; i += 1) {
                const powerRoof = new PowerRoof(this.stage);
                powerRoof.loadObject(groundData.powerRoofs[i]);
                this.powerRoofs.push(powerRoof);
            }
        }
        // Jugaad fix for old designs showing ac size = 0
        this.stage.selectionControls.setSelectedObject(this);
        // window.stageLoaded = true;
        await Promise.all(this.loadingPromises).then(async ()=>{
            if(this.stage.getOverviewMode()){
                this.stage.updateTextMeshView('3D');
            }
            await this.stage.customImageManager.updateRoofTextures();
            window.stageLoaded = true;
        });
    }

    async loadEmptyGround() {
        //load all custom images present in scene
        this.stage.customImageManager.loadCustomImagesOnLoad();

        await Promise.all(this.loadingPromises).then(async ()=>{
            await this.stage.customImageManager.updateRoofTextures();
            window.stageLoaded = true;
        });
    }

    loadAttachments() {
        const models = exporters.getAllModelType();
        exporters.getModels(this, models);

        models.polygons.forEach(polygon => polygon.loadAttachments());

        models.smartroofs.forEach(smartroof => {
            smartroof.loadAttachments();
        });
    }

    removeAllStringTextBox() {
        const result = exporters.getAllModelType();
        exporters.getModels(this, result)

        result.textbox.forEach(ele => {
            if (ele.isStringText) {
                ele.removeObject();
            }
        })
    }

    loadStringTextBoxesMicroInverter() {
        const microInverters = this.getMicroinverters();
        let strName;
        if (microInverters.length > 1) {
            for (let i = 0; i < microInverters.length; i++) {
                for (let j = 0; j < microInverters[i].strings.length; j++) {
                    if(microInverters[i].strings[j]?.stringText) {
                        microInverters[i].strings[j].stringText.removeObject();
                    }
                    strName = `I${i+1} B${j+1}`;
                    let firstCoordinate = microInverters[i].strings[j].getCoordinates().coord[0];
                    let lastCoordinate = microInverters[i].strings[j].getCoordinates().coord[microInverters[i].strings[j].getCoordinates().length - 1];
                    microInverters[i].strings[j].addTextBox(strName, firstCoordinate, lastCoordinate);
                }
            }
        } else if (microInverters.length === 1) {
            for (let j = 0; j < microInverters[0].strings.length; j++) {
                if(microInverters[0].strings[j]?.stringText) {
                    microInverters[0].strings[j].stringText.removeObject();
                }
                strName = `B${j+1}`;
                let firstCoordinate = microInverters[0].strings[j].getCoordinates().coord[0];
                let lastCoordinate = microInverters[0].strings[j].getCoordinates().coord[microInverters[0].strings[j].getCoordinates().length - 1];
                microInverters[0].strings[j].addTextBox(strName, firstCoordinate, lastCoordinate);
            }
        }
    }
    loadStringTextBoxesStringInverter() {
        const stringInverters = getInverters(this.stage);
        let strName;
        if (stringInverters.length > 1) {
            for (let i = 0; i < stringInverters.length; i++) {
                for (let j = 0; j < stringInverters[i].mppts.length; j++) {
                    for (let k = 0; k < stringInverters[i].mppts[j].strings.length; k++) {
                        if(stringInverters[i].mppts[j].strings[k].stringText) {
                            stringInverters[i].mppts[j].strings[k].stringText.removeObject();
                        }
                        strName = `I${i+1} S${k+1}`;
                        stringInverters[i].mppts[j].strings[k].stringName = `M${j+1} S${k+1}`;
                        let firstCoordinate = stringInverters[i].mppts[j].strings[k].getCoordinates().coord[0];
                        let lastCoordinate = stringInverters[i].mppts[j].strings[k].getCoordinates().coord[stringInverters[i].mppts[j].strings[k].getCoordinates().length - 1];
                        stringInverters[i].mppts[j].strings[k].addTextBox(strName, firstCoordinate, lastCoordinate);
                    }
                }
            }
        } else if (stringInverters.length === 1) {
            for (let j = 0; j < stringInverters[0].mppts.length; j++) {
                for (let k = 0; k < stringInverters[0].mppts[j].strings.length; k++) {
                    if(stringInverters[0].mppts[j].strings[k].stringText) {
                        stringInverters[0].mppts[j].strings[k].stringText.removeObject();
                    }
                    strName = `S${k+1}`;
                    stringInverters[0].mppts[j].strings[k].stringName = `M${j+1} S${k+1}`;
                    let firstCoordinate = stringInverters[0].mppts[j].strings[k].getCoordinates().coord[0];
                    let lastCoordinate = stringInverters[0].mppts[j].strings[k].getCoordinates().coord[stringInverters[0].mppts[j].strings[k].getCoordinates().length - 1];
                    stringInverters[0].mppts[j].strings[k].addTextBox(strName, firstCoordinate, lastCoordinate);
                }
            }
        }
    }

    // Helper Functions

    getMicroinverters() {
        return this.microInverters;
    }

    getHybridInverters() {
        const models = exporters.getAllModelType();
        exporters.getModels(this, models);

        let inverters = models.inverters;
        let hybridInverters = inverters.filter(inverter => inverter instanceof HybridInverter)
        return hybridInverters
    }

    updateRoofIntersections() {
        const children = this.getChildren();
        const roofsToExclude = []
        for (let ind = 0; ind < children.length; ind += 1) {
            if(children[ind] instanceof SmartroofModel) {
                children[ind].getAllSmartroofIntersections(true, roofsToExclude);
            }
        }
    }

    get2DVertices() {
        let vertices = [];
        let x = this.stage.imageDimensions.width / 2;
        let y = this.stage.imageDimensions.height / 2;

        vertices.push([x, y]);
        vertices.push([-x, y]);
        vertices.push([-x, -y]);
        vertices.push([x, -y]);

        return vertices;
    }

    get3DVertices() {
        let vertices = [];
        let x = this.stage.imageDimensions.width / 2;
        let y = this.stage.imageDimensions.height / 2;

        vertices.push([x, y, 0]);
        vertices.push([-x, y, 0]);
        vertices.push([-x, -y, 0]);
        vertices.push([x, -y, 0]);

        return vertices;
    }


    getParapetHeight() {
        return 0;
    }

    getTilt() {
        return 0;
    }

    getAzimuth() {
        return 180;
    }

    getZOnTopSurface() {
        return 0;
    }

    isIgnored() {
        return false;
    }

    updateWires(wires) {
        this.wires = wires;
    }

    // Functions pertaining to other objects

    async autoPanelPlacement(appParameters) {

        function deleteSubarrays() {
            for (let model of this.appModels) {
                deleteSubarrayForModel(model);
            }

        }

        function deleteSubarrayForModel(model) {
            if (this.stage.checkInstanceOfPolygonModel(model) || this.stage.checkInstanceOfCylinderModel(model)) {
                for (let child of model.getChildren()) {
                    if (child instanceof Subarray) {
                        child.removeObject();
                    }
                }
            }
        }

        function applyAutoPanelPlacement(object, appMap, moduleProperties) {
            for (let child of object.getChildren()) {
                if ((this.stage.checkInstanceOfPolygonModel(child) || this.stage.checkInstanceOfCylinderModel(child))) {
                    if (child.placable && this.appModels.indexOf(child) > -1) {
                        deleteSubarrayForModel(child);
                        let subarray = new Subarray(this.stage);
                        subarray.moduleProperties = moduleProperties;
                        if (this.stage.checkInstanceOfPolygonModel(child) || this.stage.checkInstanceOfCylinderModel(child)) {
                            subarray.autoPanelPlacement(
                                child.get3DVertices(),
                                child,
                                appMap[child.id.toString()],
                                appMap['solarAccess'][appMap[child.id.toString()]['id']],
                                solarAccessCutoff);
                        }
                    }
                    applyAutoPanelPlacement.call(this, child, appMap, moduleProperties);
                }
            }
        }

        let moduleProperties = appParameters.moduleProperties;
        let solarAccessCutoff = appParameters.solarAccessCutoff / 100;

        let roofMap = roofMapExporter(this.stage);
        let placableModels = [];
        for (let model of this.appModels) {
            placableModels.push(model.getId());
        }

        let tableTypes = [];
        for (let tableType of appParameters.tableTypes) {
            tableTypes.push({
                landscape: tableType.panelOrientation === PANEL_ORIENTATION_LANDSCAPE,
                frameSizeUp: tableType.tableSizeUp,
                frameSizeWide: tableType.tableSizeWide,
                moduleSpacingUp: tableType.moduleSpacingUp,
                moduleSpacingWide: tableType.moduleSpacingWide,
                mountHeight: tableType.mountHeight
            })
        }

        let appRequest = {
            fieldSegment: {
                id: 1,
                tilt: appParameters.tilt,
                frameSpacing: appParameters.tableSpacing,
                azimuth: appParameters.azimuth,
                moduleLength: moduleProperties.moduleLength,
                moduleWidth: moduleProperties.moduleWidth,
                tableTypes: tableTypes,
                associatedObstacles: placableModels
            },
            obstacles: roofMap
        };
        try {
            const appResponse = await axios.post(
                BASE_URL + 'auto-panels?lat=' + this.stage.getLatitude().toString() +
                '&lon=' + this.stage.getLongitude().toString() +
                '&weather=' + this.stage.eventManager.getWeatherID(),
                appRequest
            );
            return Promise.resolve(
                [
                    loBind(applyAutoPanelPlacement, this, this),
                    appResponse.data,
                    moduleProperties,
                    solarAccessCutoff,
                    deleteSubarrays.bind(this)
                ]);
        } catch (error) {
            console.error('ERROR: App: Auto panel placement failed.', error);
            return Promise.reject(error);
        }
    }

    enableAppSelectionMode() {
        this.appModels = [];

        this.stage.selectionControls.selectGroundAndDisable();

        let models = exporters.getAllModelType();

        exporters.getModels(this, models);

        for (let model of models.polygons) {
            if (model.placable) {
                //model.highlightGeometry();
            } else {
                model.switchVisualState(VISUAL_STATES.APP_DISABLE, false);
            }
        }
        for (let model of models.smartroofs) {
            if (model.placable) {
                //model.highlightGeometry();
            } else {
                model.switchVisualState(VISUAL_STATES.APP_DISABLE, false);
            }
        }
        for (let model of models.cylinders) {
            if (model.placable) {
                //model.highlightGeometry();
            } else {
                model.switchVisualState(VISUAL_STATES.APP_DISABLE, false);
            }
        }

        this.canvas.addEventListener('mousedown', this.modelAppSelection, false);
    }

    modelAppSelection = (event) => {
        if (!(event.ctrlKey || event.metaKey || event.which !== 1)) {
            if (!this.stage.textSelectionControls.deSelectSelectedTextObject()) {
                return;
            }
            let intersectingObject = raycastingUtils.getTopObjectOnClick(event, this.stage);
            if (this.stage.checkInstanceOfPolygonModel(intersectingObject) ||
                this.stage.checkInstanceOfCylinderModel(intersectingObject) ||
                intersectingObject instanceof SmartroofModel) {
                if (intersectingObject.placable) {
                    if (this.appModels.indexOf(intersectingObject) < 0) {
                        intersectingObject.switchVisualState(VISUAL_STATES.APP_HIGHLIGHT);
                        this.appModels.push(intersectingObject)
                    } else {
                        intersectingObject.switchMaterialState(this.stage.visualManager
                            .getMaterialStateBasedOnConditions(), false);
                        intersectingObject.switchVisualState(VISUAL_STATES.DEFAULT, true);
                        this.appModels.splice(this.appModels.indexOf(intersectingObject), 1);
                    }
                }
            }
        }
    };

    onCompleteAppSelectionMode() {
        this.stage.visualManager.updateVisualsForEditing(false);
        this.switchVisualState(VISUAL_STATES.DEFAULT, true);
        this.canvas.removeEventListener('mousedown', this.modelAppSelection, false);
    }

    onCancelAppSelectionMode() {
        this.stage.visualManager.updateVisualsForEditing(false);
        this.switchVisualState(VISUAL_STATES.DEFAULT, true);
        this.appModels = [];
        this.canvas.removeEventListener('mousedown', this.modelAppSelection, false);
    }

    getMinMax() {
        const arr = this.plane.geometry.attributes.position.array;
        const maxX = arr[3];
        const minX = arr[0];
        const maxY = arr[1];
        const minY = arr[7];

        return [minX, minY, maxX, maxY];
    }

    switchVisualState(newVisualState, recursive) {
        if (recursive) {
            for (let i = 0; i < this.children.length; i += 1) {
                this.children[i].switchVisualState(newVisualState, recursive);
            }

            if (this.powerRoofs.length) {
                this.powerRoofs.forEach((powerRoof) => {
                    powerRoof.switchVisualState(newVisualState, recursive);
                });
            }
        }

        if (this.visualState !== newVisualState) {
            if (newVisualState === VISUAL_STATES.DEFAULT) {
                this.stage.visualManager.switchDefaultVisualStatesInSequenceForObject(this);
            }
            else {
                this.visualState = newVisualState;
            }

            this.updateVisualsBasedOnStates();
        }
    }

    highlightRoofFacesWhileTilling(faceIds) {
        if(this.powerRoofs.length){
            Object.values(this.powerRoofs[0].smartRoofs).forEach((roof) => {
                roof.getChildren().forEach((face) => {
                    if (faceIds.includes(face.getId())) face.showFaceMeshForTilingSelection();
                    else if(face.isValidFace()) face.hideFaceMeshForTilingSelection();
                });
            });
        }
    }
    unHighlightRoofFacesAfterTilling() {
        if(this.powerRoofs.length){
            Object.values(this.powerRoofs[0].smartRoofs).forEach((roof) => {
                roof.getChildren().forEach((face) => {
                    if(face.isValidFace()) face.hideFaceMeshForTilingSelection();
                });
            });
        }
    }
    getPowerRoofFaces() {
        const powerRoofFacesInfo = [];
        if(this.powerRoofs.length){
            Object.values(this.powerRoofs[0].smartRoofs).forEach((roof) => {
                roof.getChildren().forEach((face) => {
                    if(face.isValidFace()) powerRoofFacesInfo.push(face.getInfoForTilingPopup());
                });
            });
        }
        return powerRoofFacesInfo;
    }

    getPowerRoofMaxDCCapacity() {
        let powerRoofFacesArea = 0;
        let maxDCCapacity = 0;
        if(this.powerRoofs.length){
            Object.values(this.powerRoofs[0].smartRoofs).forEach((roof) => {
                roof.getChildren().forEach((face) => {
                    if(face.isValidFace()) powerRoofFacesArea+= face.computeArea();
                });
            });
            const defaultTileArea = useTilesStore().pvTile.characteristics.length * useTilesStore().pvTile.characteristics.width;
            let numberOfTiles = parseInt((powerRoofFacesArea/defaultTileArea));
            maxDCCapacity = parseFloat((numberOfTiles * parseFloat(useTilesStore().pvTile.characteristics.p_mp_ref)/1000).toFixed(2));
        }
        return maxDCCapacity;
    }

    tilingPowerRoof(isSelectiveTiling, params={faceIdsForTiling: [], capacityValue: 0}){
        if(isSelectiveTiling){
            this.powerRoofs[0]?.tilingForSelectiveFaces(params.faceIdsForTiling);
        }else {
            const numberOfTiles = parseInt((params.capacityValue/parseFloat(useTilesStore().pvTile.characteristics.p_mp_ref))*1000);
            this.powerRoofs[0]?.fillToCapacity(numberOfTiles);
        }
    }

    async enablePowerroofAestheticView(){
        if(!useTilesStore().tilesAestheticMaterial) await coreUtils.getAestheticMaterial(this.stage);
        if(!useTilesStore().cappingsAestheticMaterial) coreUtils.getAestheticMaterialForCapping(this.stage);
        this.powerRoofs.forEach(powerRoof => {
            Object.values(powerRoof.smartRoofs).forEach((roof) => {
                roof.getChildren().forEach(async(face) => {
                    if(face.isValidFace()) {
                        await face?.tilesGrid.switchToAestheticView();
                        face.switchCappingsToAestheticView();
                    }
                });
            });
        });
    }

    disablePowerroofAestheticView(){
        this.powerRoofs.forEach(powerRoof => {
            Object.values(powerRoof.smartRoofs).forEach((roof) => {
                roof.getChildren().forEach(face => {
                    if(face.isValidFace()){
                        face?.tilesGrid.switchOffAestheticView();
                        face.switchCappingsToNormalView();
                    }
                });
            });
        });
    }

    // Visual Functions

    switchMaterialState(newMaterialState, recursive) {
        super.switchMaterialState(newMaterialState, recursive);
        if (this.stage.visualManager.getIn3D() || this.stage.sldView) {
            for (let i = 0, l = this.microInverters.length; i < l; i += 1) {
                this.microInverters[i].hideObject();
            }
        } else {
            if (this.stage.viewManager.showStringing) {
                for (let i = 0, l = this.microInverters.length; i < l; i += 1) {
                    this.microInverters[i].showObject();
                }
            }
        }
    }

    getColorMap() {
        const colorMapping = COLOR_MAPPINGS.GROUND;
        if (this.materialAndVisualStatesExist(colorMapping)) {
            return colorMapping[this.materialState][this.visualState];
        }
        return {};
    }

    updateVisualsBasedOnStates() {
        if (this.materialState === MATERIAL_STATES.SOLID) {
            this.groundImage.switchToSolidMaterial();

            this.plane.receiveShadow = true;
            this.plane.material = this.shadowMaterial;
        } else if (this.materialState === MATERIAL_STATES.TRANSLUCENT) {
            this.groundImage.switchToTranslucentMaterial();

            this.plane.receiveShadow = false;
            this.plane.material = this.material;
        }
        const newColors = this.getColorMap();
        visualUtils.updateMeshWithColor(newColors.MESH_COLOR, this.plane);
        visualUtils.updateMeshWithColor(newColors.MESH_COLOR, this.groundImage.getPlane());
    }

    hideGroundImage() {
        this.groundImage.hide();
    }

    showGroundImage() {
        this.groundImage.show();
    }

    hideGrid() {
        this.gridLines.visible = false;
    }

    showGrid() {
        this.gridLines.visible = true;
    }

    // Universal Functions

    onSelect() {
    }

    deSelect() {}

    showObjectLayer() {
        // In future if layers are used..  it needs to be checked if the camera and model are in the same layer or not!
        for (let child of this.objectsGroup.children) {
            child.layers.enable(0);
        }
    }

    hideObjectLayer() {
        for (let child of this.objectsGroup.children) {
            child.layers.disable(0);
        }
    }

    enableObjectLayer(layer) {
        this.plane.layers.enable(layer);
    }

    disableObjectLayer(layer) {
        this.plane.layers.disable(layer);
    }

    showEdges() {
        this.edgesMesh.visible = true;
    }

    hideEdges() {
        this.edgesMesh.visible = false;
    }

    updateGroundForSLD() {
        this.plane.visible = false;
        this.removeRoofTexture();
        this.hideImage();
        this.gridLines.visible = false;
    }

    updateGroundForDrawing() {
        this.plane.visible = true;
        if(useMapImagesStore().groundMapImageVisible) this.showImage();
        this.gridLines.visible = true;
    }

    static getObjectType() {
        return 'Ground'
    }

    getUUID() {
        return this.uuid;
    }

    getId() {
        return this.id;
    }
}
