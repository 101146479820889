import { CREATED_STATE, DELETED_STATE } from "../../coreConstants";
import ACDB from "../../objects/ac/ACDB";
import DoubleCableTray from "../../objects/ac/cableTrays/DoubleCableTray";
import DoubleSeparateCableTray from "../../objects/ac/cableTrays/DoubleSeparateCableTray";
import SingleCableTray from "../../objects/ac/cableTrays/SingleCableTray";
import Conduit from "../../objects/ac/conduits/Conduit";
import DoubleConduit from "../../objects/ac/conduits/DoubleConduit";
import DoubleSeparateConduit from "../../objects/ac/conduits/DoubleSeparateConduit";
import DCDB from "../../objects/ac/DCDB";
import HybridInverter from "../../objects/ac/HybridInverter";
import Inverter from "../../objects/ac/Inverter";
import AcCable from "../../objects/model/cable/AcCable";
import DcCable from "../../objects/model/cable/DcCable";
import CylinderModel from "../../objects/model/CylinderModel";
import Handrail from "../../objects/model/Handrail";
import RectangleObstruction from "../../objects/model/Rectangle";
import SafetyLine from "../../objects/model/SafetyLine";
import Tree from "../../objects/model/Tree";
import Walkway from "../../objects/model/Walkway";
import Subarray from "../../objects/subArray/Subarray";
import LengthMeasurement from "../../objects/subObjects/LengthMeasurement";
import ThreejsText from "../../objects/subObjects/ThreejsText";
import { getCylinderObstruction, getRectangleObstruction } from "../../utils/exporters";
import * as utils from '../../utils/utils';



export default class SalesModeCylinderModel extends CylinderModel {
    constructor(stage, isObstructionType = true) {
        super(stage, isObstructionType);
        this.canvasName = '';
    }

    saveObject(isCopy = false) {
        const cylinderModelData = super.saveObject(isCopy);
        cylinderModelData.salesModeProperties = this.salesModeProperties;
        cylinderModelData.type = SalesModeCylinderModel.getObjectType();
        cylinderModelData.canvasName = this.canvasName;

        return cylinderModelData;
    }

    loadObject(cylinderModelData, isPaste = false) {
        if (cylinderModelData.canvasName) {
            this.canvasName = cylinderModelData.canvasName;
        }
        else {
            this.canvasName = `CO${this.salesModeProperties.name[this.salesModeProperties.name.length - 1]}`;
        }
        if (!this.validateObject(cylinderModelData).isValid) {
            this.stage.stateManager.add({
                uuid: this.uuid,
                getStateCb: () => DELETED_STATE,
            });

            this.stage.sceneManager.scene.remove(this.objectsGroup);

            if (this.getParent() !== null) {
                this.getParent().removeChild(this);
            }

            this.stage.eventManager
                .customErrorMessage('Cylinder data invalid: Cylinder removed');
            return;
        }

        // load id and name
        if (!isPaste) {
            this.id = cylinderModelData.id;
            this.name = cylinderModelData.name;
        }
        if (cylinderModelData.salesModeProperties) {
            this.salesModeProperties = cylinderModelData.salesModeProperties;
        }
        else if (cylinderModelData.salesModeName) {
            this.salesModeProperties = { name: cylinderModelData.salesModeName, updated: true }
        }

        // load cylinder properties
        this.baseHeight = cylinderModelData.baseHeight;
        this.coreHeight = cylinderModelData.coreHeight;
        this.parapetHeight = cylinderModelData.parapetHeight;
        this.parapetThickness = cylinderModelData.parapetThickness;
        this.tilt = cylinderModelData.tilt;
        this.azimuth = cylinderModelData.azimuth;
        this.setbackInside = cylinderModelData.setbackInside;
        this.setbackOutside = cylinderModelData.setbackOutside;
        this.flashingEnabled = cylinderModelData.flashingEnabled || false;
        this.flashingWidth = cylinderModelData.flashingWidth || 0;
        this.ignored = cylinderModelData.ignored;
        this.placable = cylinderModelData.placable;
        this.obstruction = cylinderModelData.obstruction;
        this.lockedParameter = cylinderModelData.lockedParameter;
        this.topHeight = cylinderModelData.topHeight;

        this.isObstruction  = cylinderModelData.isObstruction;
        if (this.isObstruction) {
            this.setbackInside = null;
            this.flushType = cylinderModelData.flushType;
        }

        for (let i = 0; i < cylinderModelData.outlinePoints.length; i += 1) {
            const outlinePoint = cylinderModelData.outlinePoints[i];
            this.outlinePoints.push(this.stage.initializeOutlinePoints(
                outlinePoint[0],
                outlinePoint[1],
                outlinePoint[2],
                this,
                this.stage,
            ));
        }

        // create cylinder measurement
        this.polygonMeasurement = new LengthMeasurement(this.outlinePoints[0], this.outlinePoints[1], this.stage, this, 0);

        // update geometry
        this.updateGeometry();

        this.polygonMeasurement.hide();

        // load children
        const { children } = cylinderModelData;
        for (let i = 0, len = children.length; i < len; i += 1) {
            if (this.stage.checkObjectTypePolygonModel(children[i])) {
                const polygonModel = this.stage.initializePolygonModel();
                this.addChild(polygonModel);
                polygonModel.loadObject(children[i], isPaste);
                if (polygonModel.getParent() !== this) {
                    console.error('CylinderModel: Mismatch in parent while loading CylinderModel');
                }
            } else if (children[i].type === RectangleObstruction.getObjectType()) {
                const rectangleObstruction = new RectangleObstruction(this.stage);
                this.addChild(rectangleObstruction);
                rectangleObstruction.loadObject(children[i], isPaste);
                if (rectangleObstruction.getParent() !== this) {
                    console.error('CylinderModel: Mismatch in parent while loading CylinderModel');
                }
            } else if (this.stage.checkObjectTypeCylinderModel(children[i])) {
                const cylinderModel = this.stage.initializeCylinderModel();
                this.addChild(cylinderModel);
                cylinderModel.loadObject(children[i], isPaste);
                if (cylinderModel.getParent() !== this) {
                    console.error('CylinderModel: Mismatch in parent while loading CylinderModel');
                }
            } else if (children[i].type === Tree.getObjectType()) {
                const tree = new Tree(this.stage);
                this.addChild(tree);
                tree.loadObject(children[i], isPaste);
                if (tree.getParent() !== this) {
                    console.error('CylinderModel: Mismatch in parent while loading CylinderModel');
                }
            } else if (children[i].type === Inverter.getObjectType()) {
                if (!isPaste) {
                    const inverter = new Inverter(this.stage);
                    this.addChild(inverter);
                    inverter.loadObject(children[i], isPaste);
                    if (inverter.getParent() !== this) {
                        console.error('CylinderModel: Mismatch in parent while loading Inverter');
                    }
                }
            } else if (children[i].type === HybridInverter.getObjectType()) {
                if (!isPaste) {
                    const inverter = new HybridInverter(this.stage);
                    this.addChild(inverter);
                    inverter.loadObject(children[i], isPaste);
                    if (inverter.getParent() !== this) {
                        console.error('CylinderModel: Mismatch in parent while loading Hybrid Inverter');
                    }
                }
            }
            //combiner box
            else if (children[i].type === ACDB.getObjectType()) {
                try {
                    const acdb = new ACDB(this.stage);
                    this.addChild(acdb);
                    acdb.loadObject(children[i], isPaste);
                    if (acdb.getParent() !== this) {
                        console.error('CylinderModel: Mismatch in parent while loading ACDB');
                    }
                } catch (error) {
                    console.error('CylinderModel.js: error in loading ACdb', error);
                }
                // TODO: not a fix for loading later on and then adding the electrical connection
                // this.stage.ground.allAcdbs.push({data:children[i], acdb:acdb, isPaste:false});
            } else if (children[i].type === DCDB.getObjectType()) {
                if (!isPaste) {
                    try {
                        const dcdb = new DCDB(this.stage);
                        this.addChild(dcdb);
                        dcdb.loadObject(children[i], isPaste);
                        if (dcdb.getParent() !== this) {
                            console.error('CylinderModel: Mismatch in parent while loading DCDB');
                        }
                    } catch (error) {
                        console.error('CylinderModel.js: error in loading dcdb', error);
                    }

                }
            } else if (children[i].type === Subarray.getObjectType()) {
                const subarray = new Subarray(this.stage);
                subarray.loadObject(children[i], this, isPaste);
            } else if (children[i].type === Walkway.getObjectType()) {
                const walkway = new Walkway(this.stage);
                this.addChild(walkway);
                walkway.loadObject(children[i], isPaste);
            } else if (children[i].type === SafetyLine.getObjectType()) {
                const safetyLine = new SafetyLine(this.stage);
                this.addChild(safetyLine);
                safetyLine.loadObject(children[i], isPaste);
            } else if (children[i].type === Handrail.getObjectType()) {
                const handrail = new Handrail(this.stage);
                this.addChild(handrail);
                handrail.loadObject(children[i], isPaste);
            } else if (children[i].type === AcCable.getObjectType()) {
                try {
                    const acCable = new AcCable(this.stage);
                    this.addChild(acCable);
                    acCable.loadObject(children[i], isPaste);
                } catch (error) {
                    console.error('CylinderModel.js: error in loading AC cable', error);
                }
            } else if (children[i].type === DcCable.getObjectType()) {
                const dcCable = new DcCable(this.stage);
                this.addChild(dcCable);
                // this.stage.ground.allCables.push({data:children[i], cable:dcCable, isPaste:isPaste});
            } else if (children[i].type === Conduit.getObjectType()) {
                const conduit = new Conduit(this.stage);
                this.addChild(conduit);
                conduit.loadObject(children[i], isPaste);
                this.allConduitAndCabletary.push(conduit);
            } else if (children[i].type === DoubleConduit.getObjectType()) {
                const doubleConduit = new DoubleConduit(this.stage);
                this.addChild(doubleConduit);
                doubleConduit.loadObject(children[i], isPaste);
                this.allConduitAndCabletary.push(doubleConduit);
            } else if (children[i].type === DoubleSeparateConduit.getObjectType()) {
                const doubleSeparateConduit = new DoubleSeparateConduit(this.stage);
                this.addChild(doubleSeparateConduit);
                doubleSeparateConduit.loadObject(children[i], isPaste);
                this.allConduitAndCabletary.push(doubleSeparateConduit);
            } else if (children[i].type === SingleCableTray.getObjectType()) {
                const singleCableTray = new SingleCableTray(this.stage);
                this.addChild(singleCableTray);
                singleCableTray.loadObject(children[i], isPaste);
                this.allConduitAndCabletary.push(singleCableTray);
            } else if (children[i].type === DoubleCableTray.getObjectType()) {
                const doubleCableTray = new DoubleCableTray(this.stage);
                this.addChild(doubleCableTray);
                doubleCableTray.loadObject(children[i], isPaste);
                this.allConduitAndCabletary.push(doubleCableTray);
            } else if (children[i].type === DoubleSeparateCableTray.getObjectType()) {
                const doubleSeparateCableTray = new DoubleSeparateCableTray(this.stage);
                this.addChild(doubleSeparateCableTray);
                doubleSeparateCableTray.loadObject(children[i], isPaste);
                this.allConduitAndCabletary.push(doubleSeparateCableTray);
            } else {
                console.error('CylinderModel: Invalid object type in loadObject');
            }
        }
        this.makeCanvasText();
        if (isPaste) {
            this.saveState({ withoutContainer: false });
        } else {
            this.saveState({ withoutContainer: true });
        }
    }

    getState() {
        const cylinderModelData = super.getState();
        if(this.canvasTextMesh) {
            cylinderModelData.canvasName= this.canvasName;
            cylinderModelData.canvasTextMeshData = {
                position: this.canvasTextMesh.position,
                angle: this.canvasTextMesh.angle,
            } 
        }
        return cylinderModelData;
    }

    loadState(state, fromState) {
        super.loadState(state, fromState);
        if(state.canvasTextMeshData) {
            this.canvasName = state.canvasName;
            this.canvasTextMesh.update(this.canvasName, state.canvasTextMeshData.position, state.canvasTextMeshData.angle);
        }
        if (fromState === CREATED_STATE || fromState === DELETED_STATE) {
            this.canvasName = state.canvasName;
            const getCylObstructionChildren = [];
            getCylinderObstruction(this.stage.ground, getCylObstructionChildren);
            if (!this.canvasName) {
                utils.assignSalesModeNames(getCylObstructionChildren);
            }
            this.makeCanvasText();
        }
        this.stage.selectionControls.setSelectedObject(this.stage.ground);
    }

    async updateObject(properties) {
        let updateGeometryRequired = false;
        let handleChildrenRequired = false;
        let handleSiblingsRequired = false;
        let saveStateRequired = false;
        const options = {
            heightChanged: false,
            prevHeight: null,
            parapetHeightChanged: false,
            prevParapetHeight: null,
            parapetThicknessChanged: false,
        };

        if (Object.prototype.hasOwnProperty.call(properties, 'name') &&
            properties.name !== this.name) {
            this.name = properties.name;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'salesModeName') &&
        properties.salesModeName !== this.salesModeProperties.name) {
        this.salesModeProperties.name = properties.salesModeName;
        this.salesModeProperties.updated = true;
        saveStateRequired = true;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'baseHeight') &&
            properties.baseHeight !== this.baseHeight) {
            this.moveObject(0, 0, properties.baseHeight - this.baseHeight);
            this.stage.lightsManager.setShadowMapParameters();
            this.resetGrandParentSolarAccess();
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'parapetHeight') &&
            properties.parapetHeight !== this.parapetHeight) {
            updateGeometryRequired = updateGeometryRequired || true;
            handleChildrenRequired = true;
            options.parapetHeightChanged = true;
            options.prevParapetHeight = this.parapetHeight;
            this.parapetHeight = properties.parapetHeight;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'parapetThickness') &&
            properties.parapetThickness !== this.parapetThickness) {
            updateGeometryRequired = updateGeometryRequired || true;
            handleChildrenRequired = true;
            options.parapetThicknessChanged = true;
            this.parapetThickness = properties.parapetThickness;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'tilt') &&
            properties.tilt !== this.tilt) {
            updateGeometryRequired = updateGeometryRequired || true;
            handleChildrenRequired = true;
            this.tilt = properties.tilt;
            saveStateRequired = true;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'lockedParameter') &&
            properties.lockedParameter !== this.lockedParameter) {
            this.lockedParameter = properties.lockedParameter;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'topHeight') &&
            this.topHeight !== properties.topHeight) {
            this.topHeight = properties.topHeight;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'azimuth') &&
            properties.azimuth !== this.azimuth) {
            updateGeometryRequired = updateGeometryRequired || true;
            handleChildrenRequired = true;
            this.azimuth = properties.azimuth;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'flushType') && this.isObstruction &&
            properties.flushType !== this.flushType) {
            updateGeometryRequired = updateGeometryRequired || true;
            handleChildrenRequired = true;
            this.flushType = properties.flushType;
            if(properties.flushType){
                this.tilt = this.getParent().tilt===undefined ? 0 : this.getParent().tilt;
                if(this.coreHeight===properties.coreHeight){
                    options.heightChanged = true;
                    options.prevHeight = this.coreHeight;
                    this.coreHeight = 0.1;
                }
                this.azimuth = this.getParent().azimuth===undefined ? 180 : this.getParent().azimuth;
                this.updateCurrentlyLockedParameter();
            }
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'coreHeight') &&
            properties.coreHeight !== this.coreHeight && !options.heightChanged) {
            updateGeometryRequired = updateGeometryRequired || true;
            options.heightChanged = true;
            options.prevHeight = this.coreHeight;
            this.coreHeight = properties.coreHeight;
            saveStateRequired = true;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'setbackInside') &&
            properties.setbackInside !== this.setbackInside) {
            updateGeometryRequired = updateGeometryRequired || true;
            this.setbackInside = properties.setbackInside;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'setbackOutside') &&
            properties.setbackOutside !== this.setbackOutside) {
            updateGeometryRequired = updateGeometryRequired || true;
            this.setbackOutside = properties.setbackOutside;
            saveStateRequired = true;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'obstruction') &&
            properties.obstruction !== this.obstruction) {
            updateGeometryRequired = updateGeometryRequired || true;
            this.obstruction = properties.obstruction;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'ignored') &&
            properties.ignored !== this.ignored) {
            if (properties.ignored) {
                const childSubarrays = this.getChildSubarrays();
                for (let i = 0; i < childSubarrays.length; i += 1) {
                    childSubarrays[i].removeObject();
                }
            } else {
                const siblings = this.getParent().getChildren();
                for (let i = 0; i < siblings.length; i += 1) {
                    if (siblings[i] instanceof Subarray) {
                        siblings[i].deleteTableInsideArea(this.get2DVertices());
                    }
                }
            }
            updateGeometryRequired = updateGeometryRequired || false;
            this.ignored = properties.ignored;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'placable') &&
            properties.placable !== this.placable) {
            updateGeometryRequired = updateGeometryRequired || false;
            this.placable = properties.placable;
        }

        if (updateGeometryRequired) {
            try {
                this.handlePropertiesUpdate(options);
            } catch (error) {
                console.error('ERROR: CylinderModel: Update failed', error);
                return Promise.reject(error);
            }
        }
        if (handleChildrenRequired) {
            const notificationObject = this.stage.eventManager.setPolygonModelLoading();
            this.stage.lightsManager.setShadowMapParameters();

            try {
                await this.handleChildrenConsequences({
                    resized: false,
                    tiltChanged: true,
                });
                this.stage.eventManager.completePolygonModelLoading(notificationObject);
            } catch (error) {
                console.error('ERROR: CylinderModel: changeTilt failed', error);
                this.stage.eventManager.completePolygonModelLoading(notificationObject);
            }
        }
        if (saveStateRequired) {
            this.saveState();
        }
        return Promise.resolve(true);
    }

    makeCanvasText() {
        const text = this.canvasName;
        const position = this.getPosition();
        const angle = 0;

        this.canvasTextMesh = new ThreejsText(text, position, angle, this.stage, this, this.editable,'center','middle', () => {}, true, false, true);
        this.canvasTextMesh.textMesh.fontSize = 0.3;
        this.canvasTextMesh.showObject();
    }

    moveObject(deltaX, deltaY, deltaZ = 0) {
        super.moveObject(deltaX, deltaY, deltaZ);
        if(this.canvasTextMesh)this.canvasTextMesh.update(this.canvasName, this.getPosition());
    }

    updateCanvasText() {
        if(this.canvasTextMesh) {
            this.canvasTextMesh.text = this.canvasName;
            this.canvasTextMesh.update();
        }
    }

    removeObject(isTemporaryDuplicate = false) {
        if(this.canvasTextMesh)this.canvasTextMesh.removeObject();
        super.removeObject(isTemporaryDuplicate);
    }

    clearState() {
        super.clearState();
        this.canvasTextMesh.removeObject();
    }
    static getObjectType() {
        return 'SalesModeCylinderModel';
    }
}