import * as THREE from 'three';
import Tree from "../../objects/model/Tree";
import ThreejsText from '../../objects/subObjects/ThreejsText';
import { CREATED_STATE, DELETED_STATE } from '../../coreConstants';
import LengthMeasurement from '../../objects/subObjects/LengthMeasurement';
import * as utils from '../../utils/utils';
import { getTreeObstructions } from '../../utils/exporters';



export default class SalesModeTree extends Tree {
    constructor(stage) {
        super(stage);
        this.canvasName = '';
    }

    saveObject(isCopy = false) {
        const treeModelData = super.saveObject(isCopy);
        treeModelData.canvasName = this.canvasName;

        return treeModelData;
    }

    loadObject(treeModelData, isPaste = false) {
        if (treeModelData.canvasName) {
            this.canvasName = treeModelData.canvasName;
        }
        else {
            this.canvasName = `TO${this.salesModeProperties.name[this.salesModeProperties.name.length - 1]}`;
        }
        let shouldBeRemoved = true;

        for (let i = 0, len = treeModelData.outlinePoints.length; i < len; i += 1) {
            if (!(treeModelData.outlinePoints[i][0] === 0 &&
                treeModelData.outlinePoints[i][1] === 0 &&
                treeModelData.outlinePoints[i][2] === 0)) {
                shouldBeRemoved = false;
                break;
            }
        }

        if (shouldBeRemoved) {
            this.stage.stateManager.add({
                uuid: this.uuid,
                getStateCb: () => DELETED_STATE,
            });

            this.stage.sceneManager.scene.remove(this.objectsGroup);

            if (this.getParent() !== null) {
                this.getParent().removeChild(this);
            }
            return;
        }

        // load id and name
        if (!isPaste) {
            this.id = treeModelData.id;
            this.name = treeModelData.name;
        }
        if (treeModelData.salesModeProperties) {
            this.salesModeProperties = treeModelData.salesModeProperties;
        }
        else if (treeModelData.salesModeName) {
            this.salesModeProperties = { name: treeModelData.salesModeName, updated: true }
        }

        // load tree properties
        this.trunkBaseHeight = treeModelData.trunkBaseHeight;
        this.trunkHeight = treeModelData.trunkHeight;
        this.crownHeight = treeModelData.crownHeight;
        this.treeId = treeModelData.treeId ? treeModelData.treeId : this.getDefaultValues().treeId;
        this.isProportional = treeModelData.isProportional ? treeModelData.isProportional: this.getDefaultValues().isProportional;

        // set outline points
        for (let i = 0, len = treeModelData.outlinePoints.length; i < len; i += 1) {
            this.outlinePoints.push(this.stage.initializeOutlinePoints(
                treeModelData.outlinePoints[i][0],
                treeModelData.outlinePoints[i][1],
                treeModelData.outlinePoints[i][2],
                this,
                this.stage,
            ));
        }
        this.updateOutlinePointsVisuals(this.getColorMap());

        // create tree measurements
        this.trunkMeasurement = new LengthMeasurement(
            this.outlinePoints[0], this.outlinePoints[1],
            this.stage, this, 0,
        );
        this.crownMeasurement = new LengthMeasurement(
            this.outlinePoints[0], this.outlinePoints[2],
            this.stage, this, 0,
        );

        // update geometry
        this.updateGeometry();
        this.trunkMeasurement.hide();
        this.crownMeasurement.hide();
        const currentURL = window.location.href;
        if (!currentURL.includes('stage-report')) {
            this.loadPolyTree(this);
        }
        this.makeCanvasText();
        if (isPaste) {
            this.saveState({ withoutContainer: false });
        }
        else {
            this.saveState({ withoutContainer: true });
        }
    }

    getState() {
        const treeModelData = super.getState();
        if (this.canvasName) treeModelData.canvasName= this.canvasName;
        if(this.canvasTextMesh) {
            treeModelData.canvasTextMeshData = {
                position: this.canvasTextMesh.position,
                angle: this.canvasTextMesh.angle,
            } 
        }
        return treeModelData;
    }

    loadState(state, fromState){
        super.loadState(state,fromState);
        if(state.canvasTextMeshData) {
            this.canvasName = state.canvasName;
            this.canvasTextMesh.update(this.canvasName, state.canvasTextMeshData.position, state.canvasTextMeshData.angle);
        }
        if (fromState === CREATED_STATE || fromState === DELETED_STATE) {
            this.canvasName = state.canvasName;
            const getTreeObstructionChildren = [];
            getTreeObstructions(this.stage.ground, getTreeObstructionChildren);
            if(!this.canvasName){
                utils.assignSalesModeNames(getTreeObstructionChildren);
            }
            this.makeCanvasText();
        }
        this.stage.selectionControls.setSelectedObject(this.stage.ground);
    }
    // addLoadState() {
    //    return
    // }

    // getScales(number, autoscale) {
    //     const modelScales = new THREE.Vector3(1, 1, 1);
    //     return modelScales;
    // }

    // updateObject Functions
    async updateObject(properties) {
        this.salesCrownRadius = this.crownRadius;
        this.salesTrunkRadius = this.trunkRadius;
        this.name = _.get(properties, 'name', this.name);
        let saveStateRequired = false;

        try {
            if (Object.prototype.hasOwnProperty.call(properties, 'salesModeName') &&
                properties.salesModeName !== this.salesModeProperties.name) {
                this.salesModeProperties.name = properties.salesModeName;
                this.salesModeProperties.updated = true;
                saveStateRequired = true;
            }

            if (Object.prototype.hasOwnProperty.call(properties, 'salesTrunkRadius') &&
                properties.salesTrunkRadius !== this.trunkRadius) {
                this.salesTrunkRadius = properties.salesTrunkRadius;
                if (properties.salesTrunkRadius > 0 && properties.salesTrunkRadius < this.salesCrownRadius)
                    await this.trunkMeasurement.handleValueUpdateSalesMode(properties.salesTrunkRadius).then(() => {
                        return Promise.resolve(true);
                    }, (error) => {
                        return Promise.reject(error);
                    });
                saveStateRequired = true;
            }

            if (Object.prototype.hasOwnProperty.call(properties, 'salesCrownRadius') &&
                properties.salesCrownRadius !== this.crownRadius) {
                this.salesCrownRadius = properties.salesCrownRadius;
                if (properties.salesCrownRadius > 0 && properties.salesCrownRadius > this.salesTrunkRadius)
                    await this.crownMeasurement.handleValueUpdateSalesMode(properties.salesCrownRadius).then(() => {
                        return Promise.resolve(true);
                    }, (error) => {
                        return Promise.reject(error);
                    });
                saveStateRequired = true;
            }

            if (properties.trunkHeight && properties.trunkHeight !== this.trunkHeight) {
                this.changeTrunkHeight(properties.trunkHeight);
                saveStateRequired = true;
            }

            if (properties.crownHeight && properties.crownHeight !== this.crownHeight) {
                this.changeCrownHeight(properties.crownHeight);
                saveStateRequired = true;
            }

            if (properties.treeId && properties.treeId !== this.treeId) {
                this.treeId = properties.treeId;
                // dispose old mesh and geometry
                // load tree 3d model
                this.loadPolyTree(this);
                this.crownEdges.visible = true;
                this.trunkEdges.visible = true;
            }

            if (properties.isProportional !== this.isProportional) {
                this.isProportional = properties.isProportional;
                // dispose old mesh and geometry
                // load tree 3d model
                if (this.polyTrunkMesh) {
                    this.polyTrunkMesh.scale.copy(this.getScales(this.treeId, this.isProportional));
                }
                else {
                    this.loadPolyTree(this);
                }
            }
            if (saveStateRequired) {
                this.saveState();
            }
            return Promise.resolve(true);
        }
        catch (e) {
            console.error("Error in salesmode Tree: ", e);
            return Promise.reject(e);
        }
    }

    async onComplete(geometry) {
        super.onComplete(geometry);
        this.makeCanvasText();
    }

    setPolyTrunkMeshPosition() {
        this.polyTrunkMesh.position.set(
            this.outlinePoints[0].getPosition().x,
            this.outlinePoints[0].getPosition().y,
            this.trunkBaseHeight,
        );
    }

    onSelect() {
        super.onSelect();
        this.trunkMeasurement.disableTextSelection();
        this.crownMeasurement.disableTextSelection();
    }

    makeCanvasText() {
        const text = this.canvasName;
        const position = this.getPosition();
        const angle = 0;

        this.canvasTextMesh = new ThreejsText(text, position, angle, this.stage, this, this.editable,'center','middle', () => {}, true, false, true);
        this.canvasTextMesh.textMesh.fontSize = 0.3;
        this.canvasTextMesh.showObject();
    }

    
    moveObject(deltaX, deltaY, deltaZ = 0) {
        super.moveObject(deltaX, deltaY, deltaZ);
        if(this.canvasTextMesh)this.canvasTextMesh.update(this.canvasName, this.getPosition());
    }

    updateCanvasText() {
        if(this.canvasTextMesh) {
            this.canvasTextMesh.text = this.canvasName;
            this.canvasTextMesh.update();
        }
    }

    removeObject(isTemporaryDuplicate = false) {
        super.removeObject(isTemporaryDuplicate);
        if(this.canvasTextMesh)this.canvasTextMesh.removeObject();
    }

    clearState() {
        super.clearState();
        this.canvasTextMesh.removeObject();
    }
    
}