import ThreejsText from '../../subObjects/ThreejsText';
import OutlinePoints from '../../subObjects/OutlinePoints';
import * as THREE from 'three';
import LengthMeasurement from '../../subObjects/LengthMeasurement';
import { EDGE_PRECISION } from '../../../coreConstants';


export default class Edge {
    constructor(parent, stage, outlinePoint1, outlinePoint2, measurementTextFlag, index1 = -1, index2 = -1, editable = false) {
        this.belongsTo = parent;
        this.parent = parent;
        this.stage = stage;
        this.outlinePoint1 = outlinePoint1;
        this.outlinePoint2 = outlinePoint2;
        this.objectsGroup = new THREE.Group();
        this.objectsGroup.container = this;
        this.objectsGroup.name = 'Edge';
        this.stage.sceneManager.scene.add(this.objectsGroup);
        
        // Handle point1 coordinates
        const x1 = outlinePoint1.vertex ? outlinePoint1.vertex.x : outlinePoint1.x;
        const y1 = outlinePoint1.vertex ? outlinePoint1.vertex.y : outlinePoint1.y;
        const z1 = outlinePoint1.vertex ? outlinePoint1.vertex.z : outlinePoint1.z;
        
        // Handle point2 coordinates 
        const x2 = outlinePoint2.vertex ? outlinePoint2.vertex.x : outlinePoint2.x;
        const y2 = outlinePoint2.vertex ? outlinePoint2.vertex.y : outlinePoint2.y;
        const z2 = outlinePoint2.vertex ? outlinePoint2.vertex.z : outlinePoint2.z;

        this.point1 = new THREE.Vector3(x1, y1, z1);
        this.point2 = new THREE.Vector3(x2, y2, z2);
        
        this.point1.z += 60;
        this.point2.z += 60;
        this.createHash();
        this.highlightColor = 0x28ffed;
        this.measurementTextFlag = measurementTextFlag;
        this.index1 = index1;
        this.index2 = index2;
        this.editable = editable;
        this.status = true;
        this.inputError = this.stage.eventManager.wrongLengthInputError;
        if (this.measurementTextFlag) this.measurementText = this.makeMeasurementText();

        // Math.floor(Date.now() / 1000)
        // console.log('Math.floor(Date.now() / 1000): ', Math.floor(Date.now() / 1000));
        // this.makeEdgeMesh();
        if (this.measurementText) this.measurementText.hideObject();
        // if (this.measurementText) this.belongsTo.EdgesMesh.push(this.measurementText.textMesh);
    }

    createHash() {
        const currentVertex = this.point1;
        const nextVertex = this.point2;
        const currentVertexHash = `${(currentVertex.x).toFixed(EDGE_PRECISION)},${(currentVertex.y).toFixed(EDGE_PRECISION)}`;
        const nextVertexHash = `${(nextVertex.x).toFixed(EDGE_PRECISION)},${(nextVertex.y).toFixed(EDGE_PRECISION)}`;

        this.edgeHash = `${currentVertexHash}_${nextVertexHash}`;
        this.reverseEdgeHash = `${nextVertexHash}_${currentVertexHash}`;
    }

    makeMeasurementText() {
        const endPointClone = this.point2.clone();
        endPointClone.z = 0;
        const startPointClone = this.point1.clone();
        startPointClone.z = 0;
        const slope = (startPointClone.y - endPointClone.y) / (startPointClone.x - endPointClone.x);
        let textAngle = Math.atan(slope);
        // if ((endPointClone.y > startPointClone.y) && (endPointClone.x < startPointClone.x) && this.editable) {
        //     textAngle = Math.PI + textAngle;
        // }
        // else if ((endPointClone.y < startPointClone.y) && (endPointClone.x < startPointClone.x) && this.editable) {
        //     textAngle = Math.PI + textAngle;
        // }
        // else if ((endPointClone.y < startPointClone.y) && (endPointClone.x > startPointClone.x) && !this.editable) {
        //     textAngle = Math.PI + textAngle;
        // }
        // else if ((endPointClone.y > startPointClone.y) && (endPointClone.x > startPointClone.x) && !this.editable) {
        //     textAngle = Math.PI + textAngle;
        // }
        
        const lengthInMeters = startPointClone.distanceTo(endPointClone);
        // const lengthInFeets = lengthInMeters * 3.28084;
        // const lengthInInches = ((((lengthInFeets).toFixed(4)) % 1) * 12).toFixed(2);
        // const length = `${String((((lengthInFeets).toFixed(4)) - (((lengthInFeets).toFixed(4)) % 1)).toFixed(0))}'${String(lengthInInches)}"`;
        // const midPosition = (endPointClone.add(startPointClone)).divideScalar(2);
        const zoom = 1/this.stage.getNormalisedZoom() * 10;
        let zoomFactor = zoom;
        if(zoom > 1.3) zoomFactor = 1.3;

        const midPosition = this.getPerpendicularVectorToMidpoint(endPointClone, startPointClone, zoomFactor/4);

        return new ThreejsText(lengthInMeters, midPosition, textAngle, this.stage, this, this.editable,'center','middle',LengthMeasurement.isValidInput);
    }
    getPerpendicularVectorToMidpoint(vectorA, vectorB, distance) {
        // Calculate the midpoint of the line segment
        const midpoint = new THREE.Vector3();
        midpoint.addVectors(vectorA, vectorB).multiplyScalar(0.5);
      
        // Calculate the direction vector from the midpoint to vectorB
        const directionVector = new THREE.Vector3();
        directionVector.subVectors(vectorB, midpoint);
      
        // Normalize the direction vector
        directionVector.normalize();
      
        // Rotate the unit vector by 90 degrees in the XY plane
        const perpendicularVector = new THREE.Vector3(-directionVector.y, directionVector.x, 0);
      
        perpendicularVector.multiplyScalar(-distance);
      
        // Add the perpendicular vector to the midpoint to get the final point
        const perpendicularPoint = new THREE.Vector3();
        perpendicularPoint.addVectors(midpoint, perpendicularVector);
      
        return perpendicularPoint;
    }

    // eslint-disable-next-line class-methods-use-this
    makeEdgeMaterial() {
        const lineMaterial = new THREE.LineBasicMaterial({
            color: 0xff0000,
            linewidth: 1,
        });
        return lineMaterial;
    }

    makeEdgeGeometry() {
        const lineGeometry = new THREE.BufferGeometry();
        lineGeometry.setFromPoints([this.point1, this.point2]);
        return lineGeometry;
    }

    makeEdgeMesh() {
        this.linesMesh = new THREE.Line(
            this.makeEdgeGeometry(),
            this.makeEdgeMaterial(),
        );
        this.objectsGroup.add(this.linesMesh);
        this.linesMesh.name = 'edge';
        this.linesMesh.userData = {
            belongsTo: this.belongsTo,
            type: 'edge',
            edge: this,
        };
        // this.belongsTo.EdgesMesh.push(this.linesMesh);
    }
    measurementTextUpdate() {
        const endPointClone = this.point2.clone();
        endPointClone.z = 0;
        const startPointClone = this.point1.clone();
        startPointClone.z = 0;
        const slope = (startPointClone.y - endPointClone.y) / (startPointClone.x - endPointClone.x);
        let textAngle = Math.atan(slope);
        // if ((endPointClone.y > startPointClone.y) && (endPointClone.x < startPointClone.x) && this.editable) {
        //     textAngle = Math.PI + textAngle;
        // }
        // else if ((endPointClone.y < startPointClone.y) && (endPointClone.x < startPointClone.x) && this.editable) {
        //     textAngle = Math.PI + textAngle;
        // }
        const lengthInMeters = startPointClone.distanceTo(endPointClone);
        // const lengthInFeets = lengthInMeters * 3.28084;
        // const lengthInInches = ((((lengthInFeets).toFixed(4)) % 1) * 12).toFixed(2);
        // const length = `${String((((lengthInFeets).toFixed(4)) - (((lengthInFeets).toFixed(4)) % 1)).toFixed(0))}'${String(lengthInInches)}"`;
        // const midPosition = (endPointClone.add(startPointClone)).divideScalar(2);
        const zoom = 1/this.stage.getNormalisedZoom() * 10;
        let zoomFactor = zoom;
        if(zoom > 1.3) zoomFactor = 1.3;

        const midPosition = this.getPerpendicularVectorToMidpoint(startPointClone,endPointClone,zoomFactor/4);
        this.createHash();
        if (this.measurementText) this.measurementText.update(lengthInMeters, midPosition, textAngle);
    }

    updateEdge(point1, point2) {
        this.point1 = point1.clone();
        this.point2 = point2.clone();
        this.point1.z += 10;
        this.point2.z += 10;
        this.linesMesh.geometry.setFromPoints([this.point1, this.point2]);
        this.measurementTextUpdate();
    }

    moveObjectWithoutConsequences(deltaX, deltaY) {
        this.point1.x += deltaX;
        this.point1.y += deltaY;
        this.point2.x += deltaX;
        this.point2.y += deltaY;
        this.measurementTextUpdate();
    }

    updateColor(color) {
        this.linesMesh.material.color.setHex(color);
    }

    highlightOnHover() {
        this.updateColor(this.highlightColor);
    }

    unHighlight() {
        this.updateColor(this.defaultPointColor);
    }

    onSelect() {
        this.isSelected = true;
        if (this.measurementText && this.stage.viewManager.lengthVisible) this.measurementText.showObject();
        this.edgeMesh.visible = true;
    }

    onDeselect() {
        this.isSelected = false;
        if (this.measurementText) this.measurementText.hideObject();
        this.edgeMesh.visible = false;
    }

    hideObject() {
        if (this.measurementText) this.measurementText.hideObject();
        this.objectsGroup.visible = false;
    }

    showObject() {
        if (this.status && !this.stage.visualManager.in3D) {
            if(this.belongsTo?.connectedPowerRoof) {
                if (this.measurementText) {
                    this.measurementText.showObject();
                    this.measurementText.editable = false;
                }
            }else {
                if (this.measurementText && this.stage.viewManager.lengthVisible) this.measurementText.showObject();
            }
            this.objectsGroup.visible = true;
        }
    }

    removeObject() {
        if (this.measurementText) this.measurementText.removeObject();
        this.stage.sceneManager.scene.remove(this.objectsGroup);
    }
}