<template>
  <div id="app">
    <div v-if="environmentError" class="environment-error-page">
      <div class="environment-error-message">
        {{ environmentErrorMessage }}
      </div>
      <div class="environment-error-code">
        Code: {{ environmentError }}
      </div>
    </div>
    <router-view
      v-else
    />
    <IntercomIcon v-show="isMobileView && intercomIconStatus == 'Hide'" />
    <BulkLeadUploadedPopup v-if="
      bulkLeadImportState.isBulkLeadUploadedPopupVisible"/>
    <reviewerDialog v-if="isReviewDialogVisible" :isReviewDialogVisible="isReviewDialogVisible"/>
    <NotificationDrawers
      v-model:activeDrawer="activeDrawer"
      v-model:drawerData="drawerData"
    />
  </div>
</template>
<script>
import { h } from 'vue';
import API from "./services/api";
import {
  isIntercomEnabled,
  VITE_APP_ENVIRONMENT,
  environmentError,
} from "./constants";
import { mapActions, mapState } from "pinia";
import { useIntegrationStore } from "./stores/integration";
import { useMiscStore } from "./stores/misc";
import { useUiStore } from "./stores/ui";
import { useReviewStore } from "./stores/review"
import {
  initializeStore,
  getUserFromLocalStorage,
  getOrganisationFromLocalStorage,
} from "./utils";
import {
  initializeUnsignedIntercom,
  initializeSignedIntercom,
} from "./plugins/intercom";
import NotificationCards from "./pages/notificationPopUp/NotificationCards.vue";
import IntercomIcon from "./components/ui/IntercomIcon.vue"
import reviewerDialog from "./pages/commonComponents/reviewerDialog.vue";
import BulkLeadUploadedPopup from './pages/leadManagement/components/bulkLeadUploadedPopup.vue';
import { clearImpLocalStorageInfoOnLogoutOrReset } from "./stores/auth";
import NotificationDrawers from './components/NotificationDrawers.vue';

function isUserLoggedIn() {
  let userSessionInformation = getUserFromLocalStorage();
  let orgInfo = getOrganisationFromLocalStorage();
  let token = userSessionInformation.token;

  return token && orgInfo.id;
}

export default {
  name: "App",
  components: {
    NotificationDrawers,
    IntercomIcon,
    reviewerDialog,
    BulkLeadUploadedPopup,
  },
  data() {
    return {
      environmentError,
      activeDrawer: "",
      drawerData: null,
    };
  },
  async created() {
    this.addListenerForResize();
    this.disableScrollNumberInput();
    API.SET_AXIOS_RESPONSE_HANDLER();
    API.SET_DATABASE_URL();
    
    // Don't initialize intercom for these routes
    if (
      !(
        window.location.pathname.includes("stage-report") ||
        window.location.pathname.includes("stage") ||
        window.location.pathname.includes("media")
      )
    ) {
      this.setTokenStoreAndIntercom();
    } else {
      useUiStore().intercomIconStatus = "Show"
    }
    document.body.style.height = `${window.innerHeight}px`;
    window.onresize = () => {
      document.body.style.height = `${window.innerHeight}px`;
    };
    this.setProjectDataFromSessionStorage();

    this.setTestNotificationFunction();
    this.disableScrollForNumberInputs()
  },
  computed: {
    ...mapState(useMiscStore, {
      notificationList: (state) => state.notificationState.notificationList,
      tempMessage: (state) => state.notificationState.tempMessage,
      bulkLeadImportState: (state) => state.bulkLeadImportState,
    }),
    ...mapState(useReviewStore, {
      isReviewDialogVisible: 'GET_REVIEW_STATUS',
    }),
    ...mapState(useUiStore, {
      isMobileView: (state) => state.isMobileView,
      intercomIconStatus: "intercomIconStatus",
    }),
    environmentErrorMessage() {
      if (this.environmentError == 'invalid-environment') {
        return `" ${VITE_APP_ENVIRONMENT}" is not recognized as a valid environment. It may have to be configured before it can be used.`
      } else if (this.environmentError == 'environment-not-set') {
        return `Please make sure you have a valid "env.local" file with the variable "VITE_APP_ENVIRONMENT" set to a valid environment.`
      }

      return "Unknown environment error."
    },
  },
  methods: {
    ...mapActions(useIntegrationStore, {
      setProjectDataFromSessionStorage: "SET_PROJECTDATA_FROM_SESSION_STORAGE",
    }),
    ...mapActions(useUiStore, ["UPDATE_SCREEN_WIDTH","SET_ACTIVE_DESIGN_SECTION","SET_ACTIVE_LEAD_SUMMARY_TAB","SET_CLOSE_CHAT_BOX"]),

    handleBeforeUnload(event) {
      event.preventDefault();
    },
    disableScrollNumberInput() {
        // disable wheel on input number field
        const vm = this
        document.addEventListener("wheel", function(event){
            if(document.activeElement.type === "number" &&
            document.activeElement.classList.contains("disableWheel"))
            {
                document.activeElement.blur();
            }
        });
    },
    registerBeforeUnloadListener() {
      if(this.bulkLeadImportState.isSSEProcessing == false) {
        return;
      } else {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
      }
    },
    handleOpenDrawer(value) {
      if (value?.type == "redirectdesign") {
        this.SET_ACTIVE_DESIGN_SECTION('designSummary');
        this.SET_ACTIVE_LEAD_SUMMARY_TAB('design');
        this.SET_CLOSE_CHAT_BOX(false);
        this.$router.push({ name: 'leadSummary:design', params:{leadId:value?.lead_id,designId:value?.design_id} }); 
      }
      this.drawerData = value;
    },
    handleShowDrawer(bool) {
      this.activeDrawer = bool
    },
    async handleNotificationRead(notif,qparams) {
      await API.NOTIFICATIONS.MARK_INDIVIDUAL_READ(notif.id,qparams);
    },
    setTokenStoreAndIntercom() {
      let userSessionInformation = getUserFromLocalStorage();
      let isLoggedIn = isUserLoggedIn()

      if (isLoggedIn) {
        API.SET_TOKEN_HEADER(userSessionInformation.token);
        initializeStore();
        if (isIntercomEnabled) {
          initializeSignedIntercom(userSessionInformation.email, this.isMobileView);
        }
        return
      }

      // If not logged in
      clearImpLocalStorageInfoOnLogoutOrReset()
      
      const isAuthRequiredForCurrentRoute = this.$route.meta?.requiresAuth;
      if (!isAuthRequiredForCurrentRoute && isIntercomEnabled) {
        initializeUnsignedIntercom(this.isMobileView);
      }
    },
    setTestNotificationFunction() {
      window.sendTestNotification = () => {
        let newObj = {
          "id": 44989,
          "action_by": 3164,
          "action_by_name": "crm.beta.us.sales1 dd",
          "action_for_name": "crm.beta.us.sales1 dd",
          "action_for": 3164,
          "content_obj_type": "task",
          "content_obj_name": "Add Incentives",
          "content_obj_id": 35689,
          "action": "%%action_by%% assigned %%content_object_name%% to %%action_for%%",
          "type": "task",
          "content_obj_owner_id": 3164,
          "created_at": "2024-08-24T08:23:28.195655Z",
          "notes": null,
          "user_notification_type": "collaboration"
        }
        useMiscStore().notificationState.tempMessage = newObj
      }
    },
    addListenerForResize() {
      window.addEventListener("resize", () => {
        this.UPDATE_SCREEN_WIDTH(window.innerWidth);
      });
      
      // For updating the window width the first time
      this.UPDATE_SCREEN_WIDTH(window.innerWidth);
    },
    disableScrollForNumberInputs() {
      document.addEventListener('wheel', function(event) {
        if (document.activeElement.type === 'number' && event.target.type === 'number') {
          event.preventDefault();
        }
      }, { passive: false });
    },
  },
  watch: {
    'bulkLeadImportState.isSSEProcessing': function(newValue) {
      if (newValue == true) {
        this.registerBeforeUnloadListener();
      } else {
      window.removeEventListener('beforeunload', this.handleBeforeUnload);
      }
    },

    tempMessage(obj) {
      if (!obj) {
        return;
      }

      // Don't show notifications on these pages
      let noNotificationsPageList = ['studio']
      if (noNotificationsPageList.includes(this.$route.name)) {
        return
      }

      let newObj = JSON.parse(JSON.stringify(obj));

      let notification_details = [
        {
          notification_details: newObj,
        },
      ];
      let notificationInstance = this.$notify({
        message: h(NotificationCards, {
          notificationsList: notification_details,
          isSlide: true,
          onHandleClick: this.handleOpenDrawer,
          onShowDrawer: this.handleShowDrawer,
          onNotificationRead: this.handleNotificationRead,
        }),
        duration: 5000,
        customClass: "crm-notification",
        showClose: false,
      });
      this.notificationList.push(notificationInstance);
    },
    isMobileView() {
      let email = getUserFromLocalStorage().email
      if (email) {
        initializeSignedIntercom(email, this.isMobileView)
      } else {
        initializeUnsignedIntercom(this.isMobileView)
      }
    }
  },
};
</script>

<style>
@import "./assets/iconFonts/navBar/styles.css";
@import "./assets/iconFonts/leftSideBar/left-side-bar.css";
@import "./assets/iconFonts/home/home.css";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: Helvetica Neue;

  /* this font is switzer but it will show the name Helvetica Neue everywhere (new font) */ 
  src: url(./assets/font/Switzer-Regular.otf);


  /* this font is Helvetica Neue (old font)  */
  /* src: url(./assets/font/HelveticaNeue.otf); */
}

body {
  font-family: "Helvetica Neue";
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.el-popover {
  padding: 0 !important;
  border: 1px solid #141414 ;
  z-index: 10000 !important;
}
.no-border-popover{
  border: none !important;
}
.popover-avatarTest {
  top:145px !important;
  border: transparent !important;
  margin-top: 30px !important;
}
.el-popover.panelSummaryPopoverLight {
  border: 1px solid #f8f8f8 !important;
}

.el-popper[x-placement^="right"] .popper__arrow {
  border-right-color: #141414 !important;
}

.el-popover {
  padding: 0 !important;
  border: 1px solid #141414 ;
  z-index: 10000 !important;
}
.popover-avatarTest {
  border: transparent !important;
  margin-top: 30px !important;
}
.el-popover.panelSummaryPopoverLight {
  border: 1px solid #f8f8f8 !important;
}

#app {
  height: 100%;
  position: relative;
}
#ps-loading-background {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  opacity: 0.5;
  background-color: #000000;
  z-index: 9995;
  overflow: hidden;
}
.ps-loading-background.hide {
  display: none;
}
#ps-loading-container {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  /* background-color: #000000; */
  /* opacity: 0.5; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  pointer-events: none;
}
.ps-loading-container.hide {
  display: none;
}
#ps-loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #409eff;
  width: 70px;
  height: 70px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Fixes double tap for element-plus el-select on iOS */
/* Credit: https://stackoverflow.com/a/61699039/4228422 */
.el-scrollbar__bar {
  opacity: 1!important;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>

<style lang="scss">
// These are global css needs to available across the app
//  classes which are appended to body can't be made scoped
@import "./styles/components/notification";
@import "./styles/components/switch";
@import "./styles/components/select";
@import "./styles/components/radio";
@import "./styles/components/dialog";
@import "./styles/components/checkbox";
</style>

<style>
::-webkit-scrollbar {
  width: 5px;
  border-radius: 4px;
}

/* Track */

::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #cccccc;
  border: 4px solid transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #cccccc !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.el-drawer__body{
  padding: 0px;
}
.el-dialog{
  padding: 0px
}

.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: #222 !important;
}

.el-dialog__headerbtn .el-dialog__close {
  font-size: 1.5rem;
  color: #222 !important;
}

.el-button{
  padding: 12px 20px;
  height: auto;
}
.el-radio-group{
  flex-wrap: nowrap;
}
.el-tag{
 line-height: 30px;
}
.el-tabs__item{
  height: 40px
}

.el-input__wrapper {
  background-color: #e8edf2;
  border: none;
  font-size: 16px;
  color: #222;
  padding: 0 15px;
}

.el-input__inner {
  color: #222;
  height: 40px;
}

.el-select__wrapper {
  background-color: #e8edf2;
  border-radius: 4px;
  height: 40px;
}

.el-input__wrapper,
.el-select__wrapper {
  padding: 0 15px;
  box-shadow: none !important;
}

.el-select__caret {
  font-size: 24px;
  color: #222;
}

.el-select__wrapper.is-disabled .el-select__caret{
  opacity: 0.5;
}

.el-select__wrapper.is-disabled {
  pointer-events: auto;
}

.el-date-editor {
  border: none;
}

.el-date-editor .el-input__wrapper {
  height: auto;
  font-size: 14px;
}

.el-date-editor .el-input__prefix {
  cursor: default;
}

.el-select__placeholder {
  z-index: 0;
}

.el-select__selected-item {
  color: #222;
}
.el-form-item__content{
  width: 100%;
}

.inputBoxStyler {
  appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 2px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 28px;
  line-height: 28px;
  outline: none;
  padding: 0 15px;
  -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 90%;
  font-size: 12px;
}
</style>

<style scoped>

.environment-error-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 12px;
}

.environment-error-message {
  font-size: 16px;
}

.environment-error-code {
  color: rgb(150,150,150);
}



</style>