import * as THREE from 'three';
import { SmartroofModel } from "./SmartroofModel";
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';
import * as utils from '../../../utils/utils';
import * as raycastingUtils from '../../../utils/raycastingUtils';
import { MINIMUM_NUMBER_OF_POINTS } from "../../subObjects/TextBox";
import { COMPLEX_GEOMETRY_ERROR, CREATED_STATE, DELETED_STATE, INSUFFICIENT_VERTICES, INVALID_CORE_HEIGHT_ERROR, INVALID_TILT_ERROR, LAST_EDGE_INTERSECTION_ERROR, LESS_VERTICES_THAN_NEEDED_FOR_DRAWING_ERROR, LESS_VERTICES_THAN_NEEDED_FOR_PLACING_ERROR, OUT_OF_GROUND_ERROR, PATIO_HEIGHT, POWER_PATIO, VERTEX_EQUIVALENT_ERROR, VERTEX_OVER_EDGE_ERROR } from "../../../coreConstants";
import Edge from "./Edge";
import { getChildrenSequence } from "./smartroofUtils";
// import PenToolRoofFace from "./PenToolRoofFace";
import FaceVertex from "./FaceVertex";
import PenTool from './PenTool';
import RoofVertex from './RoofVertex';
import Subarray from '../../subArray/Subarray';
import { CSG } from '../../../utils/csg-lib';
import Dormer from './Dormer';
import { getPatiosExcludingModel } from '../../../utils/exporters';
import AttachObject from '../../subObjects/AttachObject';
import SmartroofFace from './SmartroofFace';
import Walkway from '../Walkway';
import * as JSTSConverter from '../../../utils/JSTSConverter';
import EastWestRack from '../../../lib/EastWestRacking';

let PenToolRoofFace;
import('./PenToolRoofFace').then(module => {
  PenToolRoofFace = module.default;
}).catch(error => {
  console.error("Error loading module: " + error);
});



export default class PenToolRoofModel extends SmartroofModel {
    constructor(stage) {
        super(stage);
        this.stage = stage;
        this.roofFaces = [];
        this.faceVertices = [];
        this.perimeterPoints = [];
        this.stage.ground.addChild(this);
        this.startingVertex = null;
        this.isDummy = false;
        this.completeLoop = [];
        this.completeLoopFaceVertices = [];
        this.faceVerticesMap = new Map();
        this.type = "PenToolRoofModel";
        this.isPenToolRoofModel = true;
        this.isNew = false;
        this.stage.stateManager.add({
            uuid: this.uuid,
            getStateCb: () => CREATED_STATE,
            withoutContainer: true,
        });
        this.isAutomated = false;
    }
    updateFaceMeshCSG() {
        this.roofFaces.forEach((face) => {
            const meshGeom = face.faceMesh.geometry.clone();
            meshGeom.computeVertexNormals();
            face.faceMeshCSG = CSG.fromGeometry(meshGeom);
        });
    }
    updateGeometry() {
        const faceGeometries = [];
        for (let i = 0; i < this.roofFaces.length; i++) {
            const roofFace = this.roofFaces[i];
            roofFace.updateVertices();
            const geo = this.createFaceGeometry(roofFace);
            if (geo) {
                faceGeometries.push(geo);
            }
        }
        if (faceGeometries.length === 0) {
            // this.coreMesh.visible = false;
            return;
        }

        const finRoofGeometry =
            BufferGeometryUtils.mergeGeometries(faceGeometries);

        finRoofGeometry.translate(0, 0, this.baseHeight);

        this.coreMesh.visible = true;
        if (this.stage.selectionControls.getSelectedObjects().includes(this)) {
            // this.polygonMeasurement.show();
        }

        if (this.coreMesh.geometry) this.coreMesh.geometry.dispose();
        this.coreMesh.geometry = finRoofGeometry;
        //call measurementTextUpdate of all testEdge
        this.testEdges.forEach((edge) => {
            edge.measurementTextUpdate();
        });
        // this.updateTilt();
        // this.updateHeightFunction();
        this.updateCompleteLoop();
    }

    updateHeightFunction() {
        let height = 0;
        this.roofFaces.forEach((face) => {
            height += face.coreHeight;
        });
        this.coreHeight = height / this.roofFaces.length;
    }

    updateTilt() {
        let tilt = 0;
        this.roofFaces.forEach((face) => {
            tilt += face.tilt;
        });
        this.tilt = tilt / this.roofFaces.length;
    }

    updateCompleteLoop() {
        if (this.completeLoopFaceVertices.length === 0) return;
        this.completeLoopFaceVertices = this.completeLoopFaceVertices.filter(
            (vertex) => vertex !== undefined
        );
        this.completeLoop = [];
        this.startingVertex = new RoofVertex(
            this.stage,
            this.completeLoopFaceVertices[0].getPosition(),
            null,
            null,
            true
        );
        const lastVertex = new RoofVertex(
            this.stage,
            this.completeLoopFaceVertices[
                this.completeLoopFaceVertices.length - 1
            ].getPosition(),
            null,
            null,
            false
        );
        this.startingVertex.previous = lastVertex;
        lastVertex.next = this.startingVertex;
        let tempStartingVertex = this.startingVertex;
        for (let i = 0; i < this.completeLoopFaceVertices.length; i++) {
            if (i !== 0 && i !== this.completeLoopFaceVertices.length - 1) {
                const tempVertex = new RoofVertex(
                    this.stage,
                    this.completeLoopFaceVertices[i].getPosition(),
                    null,
                    tempStartingVertex,
                    false
                );
                tempStartingVertex.next = tempVertex;
                tempStartingVertex = tempVertex;
            }
            if (this.completeLoopFaceVertices[i])
                this.completeLoop.push(
                    this.completeLoopFaceVertices[i].getPosition()
                );
        }
        tempStartingVertex.next = lastVertex;
        lastVertex.previous = tempStartingVertex;
    }

    updatePermimeterPoints() {
        this.perimeterPoints = [];
        for (let i = 0; i < this.completeLoopFaceVertices.length; i++) {
            this.perimeterPoints.push(
                this.completeLoopFaceVertices[i].getPosition()
            );
        }
    }

    createCompleteLoop(completeLoop) {
        this.completeLoop = [];

        //remove duplicate vertices
        completeLoop = completeLoop.filter((vertex, index, self) => {
            return (
                index ===
                self.findIndex((t) => t.x === vertex.x && t.y === vertex.y)
            );
        });

        completeLoop = !utils.isClockwise(completeLoop)
            ? completeLoop.reverse()
            : completeLoop;

        this.completeLoopFaceVertices = [];
        completeLoop.forEach((vertex) => {
            this.completeLoopFaceVertices.push(
                this.parent.pointsMap.get(
                    `${vertex.x.toFixed(4)},${vertex.y.toFixed(4)}`
                )
            );
        });
    }

    onSelect() {
        this.coreMesh.material.opacity = 0.4;
        this.is3d = this.stage.visualManager.in3D;

        // show outline points
        for (let i = 0, len = this.faceVertices.length; i < len; i += 1) {
            this.faceVertices[i].showObject();
        }

        // loop to show testedges
        for (let i = 0, len = this.testEdges.length; i < len; i += 1) {
            this.testEdges[i].showObject();
        }

        this.stage.dragControls.add(
            this,
            this.handleDragMove.bind(this),
            this.handleDragEnd.bind(this),
            this.handleDragStart.bind(this),
            this.handleDragCancel.bind(this)
        );

        if (!this.stage.selectionControls.isMultiSelect()) {
            for (let i = 0, len = this.faceVertices.length; i < len; i += 1) {
                const v = this.faceVertices[i];
                this.stage.dragControls.add(
                    v,
                    v.moveObject.bind(v),
                    v.placeObject.bind(v),
                    v.handleDragStart.bind(v)
                );
            }
        }
        this.isSelected = true;
        if (this.attachedObjects?.attachments.length > 0) {
            this.attachedObjects.attachments.forEach((attachment) => {
                if (attachment?.objectType === POWER_PATIO) {
                    attachment.initGroundModelIntersectParams();
                }
            });
        }
        if (utils.isPatioPresent(this.stage)) {
            this.stage.ground.patiosForModelConstraint =
                getPatiosExcludingModel(this.stage.ground, this);
        }
    }

    deSelect() {
        this.coreMesh.material.opacity = 0.25;
        // this.stage.rendererManager.getDomElement().removeEventListener('mousemove', this.onMouseMove, false)
        // hide outline points
        for (let i = 0, len = this.faceVertices.length; i < len; i += 1) {
            this.faceVertices[i].hideObject();
        }
        // disable innerEdge
        for (let i = 0, len = this.folds.length; i < len; i += 1) {
            this.folds[i].hideObject();
        }

        for (let i = 0, len = this.testEdges.length; i < len; i += 1) {
            this.testEdges[i].hideObject();
        }
        this.isSelected = false;
    }

    moveObject(deltaX, deltaY, deltaZ = 0, check = false) {
        // if(!check) return;
        // update base height
        this.skeletonGroup.clear();
        this.baseHeight += deltaZ;
        if (deltaX !== 0 || deltaY !== 0) {
            this.isMoved = true;
        }
        // update all meshes and edges
        this.coreMesh.geometry.translate(deltaX, deltaY, deltaZ);
        this.coreEdges.children.forEach((edge) => {
            edge.geometry.translate(deltaX, deltaY, deltaZ);
        });

        if (this.rotationPoints) {
            this.rotationPoints.moveObjectWithoutConsequences(
                deltaX,
                deltaY,
                deltaZ
            );
        }

        // update outline points without consequences
        for (let i = 0, l = this.faceVertices.length; i < l; i += 1) {
            this.faceVertices[i].moveObjectWithoutConsequences(
                deltaX,
                deltaY,
                deltaZ
            );
        }
        for (let i = 0, l = this.oldVertices.length; i < l; i += 1) {
            this.oldVertices[i].add(new THREE.Vector3(deltaX, deltaY, deltaZ));
        }

        for (let i = 0, l = this.folds.length; i < l; i += 1) {
            // this.folds[i].moveObjectWithoutConsequences(deltaX, deltaY, deltaZ);
            this.folds[i].moveObjectWithoutConsequences(deltaX, deltaY, deltaZ);
        }

        for (let i = 0, l = this.testEdges.length; i < l; i += 1) {
            this.testEdges[i].moveObjectWithoutConsequences(
                deltaX,
                deltaY,
                deltaZ
            );
        }

        this.attachedObjects.moveObject(deltaX, deltaY, deltaZ);

        // update dimensions
        this.moveDimensions(deltaX, deltaY, deltaZ);

        // update children
        const children = this.getChildren();
        for (let i = 0, l = children.length; i < l; i += 1) {
            children[i].moveObject(deltaX, deltaY, deltaZ);
        }
        this.updateCompleteLoop();
        this.updatePermimeterPoints();
        this.parent.updatePointsMap();

        this.saveState();
    }

    async placeObject(deltaX = 0, deltaY = 0, flag = false) {
        this.moveObject(deltaX, deltaY, -this.baseHeight);

        const placingInformation = this.getPlacingInformation();
        if (placingInformation.errors.length !== 0) {
            const error = placingInformation.errors[0];
            if (error.message === COMPLEX_GEOMETRY_ERROR) {
                this.stage.eventManager.setComplexPolygonModelRemoved();
            } else if (error.message === OUT_OF_GROUND_ERROR) {
                this.stage.eventManager.setPolygonModelOutOfGroundRemoved();
            } else if (error.message === VERTEX_EQUIVALENT_ERROR) {
                this.stage.eventManager.modelVertexEquivalentError();
            } else if (error.message === INVALID_CORE_HEIGHT_ERROR) {
                this.stage.eventManager.invalidCoreHeightErrorForPolygon();
            } else if (error.message === INVALID_TILT_ERROR) {
                this.stage.eventManager.invalidTiltErrorForPolygon();
            } else if (error.message === INSUFFICIENT_VERTICES) {
                this.stage.eventManager.setComplexPolygonModelRemoved();
            }
            this.removeObject();
            return Promise.reject(error);
        }
        if (this.isTemplate) {
            if (this.parent !== placingInformation.parent) {
                this.changeParent(placingInformation.parent);
                this.adjustTiltToParent();
            }
            this.snapTemplateToParent();
            this.setRestrictions();
        }

        this.updateGeometry();

        const deltaZ = 0;
        for (let i = 0, len = this.children.length; i < len; i += 1) {
            if (!this.getChildren()[i].isValid) {
                continue;
            }
            this.getChildren()[i].disposeRafters();
            this.getChildren()[i].updateOutlinePoints(
                this.getChildren()[i].vertices
            );

            this.getChildren()[i].updatePlane();
            this.getChildren()[i].updateSetback();
            this.getChildren()[i].moveChildren(0, 0, deltaZ);
            this.getChildren()[i].placeChildrenSmartRoofs();
        }
        this.getAllSmartroofIntersections();
        this.faceVertices.forEach((vertex) => {
            if (
                !this.parent.pointsMap.has(
                    `${vertex.vertex.x.toFixed(4)},${vertex.vertex.y.toFixed(
                        4
                    )}`
                )
            ) {
                this.parent.pointsMap.set(
                    `${vertex.vertex.x.toFixed(4)},${vertex.vertex.y.toFixed(
                        4
                    )}`,
                    vertex
                );
            }
        });
        if (!this.is3d) {
            this.getChildren().forEach((face) => {
                face.updateRafter();
            });
        }
        // if (!this.rotationPoints) {
        //     this.createRotation();
        // }

        // update dimensions
        const keys = Object.keys(this.dimensionObjects);
        for (let i = 0; i < keys.length; i += 1) {
            this.dimensionObjects[keys[i]].handleAssociatedObjectPlace(this);
        }

        try {
            await this.handleSiblingConsequences();
            await this.handleAttachmentConsequences();
            // automation of setbacks
            // this.updateSetbacks();
            this.updateRoofVertexLoop();
            this.resetGrandParentSolarAccess();
            this.updateCompleteLoop();
            this.updatePermimeterPoints();
            this.stage.heatMap.removeHeatMapOnModelPlace();
            this.stage.smartRoofSetbackEditMode.updateModelArea();
            // this.handleEastWestrack();
        } catch (error) {
            console.error("ERROR: Smartroof Model: placeObject failed", error);
            return Promise.reject(error);
        }

        if (!this.isSelected) {
            this.deSelect();
        }
        if (this.stage.mode === "salesMode") {
            this.primaryEdge.smartRoofFace.showSetback();
        }
        this.saveState();
        this.stage.stateManager.stopContainer();
        if (this.isTemplate) this.maxTilt = this.getMaxTilt();
        //if is template then select ground on place object
        if (this.isSelected && this.isTemplate) {
            this.updateDimensions();
            if (!this.is3d) {
                this.stage.selectionControls.setSelectedObject(this);
            }
        }

        return Promise.resolve(true);
    }

    //EASTWEST-RESTRICTION
    // handleEastWestrack() {
    //     this.stage.ground.getChildren().forEach((child) => {
    //         if (child instanceof SmartroofModel) {
    //             child.getChildren().forEach((gChild) => {
    //                 gChild.getChildren().forEach((ggChild) => {
    //                     if(ggChild instanceof EastWestRack && ggChild.getParent().tilt>0){
    //                         ggChild.removeObject();
    //                         this.stage.eventManager.eastWestRackingNotPlacebleError();
    //                         // throw new Error('East West Racking cannot be placed over Tilted Surfaces');
    //                     }
    //                 });
    //             });
    //         }
    //     });
    // }

    async handleSiblingConsequences() {
        this.updateIntersectingAcCables(this.getIntersectingAcCables());
        const allPromises = [];

        const siblings = [];
        this.stage.ground.getChildren().forEach((child) => {
            if (child instanceof SmartroofModel) {
                child.getChildren().forEach((gChild) => {
                    gChild.getChildren().forEach((ggChild) => {
                        siblings.push(ggChild);
                    });
                });
            }
        });
        this.stage.ground.getChildren().forEach((child) => {
            child.getChildren().forEach((gChild) => {
                siblings.push(gChild);
            });
        });
        this.stage.ground.getChildren().forEach((child) => {
            siblings.push(child);
        });
        for (let i = siblings.length - 1; i >= 0; i -= 1) {
            const sibling = siblings[i];
            if (sibling instanceof Subarray && !this.ignored) {
                // TO:DO - consider outside setback?
                const group = this.get2DVertices();
                if (sibling.getParent() instanceof SmartroofFace) {
                    let faceArea = JSTSConverter.verticesToJSTSPolygon(sibling.getParent().get2DVertices());
                    const placableAreas = [];
                    const removableAreas = [];
                    sibling.getParent().setbackVertices.forEach((loop) => {
                        const loopArray = loop.map(v => [v.x, v.y]);
                        if (utils.checkClockwise(loopArray)) removableAreas.push(JSTSConverter.verticesToJSTSPolygon(loopArray));
                        else placableAreas.push(JSTSConverter.verticesToJSTSPolygon(loopArray));
                    });
                    removableAreas.forEach((geom) => {
                        for (let j = 0, n = geom.getNumGeometries(); j < n; j++) {
                            const vertices = geom.getGeometryN(j).getCoordinates().map(c => [c.x, c.y]);
                            sibling.deleteTableInsideArea(vertices);
                        }
                    });
                    placableAreas.forEach((geom) => {
                        for (let j = 0, n = geom.getNumGeometries(); j < n; j++) {
                            const area = geom.getGeometryN(j);
                            if (faceArea.intersects(area)) {
                                faceArea = faceArea.difference(area);
                            }
                        }
                    });

                    for (let j = 0, n = faceArea.getNumGeometries(); j < n; j++) {
                        if (sibling.getParent() === null) break;
                        const vertices = faceArea.getGeometryN(j).getCoordinates().map(c => [c.x, c.y]);
                        sibling.deleteTableInsideArea(vertices);
                    }
                }
                else {
                    if (!(sibling.objectType && sibling.objectType === POWER_PATIO)) sibling.deleteTableInsideArea(group);
                }
            }
            else if (sibling instanceof Walkway) {
                const placingPolygon = JSTSConverter.verticesToJSTSPolygon(this.get2DVertices());
                const siblingPolygon = JSTSConverter.verticesToJSTSPolygon(sibling.get2DVertices());
                const intersectingPolygon = placingPolygon.intersection(siblingPolygon);
                if (intersectingPolygon.getArea() > 0) {
                    allPromises.push(sibling.placeObject(0,0,false));
                }
            }
        }

        try {
            await Promise.all(allPromises);
        }
        catch (error) {
            console.error('ERROR: Smartroof Model: handleSiblingConsequences failed', error);
        }
        return Promise.resolve(true);
    }
    
    get2DVertices() {
        return this.perimeterPoints.map((point) => [point.x, point.y]);
    }
    getEdges() {
        const edges = [];
        this.roofFaces.forEach((face) => {
            edges.push(...face.getEdges());
        });
        return edges;
    }

    getPatioVertices() {
        if (this.boundaryPointsAutomatedRoof) {
            return this.boundaryPointsAutomatedRoof;
        }
        let tempCompleteLoopVertices = this.completeLoopFaceVertices.map(
            (vertex) => vertex.getPosition()
        );
        if (tempCompleteLoopVertices.length > 0) {
            this.removeCollinearVertices(tempCompleteLoopVertices);
            tempCompleteLoopVertices = tempCompleteLoopVertices.map(
                (vertex) => [vertex.x, vertex.y]
            );
            tempCompleteLoopVertices = utils.checkClockwise(
                tempCompleteLoopVertices
            )
                ? tempCompleteLoopVertices.reverse()
                : tempCompleteLoopVertices;
        }
        return tempCompleteLoopVertices;
    }

    removeCollinearVertices(vertices, tolerance = 0.5) {
        //change tolerance later
        if (!vertices || vertices.length < 3) {
            return;
        }
        // Remove collinear points
        let collinearFlag = true;
        while (collinearFlag) {
            collinearFlag = false;
            for (let i = 0; i < vertices.length; i += 1) {
                const vertex = vertices[i];
                const vertexNext = vertices[(i + 1) % vertices.length];
                const vertexPrev =
                    vertices[(i - 1 + vertices.length) % vertices.length];
                if (
                    utils.checkCollinear(
                        vertex,
                        vertexNext,
                        vertexPrev,
                        tolerance
                    )
                ) {
                    vertices.splice(i, 1);
                    i -= 1;
                }
            }
        }
    }

    getFaceVertices() {
        return this.faceVertices;
    }

    updateSubarray() {
        this.children.forEach((child) => {
            child.updateSubarray();
        });
    }

    getPlacingInformation(drawingVertices) {
        const response = {};
        let numberOfPoints;

        // Getting vertices
        let vertices2DArray;
        if (drawingVertices === null || drawingVertices === undefined) {
            vertices2DArray = this.get2DVertices();
            numberOfPoints = vertices2DArray.length;
        } else {
            vertices2DArray = drawingVertices;
            numberOfPoints = vertices2DArray.length - 1;
        }
        let polygonExists = true;
        response.errors = [];
        // This is the error that is displayed to the user
        response.pointUnplaceableError = null;

        // temp fix
        const childrenVertices2DArray = [
            ...this.children
                .flatMap(child => child.get2DVertices())
        ];
        
        const vertices2DVectorArray = utils.convertArrayToVector([...vertices2DArray, ...childrenVertices2DArray]);

        if (
            !raycastingUtils.areVerticesOnGround(
                vertices2DVectorArray,
                this.stage
            )
        ) {
            const error = new Error(OUT_OF_GROUND_ERROR);
            response.errors.push(error);
            response.pointUnplaceableError = error;
        }
        return response;
    }

    createEdgeObjects() {
        // iterating through every coreEdge and creating a Edge object
        const precision = 10000;
        const checkSet = new Set();

        for (let i = 0; i < this.coreEdgeCoordinates.length; i += 2) {
            const currentVertex = this.coreEdgeCoordinates[i];
            const nextVertex = this.coreEdgeCoordinates[i + 1];
            const currentVertexHash = `${Math.round(
                currentVertex.x * precision
            )},${Math.round(currentVertex.y * precision)}`;
            const nextVertexHash = `${Math.round(
                nextVertex.x * precision
            )},${Math.round(nextVertex.y * precision)}`;

            const edgeHash = `${currentVertexHash}_${nextVertexHash}`;
            const reverseEdgeHash = `${nextVertexHash}_${currentVertexHash}`;
            if (!checkSet.has(edgeHash)) {
                const edgeObject = new Edge(
                    this,
                    this.stage,
                    this.coreEdgeCoordinates[i],
                    this.coreEdgeCoordinates[i + 1],
                    true
                );
                edgeObject.editable = false;
                this.testEdges.push(edgeObject);
                checkSet.add(edgeHash);
                checkSet.add(reverseEdgeHash);
            }
        }
    }

    updateAzimuth(azimuth) {
        for (let i = 0; i < this.roofFaces.length; i++) {
            this.roofFaces[i].updateAzimuth(azimuth[i]);
        }

        this.updateGeometry();
        this.getAllSmartroofIntersections();
        this.placeObject();
    }

    updateTiltFromLidar(relativePitch) {
        const options = {
            heightChanged: false,
            prevHeight: null,
            parapetHeightChanged: false,
            prevParapetHeight: null,
            parapetThicknessChanged: false,
        };
        const children = this.getChildren();
    
        // Check for outliers and adjust them
        const threshold = 40;
        const avgPitch = relativePitch.reduce((sum, pitch) => sum + pitch, 0) / relativePitch.length;
        
        relativePitch = relativePitch.map(pitch => {
            if ((Math.abs(pitch - avgPitch) > threshold) || pitch < 0) {
                return avgPitch;
            }
            return pitch;
        });
        // relative tilt changes of every child is calculated
        children.forEach((child, index) => {
            if (!child.isDeleted) {
                child.tilt += relativePitch[index] / 100;
            }
        });
        // Updation
        this.updateFacesWithNewAngles(false);
        children.forEach((child) => {
            child.handlePropertiesUpdate(options);
            child.snapChildDormers();
        });
        this.tilt = "custom";
        // update all obstructions
        this.updateObstructions();
    }
    updateHeight(arg, place = true) {
        const deltaZ = arg - this.coreHeight;
        if (deltaZ === 0 && !place) {
            return;
        }
        this.roofTextureGeometry.translate(0, 0, deltaZ);
        this.coreHeight = arg;

        const children = this.getChildren();
        for (let i = 0, len = children.length; i < len; i += 1) {
            children[i].resetEditedVertices();
            children[i].updateHeight(deltaZ)
        }

        // this.faceVertices.forEach((vertex) => {
        //     vertex.vertex.z += deltaZ;
        // });
        // this.attachedObjects.attachments.forEach(attachment => {
        //     attachment.moveAttached = true;
        // })
        // this.attachedObjects.hideAttachments();
        // for (let i = 0; i < this.faceVertices.length; i++) {
        //     // increase the value of each value in zValueMap in faceVertex by deltaZ
        //     this.faceVertices[i].zValueMap.forEach((value, key) => {
        //         this.faceVertices[i].zValueMap.set(key, value + deltaZ);
        //     });
        // }
        try {
            this.updateGeometry();
        } catch (error) {
            console.error("update height error", error);
        }
        if (place) {
            for (let i = 0, len = this.children.length; i < len; i += 1) {
                const child = this.getChildren()[i];
                child.updateSetback();
                if (!child.isValid || child.isDeleted) {
                    continue;
                }
                child.placeChildrenSmartRoofs();
            }
            this.resetGrandParentSolarAccess();
            this.stage.lightsManager.setShadowMapParameters();
            this.getAllSmartroofIntersections();
        } else {
            this.getAllSmartroofIntersections(false, [], false);
        }
        this.stage.mergeManager.mergeScene(this, false);
        // update roofbaseHeight of sales mode drawface
        //while updating the core height of studio drawface.
        this.roofBaseHeight = this.coreHeight;
    }

    hideAttachments() {
        this.attachedObjects?.hideAttachments();
    }

    changeTilt(newTilt, place = false) {
        this.tilt = newTilt;
        const children = this.getChildren();
        const roofObstacles = [];
        for (let i = 0; i < children.length; i++) {
            const grandChildren = [...children[i].getChildren()];
            for (let j = 0; j < grandChildren.length; j++) {
                if (grandChildren[j] instanceof Subarray) {
                    grandChildren[j].removeObject();
                    continue;
                }
                const child = children[i].removeChild(grandChildren[j]);
                roofObstacles.push(child);
            }
        }
        for (let i = 0; i < children.length; i++) {
            if (children[i].fold) {
                children[i].fold.tilt = children[i].tilt;
                continue;
            } else if (children[i].tilt && !children[i].isDeleted) {
                children[i].tilt = this.getValidTilt(this.tilt);
                // children[i].outerEdge.tilt = this.getValidTilt(this.tilt);
                // children[i].outerEdge.updateOuterEdge();
            }
        }
        this.attachedObjects.attachments.forEach((attachment) => {
            attachment.moveAttached = true;
        });
        this.attachedObjects.hideAttachments();

        this.children.forEach((face) => {
            face.resetEditedVertices();
            face.updateGeometry();
        });

        for (let i = 0; i < roofObstacles.length; i++) {
            roofObstacles[i].placeObject();
        }

        this.updateFacesWithNewAngles(place);
    }
    hideSelectables() {
        // hide outline points
        for (let i = 0, len = this.faceVertices.length; i < len; i += 1) {
            this.faceVertices[i].hideObject();
        }
        for (let i = 0, len = this.folds.length; i < len; i += 1) {
            this.folds[i].hideObject();
        }

        for (let i = 0, len = this.testEdges.length; i < len; i += 1) {
            this.testEdges[i].hideObject();
        }
    }

    showSelectables() {
        // show outline points
        for (let i = 0, len = this.faceVertices.length; i < len; i += 1) {
            this.faceVertices[i].showObject();
        }

        for (let i = 0, len = this.folds.length; i < len; i += 1) {
            this.folds[i].showObject();
        }

        for (let i = 0, len = this.testEdges.length; i < len; i += 1) {
            this.testEdges[i].showObject();
        }
    }

    async updateFacesWithNewAngles(place = true) {
        const children = this.getChildren();
        const validChildren = children.filter(
            (child) => !child.isDeleted && !child.fold
        );
        const distinctTilts = Array.from(
            new Set(validChildren.map((child) => child.tilt))
        );

        if (distinctTilts.length === 1) {
            this.tilt = distinctTilts[0];
        } else if (distinctTilts.length > 1) {
            this.tilt = "custom";
        }

        for (let i = 0; i < children.length; i++) {
            children[i].tiltChange(children[i].tilt);
        }

        if (place) {
            try {
                for (let i = 0; i < children.length; i++) {
                    children[i].updatePanels();
                    children[i].placeChildren();
                }
            } catch (e) {
                console.error(new Error(), e);
            }
            finally {
                await this.placeObject();
            }
        } else {
            try {
                this.updateGeometry();
            } catch (e) {
                console.error("Failed to update faces with new angles", e);
            }

            this.getAllSmartroofIntersections(false, [], false);
        }
    }

    updateHeightFromLidar(heightMap) {
        for (const [id, height] of heightMap) {
            const child = this.roofFaces.find((face) => face.id === id);
            if (child) {
                const newHeight = child.coreHeight - height;
                if (newHeight >= 1 && newHeight <= 60) {
                    child.updateHeight(-height);
                } else {
                    console.warn(`Height adjustment for face ${id} ignored. New height (${newHeight}) would be out of range [1, 60].`);
                }
            }
        }
        this.updateGeometry();
        this.getAllSmartroofIntersections();
    }

    async updateObject(
        properties,
        setbackFlag = false,
        forcePlaceObject = false
    ) {
        this.setbackFlag = setbackFlag;
        let updateGeometryRequired = false;
        const handleChildrenRequired = false;
        let handleAttachmentRequired = false;
        const options = {
            heightChanged: false,
            prevHeight: null,
            tiltChanged: false,
        };
        if (
            Object.prototype.hasOwnProperty.call(properties, "name") &&
            properties.name !== this.salesModeProperties.name
        ) {
            this.salesModeProperties.name = properties.name;
            this.salesModeProperties.updated = true;
        }
        if (
            Object.prototype.hasOwnProperty.call(properties, "baseHeight") &&
            properties.baseHeight !== this.baseHeight
        ) {
            this.moveObject(0, 0, properties.baseHeight - this.baseHeight);
            this.stage.lightsManager.setShadowMapParameters();
            this.resetGrandParentSolarAccess();
        }
        if (
            Object.prototype.hasOwnProperty.call(properties, "coreHeight") &&
            properties.coreHeight !== this.coreHeight.toString() &&
            String(properties.coreHeight) !== "NaN"
        ) {
            updateGeometryRequired = updateGeometryRequired || true;
            handleAttachmentRequired = handleAttachmentRequired || true;
            options.heightChanged = true;
            options.prevHeight = this.coreHeight;
            this.coreHeight = properties.coreHeight;
            if (this.coreHeight < PATIO_HEIGHT) {
                this.attachedObjects.removeAttachmentFromScene();
            }
        }
        if (parseFloat(properties.tilt) >= 0) {
            properties.tilt = parseFloat(properties.tilt);
        }
        if (
            Object.prototype.hasOwnProperty.call(properties, "tilt") &&
            properties.tilt != this.tilt
        ) {
            handleAttachmentRequired = handleAttachmentRequired || true;
            // handleChildrenRequired = true;
            if (utils.isNumber(properties.tilt) && properties.tilt > 60) {
                properties.tilt = 60;
            } else if (utils.isNumber(properties.tilt) && properties.tilt < 0) {
                properties.tilt = 0;
                options.tiltChanged = true;
                this.tilt = 0;
                updateGeometryRequired = updateGeometryRequired || true;
            } else {
                updateGeometryRequired = updateGeometryRequired || true;
                // handleChildrenRequired = true;
                options.tiltChanged = true;
                this.tilt = this.dispalyTiltChanged
                    ? 180 - properties.tilt
                    : properties.tilt;
            }
        }
        if (
            Object.prototype.hasOwnProperty.call(
                properties,
                "lockedParameter"
            ) &&
            properties.lockedParameter !== this.lockedParameter
        ) {
            this.lockedParameter = properties.lockedParameter;
        }
        if (
            Object.prototype.hasOwnProperty.call(properties, "ignored") &&
            properties.ignored !== this.ignored
        ) {
            if (properties.ignored) {
                const childSubarrays = this.getChildSubarrays();
                for (let i = 0; i < childSubarrays.length; i += 1) {
                    childSubarrays[i].removeObject();
                }
            } else {
                const siblings = this.getParent().getChildren();
                for (let i = 0; i < siblings.length; i += 1) {
                    if (siblings[i] instanceof Subarray) {
                        siblings[i].deleteTableInsideArea(this.get2DVertices());
                    }
                }
            }
            updateGeometryRequired = updateGeometryRequired || false;
            this.ignored = properties.ignored;
        }
        if (
            Object.prototype.hasOwnProperty.call(properties, "snapHeight") &&
            properties.snapHeight !== this.snapHeight
        ) {
            if (properties.snapHeight) {
                updateGeometryRequired = updateGeometryRequired || true;
                options.heightChanged = true;
                options.prevHeight = this.coreHeight;
            }
            this.snapHeight = properties.snapHeight;
        }

        if (this.setbackFlag)
            this.getAllSmartroofIntersections(false, [], this.setbackFlag);

        if (updateGeometryRequired) {
            try {
                this.handlePropertiesUpdate(options);
            } catch (error) {
                console.error("ERROR: SmartroofModel: Update failed", error);
                return Promise.reject(error);
            }
        } else if (forcePlaceObject) {
            this.placeObject(0, 0, true);
        }
        if (handleChildrenRequired) {
            this.stage.lightsManager.setShadowMapParameters();
        }

        const children = this.getChildren();
        if (children.length > 0) {
            for (let i = 0; i < children.length; i++) {
                const grandChildren = children[i].getChildren();
                for (let j = 0; j < grandChildren.length; j++) {
                    if (grandChildren[j] instanceof Dormer) {
                        grandChildren[j].placeObject();
                        grandChildren[j].heightChange =
                            this.coreHeight - options.prevHeight;
                    }
                }
            }
        }
        this.saveState();
        this.stage.mergeManager.mergeScene(this, false);
        // handle attachments on property update
        if (this.attachedObjects.hidden) {
            if (!handleAttachmentRequired) {
                this.attachedObjects.removeAttachmentFromScene();
            }
            else {
                this.attachedObjects.showAttachments();
            }
        }
        return Promise.resolve(true);
    }

    handlePropertiesUpdate(options) {
        if (options.tiltChanged) {
            const children = this.getChildren();
            const roofObstacles = [];
            for (let i = 0; i < children.length; i++) {
                const grandChildren = [...children[i].getChildren()];
                for (let j = 0; j < grandChildren.length; j++) {
                    if (grandChildren[j] instanceof Subarray) {
                        grandChildren[j].removeObject();
                        continue;
                    }
                    const child = grandChildren[j];
                    if (!(child instanceof SmartroofModel)) {
                        children[i].removeChild(child);
                    }
                    roofObstacles.push(child);
                }
            }

            for (let i = 0; i < children.length; i++) {
                children[i].tiltChange(this.tilt);
            }
            try {
                this.updateGeometry();
                this.getAllSmartroofIntersections();
            } catch (error) {
                console.error("properties  tilt update error", error);
            }

            this.children.forEach((face) => {
                face.updateSetback();
                face.updateGeometry();
            });

            for (let i = 0; i < roofObstacles.length; i++) {
                roofObstacles[i].placeObject();
            }
            this.getAllSmartroofIntersections(false, [], true);
        }
        if (options.heightChanged) {
            this.stage.stateManager.startContainer();
            const deltaZ = this.coreHeight - options.prevHeight;
            this.roofTextureGeometry.translate(0, 0, deltaZ);
            const children = this.getChildren();
            for (let i = 0, len = children.length; i < len; i += 1) {
                children[i].updateHeight(deltaZ);
            }
            try {
                this.updateGeometry();
            } catch (error) {
                console.error("properties  height update error", error);
            }

            this.resetGrandParentSolarAccess();
            this.stage.lightsManager.setShadowMapParameters();
            this.placeObject(0, 0, true);
        }
    }
    isPointOnModel(point) {
        const edges = this.getEdges();
        for (let i = 0; i < this.faceVertices.length; i++) {
            if (this.isPointOnPoint(point, this.faceVertices[i].vertex))
                return true;
        }
        for (let i = 0; i < edges.length; i += 1) {
            if (this.isPointOnLine(point, edges[i][0], edges[i][1])) {
                return true;
            }
        }
        return false;
    }
    isPointOnPoint(point1, point2, tolerance = 0.01) {
        return (
            Math.abs(point1.x - point2.x) <= tolerance &&
            Math.abs(point1.y - point2.y) <= tolerance
        );
    }
    isPointOnLine(point, linePoint1, linePoint2, buffer = 2) {
        const x = point.x;
        const y = point.y;

        // Check if the points are not coincident
        if (linePoint1.x === linePoint2.x && linePoint1.y === linePoint2.y) {
            return false;
        }

        // Calculate the slope (m)
        const slope =
            (linePoint2.y - linePoint1.y) / (linePoint2.x - linePoint1.x);

        // Calculate the y-intercept (b) using one of the points
        const yIntercept = linePoint1.y - slope * linePoint1.x;

        // Calculate the distance from the point to the line
        const distance =
            Math.abs(slope * x - y + yIntercept) / Math.sqrt(slope * slope + 1);

        // Check if the given point lies on the line with a buffer
        const isOnLine = distance <= buffer;

        // Check if the point is between linePoint1 and linePoint2
        const isBetween =
            x >= Math.min(linePoint1.x, linePoint2.x) &&
            x <= Math.max(linePoint1.x, linePoint2.x) &&
            y >= Math.min(linePoint1.y, linePoint2.y) &&
            y <= Math.max(linePoint1.y, linePoint2.y);

        return isOnLine && isBetween;
    }

    splitEdge() {
        if (this.parent.firstSplitEdge.length !== 0) {
            const roofFace = this.getRoofaceFromEdge(
                this.parent.firstSplitEdge
            );

            //insert point in vertices
            let index = 0;
            for (let i = 0; i < roofFace.roofFaceVertices.length; i++) {
                if (
                    roofFace.roofFaceVertices[i].vertex.x ===
                        this.parent.firstSplitEdge[0].x &&
                    roofFace.roofFaceVertices[i].vertex.y ===
                        this.parent.firstSplitEdge[0].y
                ) {
                    index = i;
                    break;
                }
            }

            const roofVertex = this.parent.getRoofVertexFromPointsMap(
                this.parent.firstPoint
            );
            //check if roofVertex is already present in vertices
            let isPresent = false;
            for (let i = 0; i < roofFace.roofFaceVertices.length; i++) {
                if (
                    roofFace.roofFaceVertices[i].vertex.x ===
                        roofVertex.vertex.x &&
                    roofFace.roofFaceVertices[i].vertex.y ===
                        roofVertex.vertex.y
                ) {
                    isPresent = true;
                    break;
                }
            }

            if (!isPresent) {
                roofFace.roofFaceVertices.splice(index, 0, roofVertex);
                roofFace.updateVertices();
                roofFace.createEdges();
            }
            this.parent.firstSplitEdge = [];
            this.parent.firstPoint = null;
        }

        if (this.parent.lastSplitEdge.length !== 0) {
            const roofFace = this.getRoofaceFromEdge(this.parent.lastSplitEdge);

            //insert point in vertices
            let index = 0;
            for (let i = 0; i < roofFace.roofFaceVertices.length; i++) {
                if (
                    roofFace.roofFaceVertices[i].vertex.x ===
                        this.parent.lastSplitEdge[0].x &&
                    roofFace.roofFaceVertices[i].vertex.y ===
                        this.parent.lastSplitEdge[0].y
                ) {
                    index = i;
                    break;
                }
            }

            const roofVertex = this.parent.getRoofVertexFromPointsMap(
                this.parent.lastPoint
            );
            roofFace.roofFaceVertices.splice(index, 0, roofVertex);
            roofFace.updateVertices();
            roofFace.createEdges();
            this.parent.lastSplitEdge = [];
            this.parent.lastPoint = null;
        }
    }

    getLargestRoof() {
        const largestAreaSmartRoof = this.getChildren().reduce((p, v) =>
            p.computeArea() > v.computeArea() ? p : v
        );

        return largestAreaSmartRoof;
    }

    getRoofaceFromEdge(edge) {
        for (let i = 0; i < this.roofFaces.length; i++) {
            const edgeHash = `${edge[0].x.toFixed(3)},${edge[0].y.toFixed(
                3
            )}_${edge[1].x.toFixed(3)},${edge[1].y.toFixed(3)}`;
            const reverseEdgeHash = `${edge[1].x.toFixed(
                3
            )},${edge[1].y.toFixed(3)}_${edge[0].x.toFixed(
                3
            )},${edge[0].y.toFixed(3)}`;
            for (let j = 0; j < this.roofFaces[i].edges.length; j++) {
                if (
                    this.roofFaces[i].edges[j].edgeHash === edgeHash ||
                    this.roofFaces[i].edges[j].edgeHash === reverseEdgeHash
                ) {
                    return this.roofFaces[i];
                }
            }
        }
    }

    removeObject() {
        // First deleting child subarray before other objects so that deleting walkways or other
        // objects don't refresh the subarray unnecessarily
        const childSubarrays = this.getChildSubarrays();
        for (let i = 0, len = childSubarrays.length; i < len; i += 1) {
            childSubarrays[i].removeObject();
        }

        const i = 0;
        while (this.getChildren().length > i) {
            this.getChildren()[this.getChildren().length - 1].removeObject(
                false
            );
        }

        this.stage.stateManager.add({
            uuid: this.uuid,
            getStateCb: () => DELETED_STATE,
        });

        // NOTE: deSelect should be after save since it will disable
        // drag controls and stop Undo/Redo container
        if (this.stage.selectionControls.getSelectedObject() === this) {
            this.stage.selectionControls.setSelectedObject(this.stage.ground);
        }

        this.stage.sceneManager.scene.remove(this.objectsGroup);

        if (this.getParent() !== null) {
            this.getParent().penToolRoofModels.forEach((model, index) => {
                if (model.uuid === this.uuid) {
                    this.getParent().penToolRoofModels.splice(index, 1);
                }
            });
            this.getParent().removeChild(this);
        }

        this.stage.ground.removeChild(this);

        // remove measurements
        // if (this.polygonMeasurement) this.polygonMeasurement.remove();

        for (let j = this.folds.length - 1; j >= 0; j -= 1) {
            this.folds[j].removeObject();
            this.folds.splice(j, 1);
        }

        this.faceVertices.forEach((vertex) => {
            if(vertex !== undefined) {
                if(this.stage.penTool.pointsMap.has(`${vertex.vertex.x.toFixed(4)},${vertex.vertex.y.toFixed(4)}`)) {
                    this.stage.penTool.pointsMap.delete(`${vertex.vertex.x.toFixed(4)},${vertex.vertex.y.toFixed(4)}`);
                }
            }
        });

        // remove attachments while removing the model.
        this.attachedObjects.removeAttachmentFromScene();
        this.measurementTextMesh = [];

        this.removeRoofTexture();
        if (this.rotationPoints) this.rotationPoints.removeObject();

        // from base object
        this.removeDimensions();
        this.getAllSmartroofIntersections();
        this.stage.smartRoofSetbackEditMode.updateModelArea();

        // remove object from hover manager
        this.stage.quadTreeManager.removeObject(this);

        this.stage.selectionControls.setSelectedObject(this.stage.ground);
    }
    hideTextObjects(){

        for (let i = 0, len = this.testEdges.length; i < len; i += 1) {
            this.testEdges[i].measurementText.visible = false;
            this.testEdges[i].measurementText.hideObject();
        }
    }   

    showTextObjects(){

        for (let i = 0, len = this.testEdges.length; i < len; i += 1) {
            this.testEdges[i].measurementText.visible = true;
            this.testEdges[i].measurementText.showObject();
        }
    }

    saveObject(isCopy = false) {
        const polygonModelData = {
            type: PenToolRoofModel.getObjectType(),
            children: [],
        };

        // save id and name
        polygonModelData.id = this.id;
        polygonModelData.name = this.name;
        if (isCopy) {
            polygonModelData.uuid = this.uuid;
        }

        // save polygon properties
        polygonModelData.baseHeight = this.baseHeight;
        polygonModelData.coreHeight = this.coreHeight;
        polygonModelData.tilt = this.tilt;
        polygonModelData.ignored = this.ignored;
        polygonModelData.snapHeight = this.snapHeight;
        polygonModelData.isTemplate = this.isTemplate;
        polygonModelData.azimuth = this.azimuth;
        polygonModelData.dormerType = this.type;
        polygonModelData.isAutomated = this.isAutomated;

        // saving outline points
        const outlinePoints = [];
        for (let i = 0, len = this.outlinePoints.length; i < len; i += 1) {
            const position = this.outlinePoints[i].getPosition();
            if (position) {
                outlinePoints.push([position.x, position.y, position.z]);
            }
        }
        polygonModelData.outlinePoints = outlinePoints;

        //saving complete loop
        const completeLoop = [];
        this.completeLoop.forEach((point) => {
            completeLoop.push([point.x, point.y, point.z]);
        });

        polygonModelData.completeLoop = completeLoop;

        const folds = [];
        if (this.folds) {
            for (let i = 0, len = this.folds.length; i < len; i += 1) {
                const position = this.folds[i].getPosition();
                if (position) {
                    folds.push({
                        x: position.x,
                        y: position.y,
                        z: position.z,
                        foldType: this.folds[i].foldType,
                        faceId: this.folds[i].faceId,
                        tilt: this.folds[i].tilt,
                    });
                }
            }
            polygonModelData.folds = folds;
        }
        polygonModelData.attachedObjects = this.attachedObjects.saveObject();

        // saving children
        const children = this.getChildren();
        for (let i = 0, len = children.length; i < len; i += 1) {
            polygonModelData.children.push(children[i].saveObject());
        }

        return polygonModelData;
    }

    loadAttachments() {
        if (this.attachedData) {
            this.attachedObjects = new AttachObject(this.stage, this);
            this.attachedObjects.loadObject(this.attachedData);
        }
    }

    loadObject(polygonModelData, isPaste = false) {
        this.parent = this.stage.penTool;
        this.tilt = polygonModelData.tilt;
        this.isAutomated = polygonModelData.isAutomated;

        if (!isPaste) {
            this.id = polygonModelData.id;
            this.name = polygonModelData.name;
        }

        const { children } = polygonModelData;
        for (let i = 0, len = children.length; i < len; i += 1) {
            this.makePentoolRoofFace(children[i], isPaste);
        }
        if (this.parent.penToolRoofModel.isDummy) {
            // temp fix for dummy model, fix the flow to add parent
            if (
                this.parent.penToolRoofModel.parent &&
                this.parent.penToolRoofModel.parent ===
                    this.parent.penToolRoofModel.stage.ground
            ) {
                this.stage.ground.removeChild(this.parent.penToolRoofModel);
                this.parent.children.pop();
            }
        }
        if (isPaste) {
            polygonModelData.completeLoop.forEach((point) => {
                const vertex = this.parent.pointsMap.get(
                    `${point[0].toFixed(4)},${point[1].toFixed(4)}`
                );
                if(vertex !== undefined) {
                    this.completeLoopFaceVertices.push(vertex);
                }
            });
        } else {
            polygonModelData.completeLoop.forEach((point) => {
                const vertex = this.parent.pointsMap.get(
                    `${point[0].toFixed(4)},${point[1].toFixed(4)}`
                );
                if(vertex !== undefined) {
                    this.completeLoopFaceVertices.push(vertex);
                }
            });
        }
        if (polygonModelData.attachedObjects)
            this.attachedData = polygonModelData.attachedObjects;
        this.updateRoofVertexLoop();
        this.updateGeometry();

        this.perimeterPoints = this.completeLoop;
        this.parent.completeLoop = this.completeLoop;
        this.parent.penToolRoofModel = this;
        // this.parent.updatepointsGraph();
        // this.updateGeometry();
        // console.log('bruh fr?');
        this.getAllSmartroofIntersections();
        // this.parent.children.push(this);
        this.parent.penToolRoofModels.push(this);

        if (isPaste) {
            this.saveState({ withoutContainer: false });
        } else {
            this.saveState({ withoutContainer: true });
        }
    }

    getZOnTopSurface(x, y) {
        // should return 0
        if (this.faceVertices.length === 0) {
            console.error("ERROR: Smartroof Model: has outline points null");
        }
        const v1 = this.faceVertices[0].getPosition();
        let v2 = this.faceVertices[1].getPosition();
        const v3 = this.faceVertices[2].getPosition();

        v1.addScaledVector(v2, -1);
        v3.addScaledVector(v2, -1);
        v1.cross(v3);
        v2 = this.faceVertices[1].getPosition();
        const d = -1 * (v1.x * v2.x + v1.y * v2.y + v1.z * v2.z);
        return -1 * (d / v1.z + (v1.y * y) / v1.z + (v1.x * x) / v1.z);
    }

    get3DVertices() {
        const vertices = [];
        for (let i = 0, len = this.completeLoop.length; i < len; i += 1) {
            const outlinePoint = this.completeLoop[i];
            vertices.push([outlinePoint.x, outlinePoint.y, outlinePoint.z]);
        }
        return vertices;
    }

    getCleaned3DVertices() {
        const vertices = this.get3DVertices().map((vertex) => {
            return new THREE.Vector3(vertex[0], vertex[1], vertex[2]);
        });

        this.removeCollinearVertices(vertices);

        return vertices.map(vertex => [vertex.x, vertex.y, vertex.z]);
        
    }

    removeCollinearVertices(vertices) {
        if (!vertices || vertices.length < 3) {
            return;
        }
        // Remove collinear points
        let collinearFlag = true;
        while(collinearFlag) {
            collinearFlag = false;
            for (let i = 0; i < vertices.length; i += 1) {
                const vertex = vertices[i];
                const vertexNext = vertices[(i + 1) % vertices.length];
                const vertexPrev = vertices[((i - 1) + vertices.length) % vertices.length];
                if (utils.checkCollinear(vertex, vertexNext, vertexPrev, 0.0001)) {
                    vertices.splice(i, 1);
                    i -= 1;
                }
            }
        }
    }

    makePentoolRoofFace(roof, isPaste) {
        const vertices = [];
        roof.roofFaceVertices.forEach((roofVertex) => {
            vertices.push(
                new THREE.Vector3(roofVertex[0], roofVertex[1], roofVertex[2])
            );
        });

        try {
            const roofVertices = [];
            // const setbackVertices = !checkClockwise(vertices.map(vertex => [vertex.x, vertex.y])) ? vertices : vertices.reverse();
            const roofFace = new PenToolRoofFace(
                this.stage,
                this,
                vertices,
                roofVertices,
                [],
                0,
                this.coreHeight,
                [[]],
                null,
                null,
                []
            );
            if (this.completeLoop.length === 0) this.completeLoop = vertices;
            if (isPaste) {
                vertices.forEach((vertex) => {

                    const vertexHash = `${vertex.x.toFixed(4)},${vertex.y.toFixed(4)}`;
                    if(this.faceVerticesMap.has(vertexHash)) {
                        const roofVertex = this.faceVerticesMap.get(vertexHash);
                        roofVertex.zValueMap.set(roofFace.uuid, vertex.z);
                        roofVertices.push(roofVertex);
                    }
                    else {
                        const roofVertex = new FaceVertex(
                            this.stage,
                            roofFace,
                            vertex,
                            [],
                            false,
                            this.parent
                        );
                        this.stage.penTool.pointsMap.set(`${vertex.x.toFixed(4)},${vertex.y.toFixed(4)}`, roofVertex);
                        roofVertex.zValueMap.set(roofFace.uuid, vertex.z);
                        roofVertices.push(roofVertex);
                        this.faceVertices.push(roofVertex);
                        this.faceVerticesMap.set(vertexHash, roofVertex);
                    }
                });
            } else {
                vertices.forEach((vertex) => {
                    //if the vertex not in pointsMap, add it to pointsMap
                    if (
                        !this.stage.penTool.pointsMap.has(
                            `${vertex.x.toFixed(4)},${vertex.y.toFixed(4)}`
                        )
                    ) {
                        const roofVertex = new FaceVertex(
                            this.stage,
                            roofFace,
                            vertex,
                            [],
                            false,
                            this.stage.penTool
                        );
                        this.stage.penTool.pointsMap.set(
                            `${vertex.x.toFixed(4)},${vertex.y.toFixed(4)}`,
                            roofVertex
                        );
                        roofVertex.zValueMap.set(roofFace.uuid, vertex.z);
                        roofVertices.push(roofVertex);
                        this.faceVertices.push(roofVertex);
                    } else {
                        const roofVertex = this.stage.penTool.pointsMap.get(
                            `${vertex.x.toFixed(4)},${vertex.y.toFixed(4)}`
                        );
                        roofVertex.zValueMap.set(roofFace.uuid, vertex.z);
                        roofVertices.push(roofVertex);
                    }
                });
            }

            roofFace.roofFaceVertices = roofVertices;
            roofFace.createEdges();
            this.roofFaces.push(roofFace);
            this.children.push(roofFace);
            roofFace.loadObject(roof, isPaste);
            this.updateGeometry();
            this.getAllSmartroofIntersections();
            this.parent.roofFaces.push(roofFace);

            return Promise.resolve(true);
        } catch (e) {
            console.log("e: ", e);
            return Promise.reject(e);
        }
    }

    static getObjectType() {
        return "PenToolRoofModel";
    }

    getState() {
        const polygonData = {
            id: this.id,
            uuid: this.uuid,
            name: this.name,
            tilt: this.tilt,
            baseHeight: this.baseHeight,
            coreHeight: this.coreHeight,
            lockedParameter: this.lockedParameter,
            ignored: this.ignored,
            snapHeight: this.snapHeight,
            isTemplate: this.isTemplate,
            childSequence: getChildrenSequence(this),
            outlinePoints: this.saveOutlinePoints(),

            oldVertices: this.oldVertices,
            folds: this.saveFolds(),
            isMoved: this.isMoved,
            rotationPoints: this.rotationPoints,
            parent: this.getParent() ? this.getParent().uuid : null,
            maxTilt: this.maxTilt,
            faceVertices: this.saveFaceVertices(),
            perimeterPoints: this.savePerimeterPoints(),
            isDummy: this.isDummy,
            completeLoop: this.saveCompleteLoop(),
        };
        return polygonData;
    }

    saveFaceVertices() {
        const faceVertices = [];
        this.faceVertices.forEach((vertex) => {
            faceVertices.push([
                vertex.vertex.x,
                vertex.vertex.y,
                vertex.vertex.z,
                new Map(vertex.zValueMap),
            ]);
        });
        return faceVertices;
    }

    savePerimeterPoints() {
        const perimeterPoints = [];
        this.perimeterPoints.forEach((point) => {
            perimeterPoints.push([point.x, point.y, point.z]);
        });
        return perimeterPoints;
    }

    saveCompleteLoop() {
        const completeLoop = [];
        this.completeLoop.forEach((point) => {
            completeLoop.push([point.x, point.y, point.z]);
        });
        return completeLoop;
    }

    loadState(state, fromState) {
        if (state === CREATED_STATE || state === DELETED_STATE) {
            this.clearState();
        } else {
            // load id and name
            this.id = state.id;
            this.name = state.name;

            // load polygon properties
            this.tilt = state.tilt;
            this.coreHeight = state.coreHeight;
            this.baseHeight = state.baseHeight;
            this.lockedParameter = state.lockedParameter;
            this.ignored = state.ignored;
            this.snapHeight = state.snapHeight;
            this.isTemplate = state.isTemplate;
            this.isMoved = state.isMoved;
            this.oldVertices = [];
            this.updateVisualsAfterLoadingAndCreation();
            if (this.isTemplate) {
                if (this.parent) {
                    this.maxTilt = this.getMaxTilt();
                } else {
                    this.maxTilt = state.maxTilt ? state.maxTilt : 60;
                }
            }
            // update parent
            const parentObject = this.stage.getObject(state.parent);
            if (parentObject && this.getParent() !== parentObject) {
                this.changeParent(parentObject);
            }

            if (fromState === CREATED_STATE || fromState === DELETED_STATE) {
                const parent = this.getParent();
                this.parent = null;
                this.stage.ground.addChild(this);
                this.parent = parent;

                // add objectsGroup to scene
                this.stage.sceneManager.scene.add(this.objectsGroup);

                this.faceVertices = state.faceVertices.map((vertex) => {
                    const faceVertex = new FaceVertex(
                        this.stage,
                        this,
                        new THREE.Vector3(vertex[0], vertex[1], vertex[2]),
                        [],
                        false,
                        this.parent
                    );
                    this.parent.pointsMap.set(
                        `${vertex[0].toFixed(4)},${vertex[1].toFixed(4)}`,
                        faceVertex
                    );
                    return faceVertex;
                });

                this.loadFolds(state.folds);
                this.updateOldVertices();
            }
            else {
                return null;
            }
            this.parent.updatePointsMap();

            //update completeloop

            this.completeLoop = state.completeLoop.map(
                (point) => new THREE.Vector3(point[0], point[1], point[2])
            );
            this.perimeterPoints = this.completeLoop;
            this.completeLoopFaceVertices = [];
            for (let i = 0; i < this.completeLoop.length; i++) {
                const faceVertex = this.parent.pointsMap.get(
                    `${this.completeLoop[i].x.toFixed(4)},${this.completeLoop[
                        i
                    ].y.toFixed(4)}`
                );
                if (faceVertex) {
                    this.completeLoopFaceVertices.push(faceVertex);
                }
            }
            // update geometry
            this.updateRoofVertexLoop();
            this.updateGeometry();
            this.stage.heatMap.removeHeatMapOnModelPlace();
            this.children.forEach((child) => {
                child.updatedEditedFace();
            });
            this.getAllSmartroofIntersections();
            this.clearTestEdges();
            // this.updatePolygonMeasurement();

            this.coreMesh.geometry.computeBoundingSphere();

            // setting RotationPoint
            // if (!this.rotationPoints) {
            //     this.createRotation();
            // }
            // else {
            //     this.rotationPoints.removeObject();
            //     this.createRotation();
            //     this.rotationPoints.updateRotationPoint();
            // }
        }
        return true;
    }

    clearState() {
        // select ground if selected
        if (this.stage.selectionControls.getSelectedObject() === this) {
            this.stage.selectionControls.setSelectedObject(this.stage.ground);
        }

        if (this.rotationPoints) this.rotationPoints.removeObject();

        //remove faceVertices
        for (let i = this.faceVertices.length - 1; i >= 0; i -= 1) {
            if (this.faceVertices[i].zValueMap.size === 0) {
                this.parent.pointsMap.delete(
                    `${this.faceVertices[i].vertex.x.toFixed(
                        4
                    )},${this.faceVertices[i].vertex.y.toFixed(4)}`
                );
                this.faceVertices[i].removeObject();
            }
            this.faceVertices.splice(i, 1);
        }
        this.stage.quadTreeManager.removeObject(this);

        this.stage.sceneManager.scene.remove(this.objectsGroup);
        if (this.getParent()) {
            this.getParent().removeChild(this);
        }
        this.stage.ground.removeChild(this);

        // Remove folds
        for (let i = this.folds.length - 1; i >= 0; i -= 1) {
            this.folds[i].removeObject();
            this.folds.splice(i, 1);
        }
    }

    updateRoofVertexLoop() {
        this.startingVertex = new RoofVertex(
            this.stage,
            this.completeLoop[0],
            null,
            null,
            true
        );
        const lastVertex = new RoofVertex(
            this.stage,
            this.completeLoop[this.completeLoop.length - 1],
            this.startingVertex,
            null,
            false
        );
        this.startingVertex.previous = lastVertex;
        let tempVertex = this.startingVertex;
        for (let i = 1; i < this.completeLoop.length - 1; i++) {
            const roofVertex = new RoofVertex(
                this.stage,
                this.completeLoop[i],
                null,
                tempVertex,
                false
            );
            tempVertex.next = roofVertex;
            tempVertex = roofVertex;
        }
        tempVertex.next = lastVertex;
        lastVertex.previous = tempVertex;
    }
    getPosition() {
        // get centroid of outline points
        let count = 0;
        let cumulativeX = 0;
        let cumulativeY = 0;
        let cumulativeZ = 0;
        const facePoints = [];
        this.roofFaces.forEach((face) => {
            face.vertices.forEach((vertex) => {
                facePoints.push(vertex);
            });
        });
        for (let i = 0, len = facePoints.length; i < len; i += 1) {
            const pointPosition = facePoints[i];
            cumulativeX += pointPosition.x;
            cumulativeY += pointPosition.y;
            cumulativeZ += pointPosition.z;
            count += 1;
        }
        // noinspection JSValidateTypes
        return new THREE.Vector3(
            cumulativeX / count,
            cumulativeY / count,
            cumulativeZ / count
        );
    }

    findAdjacentFaces(faces = this.roofFaces) {
        // Step 3: Map vertices to faces
        const vertexToFaceMap = new Map();
        faces.forEach((face) => {
            face.vertices.forEach((vertex) => {
                const vertexKey = `${vertex.x},${vertex.y}`;
                if (!vertexToFaceMap.has(vertexKey)) {
                    vertexToFaceMap.set(vertexKey, []);
                }
                vertexToFaceMap.get(vertexKey).push(face.id);
            });
        });

        // Step 4 & 5: Determine adjacency and build the adjacency map
        const adjacencyMap = new Map();
        faces.forEach((face) => {
            const sharedVerticesCount = new Map(); // Track shared vertex count
            face.vertices.forEach((vertex) => {
                const vertexKey = `${vertex.x},${vertex.y}`;
                const facesSharingVertex = vertexToFaceMap.get(vertexKey);
                facesSharingVertex.forEach((adjacentFaceId) => {
                    if (adjacentFaceId !== face.id) {
                        sharedVerticesCount.set(
                            adjacentFaceId,
                            (sharedVerticesCount.get(adjacentFaceId) || 0) + 1
                        );
                    }
                });
            });

            // Only consider faces adjacent if they share at least 2 vertices
            const adjacentFaces = Array.from(sharedVerticesCount.entries())
                .filter(([_, count]) => count >= 2)
                .map(([faceId, _]) => faceId);

            adjacencyMap.set(face.id, adjacentFaces);
        });

        return adjacencyMap;
    }
    async minimizePolygonAreaTiltBetweenTwoFaces(face1, face2) {
        const tolerance = 0.00005; // Define a tolerance level for convergence
        const maxIterations = 100; // Define a maximum number of iterations to prevent infinite loops
        let deltaTilt = -1; // Step size for tilt adjustment, initialized negatively
        const deltaTiltReductionFactor = 0.95; // Factor to reduce deltaTilt by in each iteration
        const minDeltaTilt = 0.01;
        const maxTilt = 45;
        const initTilt = face2.tilt;

        const adjustTiltAndComputeArea = (tiltValue) => {
            face2.tiltChange(tiltValue); // Adjust tilt by tiltValue for face2 only
            this.updateGeometry();
            this.getAllSmartroofIntersections();

            const { polygonVertices } = utils.getCommonEdges(face1, face2);

            if (polygonVertices.length === 0) {
                return 0; // No common edge, return 0 area
            }

            const p1 = polygonVertices[0];
            const p2 = polygonVertices[1];
            const p3 = polygonVertices[2];
            const planeNormal = new THREE.Vector3()
                .crossVectors(
                    new THREE.Vector3().subVectors(p2, p1),
                    new THREE.Vector3().subVectors(p3, p1)
                )
                .normalize();
            const plane = { normal: planeNormal, point: p1, a: p1, b: p2 };

            const projectedVertices2D = utils.projectPointsOntoPlane(
                polygonVertices,
                plane
            );
            return utils.computePolygonArea(projectedVertices2D);
        };

        let iteration = 0;
        let currentArea;
        let previousArea = Infinity;

        while (iteration < maxIterations) {
            const initialTilt = face2.tilt;
            currentArea = adjustTiltAndComputeArea(initialTilt + deltaTilt);

            if (Math.abs(previousArea - currentArea) < tolerance) {
                break; // Converged
            }

            // If the previous area is less than the current area, reverse the direction of delta tilt
            if (previousArea < currentArea) {
                deltaTilt *= -1; // Reverse the tilt direction for the next iteration
            }

            // Adjust deltaTilt based on progress
            deltaTilt *= deltaTiltReductionFactor;
            deltaTilt =
                Math.max(Math.abs(deltaTilt), minDeltaTilt) *
                Math.sign(deltaTilt); // Ensure deltaTilt does not become too small

            previousArea = currentArea;
            iteration++;
        }

        // If the tilt adjustment exceeds the maximum tilt, revert to the initial tilt
        if (Math.abs(face2.tilt) > maxTilt || face2.tilt < 0) {
            face2.tiltChange(initTilt); // Adjust tilt by tiltValue for face2 only
            this.updateGeometry();
            this.getAllSmartroofIntersections();
        }
    }
    async minimizePolygonAreaHeightBetweenTwoFaces(face1, face2) {
        const tolerance = 0.00005; // Define a tolerance level for convergence
        const maxIterations = 100; // Define a maximum number of iterations to prevent infinite loops
        let deltaHeight = -0.05; // Step size for height adjustment, initialized negatively
        const deltaHeightReductionFactor = 0.95; // Factor to reduce deltaHeight by in each iteration
        const minDeltaHeight = 0.001;
        const maxHeight = 50;
        const initHeight = face2.coreHeight;
    
        const adjustHeightAndComputeArea = (heightValue) => {
            face2.updateHeight(heightValue); // Adjust height by heightValue for face2 only
            this.updateGeometry();
            this.getAllSmartroofIntersections();
    
            const { polygonVertices } = utils.getCommonEdges(face1, face2);
    
            if (polygonVertices.length === 0) {
                return 0; // No common edge, return 0 area
            }
    
            const p1 = polygonVertices[0];
            const p2 = polygonVertices[1];
            const p3 = polygonVertices[2];
            const planeNormal = new THREE.Vector3()
                .crossVectors(
                    new THREE.Vector3().subVectors(p2, p1),
                    new THREE.Vector3().subVectors(p3, p1)
                )
                .normalize();
            const plane = { normal: planeNormal, point: p1, a: p1, b: p2 };
    
            const projectedVertices2D = utils.projectPointsOntoPlane(
                polygonVertices,
                plane
            );
            return utils.computePolygonArea(projectedVertices2D);
        };
    
        let iteration = 0;
        let currentArea;
        let previousArea = Infinity;
    
        while (iteration < maxIterations) {
            currentArea = adjustHeightAndComputeArea(deltaHeight);
    
            if (Math.abs(previousArea - currentArea) < tolerance) {
                break; // Converged
            }
    
            // If the previous area is less than the current area, reverse the direction of delta height
            if (previousArea < currentArea) {
                deltaHeight *= -1; // Reverse the height direction for the next iteration
            }
    
            // Adjust deltaHeight based on progress
            deltaHeight *= deltaHeightReductionFactor;
            deltaHeight =
                Math.max(Math.abs(deltaHeight), minDeltaHeight) *
                Math.sign(deltaHeight); // Ensure deltaHeight does not become too small
    
            previousArea = currentArea;
            iteration++;
        }

        if(face2.coreHeight < 0 || face2.coreHeight >= maxHeight) {
            face2.updateHeight(initHeight - face2.coreHeight);
            this.updateGeometry();
            this.getAllSmartroofIntersections();
        }
    }

    async minimizePolygonAreaHeightBFS() {
        // Initialize
        const groundTruthFace = this.getLargestRoof();
        const queue = [groundTruthFace];
        const processedFaces = new Set([groundTruthFace.id]);
        const adjacencyMap = this.findAdjacentFaces(this.roofFaces);

        const processAdjacentFaces = async (currentFace) => {
            const adjacentFaceIds = adjacencyMap.get(currentFace.id) || [];
            const newlyProcessedFaces = [];

            for (const adjacentFaceId of adjacentFaceIds) {
                if (!processedFaces.has(adjacentFaceId)) {
                    const adjacentFace = this.roofFaces.find(
                        (f) => f.id === adjacentFaceId
                    );
                    if (adjacentFace) {
                        await this.minimizePolygonAreaHeightBetweenTwoFaces(
                            currentFace,
                            adjacentFace
                        );
                        newlyProcessedFaces.push(adjacentFace);
                        processedFaces.add(adjacentFaceId);
                        queue.push(adjacentFace);
                    }
                }
            }

            return newlyProcessedFaces;
        };

        // BFS main loop
        while (queue.length > 0) {
            const currentFace = queue.shift();
            await processAdjacentFaces(currentFace);
        }

        // Process any remaining unconnected faces
        for (const face of this.roofFaces) {
            if (!processedFaces.has(face.id)) {
                queue.push(face);
                processedFaces.add(face.id);

                while (queue.length > 0) {
                    const currentFace = queue.shift();
                    await processAdjacentFaces(currentFace);
                }
            }
        }
    }
    async minimizePolygonAreaTiltBFS() {
        // Initialize
        const groundTruthFace = this.getLargestRoof();
        const queue = [groundTruthFace];
        const processedFaces = new Set([groundTruthFace.id]);
        const adjacencyMap = this.findAdjacentFaces(this.roofFaces);

        const processAdjacentFaces = async (currentFace) => {
            const adjacentFaceIds = adjacencyMap.get(currentFace.id) || [];
            const newlyProcessedFaces = [];

            for (const adjacentFaceId of adjacentFaceIds) {
                if (!processedFaces.has(adjacentFaceId)) {
                    const adjacentFace = this.roofFaces.find(
                        (f) => f.id === adjacentFaceId
                    );
                    if (adjacentFace) {
                        await this.minimizePolygonAreaTiltBetweenTwoFaces(
                            currentFace,
                            adjacentFace
                        );
                        newlyProcessedFaces.push(adjacentFace);
                        processedFaces.add(adjacentFaceId);
                        queue.push(adjacentFace);
                    }
                }
            }

            return newlyProcessedFaces;
        };

        // BFS main loop
        while (queue.length > 0) {
            const currentFace = queue.shift();
            await processAdjacentFaces(currentFace);
        }

        // Process any remaining unconnected faces
        for (const face of this.roofFaces) {
            if (!processedFaces.has(face.id)) {
                queue.push(face);
                processedFaces.add(face.id);

                while (queue.length > 0) {
                    const currentFace = queue.shift();
                    await processAdjacentFaces(currentFace);
                }
            }
        }
    }
    // async minimizePolygonAreaHeight() {
    //     const tolerance = 0.00005; // Define a tolerance level for convergence
    //     const maxIterations = 100; // Define a maximum number of iterations to prevent infinite loops
    //     let deltaZ = 0.1; // Step size for height adjustment, initialized positively
    //     const deltaZReductionFactor = 1; // Factor to reduce deltaZ by in each iteration
    //     const minDeltaZ = 0.01;
    //     const highlightColor = 0xff0000; // Color to highlight the face currently being modified
    //     const originalColor = 0xffffff; // Color to revert to after modification
    //     const waitTime = 100; // Wait time in milliseconds

    //     const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    //     const adjustHeightAndComputeArea = async (face, deltaZ) => {
    //         face.updateHeight(deltaZ); // Adjust height by deltaZ
    //         this.updateGeometry();
    //         this.getAllSmartroofIntersections();
    //         const commonVerticesArray = [];
    //         for (let i = 0; i < this.roofFaces.length; i++) {
    //             for (let j = i + 1; j < this.roofFaces.length; j++) {
    //                 const { commonVertices } = utils.getCommonEdges(this.roofFaces[i], this.roofFaces[j]);
    //                 if (commonVertices.length > 0) {
    //                     commonVerticesArray.push(commonVertices);
    //                 }
    //             }
    //         }

    //         const areas = commonVerticesArray.map(commonVertices => {
    //             const p1 = commonVertices[0];
    //             const p2 = commonVertices[1];
    //             const p3 = commonVertices[2];
    //             const planeNormal = new THREE.Vector3().crossVectors(
    //                 new THREE.Vector3().subVectors(p2, p1),
    //                 new THREE.Vector3().subVectors(p3, p1)
    //             ).normalize();
    //             const plane = { normal: planeNormal, point: p1, a: p1, b: p2 };

    //             const projectedVertices2D = utils.projectPointsOntoPlane(commonVertices, plane);
    //             return utils.computePolygonArea(projectedVertices2D);
    //         });

    //         return areas.reduce((a, b) => a + b, 0); // Return the total area of all common polygons
    //     };

    //     let iteration = 0;
    //     let totalArea;
    //     let previousTotalArea = Infinity;

    //     while (iteration < maxIterations) {
    //         totalArea = 0;

    //         for (let i = 0; i < this.roofFaces.length; i++) {
    //             const face = this.roofFaces[i];

    //             let currentArea = await adjustHeightAndComputeArea(face, deltaZ);

    //             await wait(waitTime);
    //             totalArea += currentArea;

    //         }

    //         // Uncomment and correct the convergence check
    //         if (Math.abs(previousTotalArea - totalArea) < tolerance) {
    //             break; // Converged
    //         }

    //         // If the previous area is less than the total area, reverse the direction of deltaZ
    //         if (previousTotalArea < totalArea) {
    //             deltaZ *= -1; // Reverse the height direction for the next iteration
    //         }

    //         // Adjust deltaZ based on progress and ensure it doesn't become too small
    //         deltaZ *= 0.95; // Assuming deltaZReductionFactor should be 0.95 for gradual reduction
    //         if (Math.abs(deltaZ) < minDeltaZ) {
    //             deltaZ = deltaZ < 0 ? -minDeltaZ : minDeltaZ; // Ensure deltaZ does not become too small in absolute value
    //         }

    //         previousTotalArea = totalArea;
    //         // console.log('previousTotalArea: ', previousTotalArea);
    //         iteration++;
    //     }
    // }
}