import * as THREE from 'three';
import OutlinePoints from '../subObjects/OutlinePoints';
import PolygonMeasurement from '../subObjects/PolygonMeasurement';
import RotationPoint from '../subObjects/RotationPoint';
import PolygonModel from "./PolygonModel";
import * as utils from '../../utils/utils';
import * as raycastingUtils from '../../utils/raycastingUtils';
import {
    COMPLEX_GEOMETRY_ERROR,
    CREATED_STATE,
    DELETED_STATE,
    DRAWING_POINT_INSIDE_PATIO_SETBACK_AREA_ERROR,
    INVALID_CORE_HEIGHT_ERROR,
    INVALID_TILT_ERROR,
    INVALID_TOP_HEIGHT_ERROR,
    LAST_EDGE_INTERSECTION_ERROR,
    OUT_OF_GROUND_ERROR,
    POLYGON_WITH_NO_AREA_ERROR,
    RECTANGLE_NAME,
    SALES_MODE,
    VERTEX_EQUIVALENT_ERROR,
    VERTEX_OVER_EDGE_ERROR
} from '../../coreConstants';
import { MINIMUM_NUMBER_OF_POINTS } from '../subObjects/TextBox';
import { SmartroofModel } from './smartroof/SmartroofModel';
import ThreejsText from '../subObjects/ThreejsText';
import Tree from './Tree';
import Inverter from '../ac/Inverter';
import HybridInverter from '../ac/HybridInverter';
import CombinerBox from '../ac/CombinerBox';
import ACDB from '../ac/ACDB';
import DCDB from '../ac/DCDB';
import EastWestRack from '../../lib/EastWestRacking';
import Subarray from '../subArray/Subarray';
import Gazebo from '../../lib/PowerGazebo';
import Walkway from './Walkway';
import SafetyLine from './SafetyLine';
import Handrail from './Handrail';
import AcCable from './cable/AcCable';
import DcCable from './cable/DcCable';
import { getRectangleObstruction } from '../../utils/exporters';

export default class RectangleObstruction extends PolygonModel {
    constructor(stage) {
        super(stage, true);
        this.isRotationAllowed = false;
        this.setbackInside = null;
        this.flushType = false;

        this.salesModeProperties = {
            name: RECTANGLE_NAME,
            updated: false,
        }
        this.canvasName = '';
        this.obstructionType = RectangleObstruction.getObjectType();
    }

    saveObject(isCopy = false) {
        const polygonModelData = super.saveObject(isCopy);
        if(this.stage.mode === SALES_MODE) polygonModelData.canvasName = this.canvasName;
        return polygonModelData;
    }

    // need to call the make canvas text simultaneously while loading as the save state is being called.
    loadObject(polygonModelData, isPaste = false) {
        if (this.stage.mode === SALES_MODE) { // load new AIModel salesmode
            if (polygonModelData.isAIModel) {
                this.isAIModel = polygonModelData.isAIModel;
                this.makeMaterialsTransparent();
            }
            else {
                this.isAIModel = false;
            }

            if (polygonModelData.canvasName) {
                this.canvasName = polygonModelData.canvasName;
            }
            else {
                this.canvasName = `RO${this.salesModeProperties.name[this.salesModeProperties.name.length - 1]}`;
            }
            if (!this.validateObject(polygonModelData).isValid) {
                this.stage.stateManager.add({
                    uuid: this.uuid,
                    getStateCb: () => DELETED_STATE,
                });

                this.stage.sceneManager.scene.remove(this.objectsGroup);

                if (this.getParent() !== null) {
                    this.getParent().removeChild(this);
                }

                this.stage.eventManager
                    .customErrorMessage('Polygon data invalid: Polygon removed');
                return;
            }

            // load id and name
            if (!isPaste) {
                this.id = polygonModelData.id;
                this.name = polygonModelData.name;
            }
            if (polygonModelData.salesModeName) {
                this.salesModeProperties = { name: polygonModelData.salesModeName, updated: true }
            }
            else if (polygonModelData.salesModeProperties) {
                this.salesModeProperties = polygonModelData.salesModeProperties;
            }

            // load polygon properties
            this.baseHeight = polygonModelData.baseHeight;
            this.coreHeight = polygonModelData.coreHeight;
            this.parapetHeight = polygonModelData.parapetHeight;
            this.parapetThickness = polygonModelData.parapetThickness;
            this.tilt = polygonModelData.tilt;
            this.azimuth = polygonModelData.azimuth;
            this.setbackInside = polygonModelData.setbackInside;
            this.setbackOutside = polygonModelData.setbackOutside;
            this.flashingEnabled = polygonModelData.flashingEnabled || false;
            this.flashingWidth = polygonModelData.flashingWidth || 0;
            this.ignored = polygonModelData.ignored;
            if (polygonModelData.placable === undefined) {
                this.placable = this.getDefaultValues().placable;
            } else {
                this.placable = polygonModelData.placable;
            }
            this.lockedParameter = polygonModelData.lockedParameter;
            this.topHeight = polygonModelData.topHeight;
            this.isRotationAllowed = (polygonModelData.isRotationAllowed === undefined || polygonModelData.isRotationAllowed === null) ? true : polygonModelData.isRotationAllowed,
                this.obstruction = polygonModelData.obstruction;
            this.isObstruction = polygonModelData.isObstruction;
            if (!polygonModelData.obstruction) {
                this.obstruction = 'None';
            }

            // this.rotationPoints = polygonModelData.rotationPoints;

            // set outline points
            for (let i = 0, len = polygonModelData.outlinePoints.length; i < len; i += 1) {
                this.outlinePoints.push(this.stage.initializeOutlinePoints(
                    polygonModelData.outlinePoints[i][0],
                    polygonModelData.outlinePoints[i][1],
                    polygonModelData.outlinePoints[i][2],
                    this,
                    this.stage,
                ));
            }
            if (this.isObstruction) {
                this.setbackInside = null;
                this.flushType = polygonModelData.flushType;
            }
            if (!this.isObstruction) {
                for (let i = 0, l = this.outlinePoints.length; i < l; i += 1) {
                    const nextIndex = i + 1 < l ? i + 1 : 0;
                    const currentPoint = this.outlinePoints[i].getPosition();
                    const nextPoint = this.outlinePoints[nextIndex].getPosition();
                    this.edgeCentrePoints.push(new EdgeCentrePoints(
                        (currentPoint.x + nextPoint.x) / 2,
                        (currentPoint.y + nextPoint.y) / 2,
                        (currentPoint.z + nextPoint.z) / 2,
                        this,
                        this.stage,
                    ));
                }
            }

            if (!Array.isArray(this.setbackInside)) {
                const setbackValues = [];
                if (this.setbackInside === 0) {
                    this.setbackInside = 0.001;
                }
                for (let i = 0, len = this.outlinePoints.length; i < len; i += 1) {
                    setbackValues.push(this.setbackInside);
                }
                this.setbackInside = setbackValues;
            }
            else {
                for (let i = 0, len = this.setbackInside.length; i < len; i += 1) {
                    if (Array.isArray(this.setbackInside[i])) {
                        for (let j = 0, len = this.setbackInside[i].length; j < len; j += 1) {
                            if (this.setbackInside[i][j] === 0) {
                                this.setbackInside[i][j] = 0.001;
                            }
                        }
                    }
                    else {
                        if (this.setbackInside[i] === 0)
                            this.setbackInside[i] = 0.001;
                    }
                }
            }

            if (!Array.isArray(this.setbackOutside)) {
                const setbackValues = [];
                if (this.setbackOutside === 0) {
                    this.setbackOutside = 0.001;
                }
                for (let i = 0, len = this.outlinePoints.length; i < len; i += 1) {
                    setbackValues.push(this.setbackOutside);
                }
                this.setbackOutside = setbackValues;
            }
            else {
                for (let i = 0, len = this.setbackOutside.length; i < len; i += 1) {
                    if (Array.isArray(this.setbackOutside[i])) {
                        for (let j = 0, len = this.setbackOutside[i].length; j < len; j += 1) {
                            if (this.setbackOutside[i][j] === 0) {
                                this.setbackOutside[i][j] = 0.001;
                            }
                        }
                    }
                    else {
                        if (this.setbackOutside[i] === 0)
                            this.setbackOutside[i] = 0.001;
                    }
                }
            }

            // create polygon measurement
            this.polygonMeasurement = new PolygonMeasurement([...this.outlinePoints], this, this.stage);

            // update geometry
            this.updateGeometry();
            this.createRotation();

            // load children
            const { children } = polygonModelData;
            this.errors = polygonModelData.errors ? polygonModelData.errors : [];
            for (let i = 0, len = children.length; i < len; i += 1) {
                let currentObject;
                try {
                    if (this.stage.checkObjectTypePolygonModel(children[i])) {
                        const polygonModel = this.stage.initializePolygonModel();
                        currentObject = polygonModel;
                        this.addChild(polygonModel);
                        polygonModel.loadObject(children[i], isPaste);
                        if (polygonModel.getParent() !== this) {
                            console.error('PolygonModel: Mismatch in parent while loading PolygonModel');
                        }
                    } else if (children[i].type === RectangleObstruction.getObjectType()) {
                        const rectangleObstruction = new RectangleObstruction(this.stage);
                        currentObject = rectangleObstruction;
                        this.addChild(rectangleObstruction);
                        rectangleObstruction.loadObject(children[i], isPaste);
                        if (rectangleObstruction.getParent() !== this) {
                            console.error('PolygonModel: Mismatch in parent while loading PolygonModel');
                        }
                    } else if (this.stage.checkObjectTypeCylinderModel(children[i])) {
                        const cylinderModel = this.stage.initializeCylinderModel();
                        currentObject = cylinderModel;
                        this.addChild(cylinderModel);
                        cylinderModel.loadObject(children[i], isPaste);
                        if (cylinderModel.getParent() !== this) {
                            console.error('PolygonModel: Mismatch in parent while loading PolygonModel');
                        }
                    } else if (children[i].type === Tree.getObjectType()) {
                        const tree = new Tree(this.stage);
                        currentObject = tree;
                        this.addChild(tree);
                        tree.loadObject(children[i], isPaste);
                        if (tree.getParent() !== this) {
                            console.error('PolygonModel: Mismatch in parent while loading PolygonModel');
                        }
                    } else if (children[i].type === Inverter.getObjectType()) {
                        if (!isPaste) {
                            const inverter = new Inverter(this.stage);
                            currentObject = inverter;
                            this.addChild(inverter);
                            inverter.loadObject(children[i], isPaste);
                            if (inverter.getParent() !== this) {
                                console.error('PolygonModel: Mismatch in parent while loading Inverter');
                            }
                        }
                    } else if (children[i].type === HybridInverter.getObjectType()) {
                        if (!isPaste) {
                            const inverter = new HybridInverter(this.stage);
                            currentObject = inverter;
                            this.addChild(inverter);
                            inverter.loadObject(children[i], isPaste);
                            if (inverter.getParent() !== this) {
                                console.error('PolygonModel: Mismatch in parent while loading Hybrid Inverter');
                            }
                        }
                    } else if (children[i].type === CombinerBox.getObjectType()) {
                        const combinerBox = new CombinerBox(this.stage);
                        currentObject = combinerBox;
                        this.addChild(combinerBox);
                        combinerBox.loadObject(children[i], isPaste);
                        if (combinerBox.getParent() !== this) {
                            console.error('PolygonModel: Mismatch in parent while loading Combiner Box');
                        }
                    } else if (children[i].type === ACDB.getObjectType()) {
                        try {
                            const acdb = new ACDB(this.stage);
                            currentObject = acdb;
                            this.addChild(acdb);
                            acdb.loadObject(children[i], isPaste);
                            if (acdb.getParent() !== this) {
                                console.error('PolygonModel: Mismatch in parent while loading ACDB');
                            }
                        } catch (error) {
                            console.error('PolygonModel.js: error in loading ACdb', error);
                        }
                        // TODO: not a fix for loading later on and then adding the electrical connection
                        // this.stage.ground.allAcdbs.push({data:children[i], acdb:acdb, isPaste:false});
                    } else if (children[i].type === DCDB.getObjectType()) {
                        if (!isPaste) {
                            try {
                                const dcdb = new DCDB(this.stage);
                                currentObject = dcdb;
                                this.addChild(dcdb);
                                dcdb.loadObject(children[i], isPaste);
                                if (dcdb.getParent() !== this) {
                                    console.error('PolygonModel: Mismatch in parent while loading DCDB');
                                }
                            } catch (error) {
                                console.error('PolygonModel.js: error in loading dcdb', error);
                            }

                        }
                    } else if (children[i].type === EastWestRack.getObjectType()) {
                        if (children[i].eastWestSubarraydata) {
                            // we have 2 childrens of the east west subarray 
                            // if we load both here we wont be able to link them together
                            // after loading, so we are loading it in the east west rack loadobject
                            const ewRack = new EastWestRack(this.stage);
                            currentObject = ewRack;
                            ewRack.loadObject(children[i], this, isPaste);
                        }
                        else {
                            continue;
                        }
                    } else if (children[i].type === Subarray.getObjectType()) {
                        const subarray = new Subarray(this.stage);
                        currentObject = subarray;
                        subarray.loadObject(children[i], this, isPaste);
                    } else if (children[i].type === Gazebo.getObjectType()) {
                        const gazebo = new Gazebo(this.stage);
                        currentObject = gazebo;
                        gazebo.loadObject(children[i], this, isPaste);
                    } else if (children[i].type === Walkway.getObjectType()) {
                        const walkway = new Walkway(this.stage);
                        currentObject = walkway;
                        this.addChild(walkway);
                        walkway.loadObject(children[i], isPaste);
                    } else if (children[i].type === SafetyLine.getObjectType()) {
                        const safetyLine = new SafetyLine(this.stage);
                        currentObject = safetyLine;
                        this.addChild(safetyLine);
                        safetyLine.loadObject(children[i], isPaste);
                    } else if (children[i].type === Handrail.getObjectType()) {
                        const handrail = new Handrail(this.stage);
                        currentObject = handrail;
                        this.addChild(handrail);
                        handrail.loadObject(children[i], isPaste);
                    } else if (children[i].type === AcCable.getObjectType()) {
                        try {
                            const acCable = new AcCable(this.stage);
                            currentObject = acCable;
                            this.addChild(acCable);
                            acCable.loadObject(children[i], isPaste);
                        } catch (error) {
                            console.error('PolygonModel.js: error in loading AC cable', error);
                        }
                        // this.stage.ground.allCables.push({data:children[i], cable:acCable, isPaste:isPaste});
                    } else if (children[i].type === DcCable.getObjectType()) {
                        const dcCable = new DcCable(this.stage);
                        currentObject = dcCable;
                        this.addChild(dcCable);
                        // this.stage.ground.allCables.push({data:children[i], cable:dcCable, isPaste:isPaste});
                    }
                    // else if (children[i].type === Conduit.getObjectType()) {
                    //     const conduit = new Conduit(this.stage);
                    //     this.addChild(conduit);
                    //     conduit.loadObject(children[i], isPaste);
                    //     this.allConduitAndCabletary.push(conduit);
                    // }
                    // else if (children[i].type === DoubleConduit.getObjectType()) {
                    //     const doubleConduit = new DoubleConduit(this.stage);
                    //     this.addChild(doubleConduit);
                    //     doubleConduit.loadObject(children[i], isPaste);
                    //     this.allConduitAndCabletary.push(doubleConduit);
                    // }
                    // else if (children[i].type === DoubleSeparateConduit.getObjectType()) {
                    //     const doubleSeparateConduit = new DoubleSeparateConduit(this.stage);
                    //     this.addChild(doubleSeparateConduit);
                    //     doubleSeparateConduit.loadObject(children[i], isPaste);
                    //     this.allConduitAndCabletary.push(doubleSeparateConduit);
                    // }
                    // else if (children[i].type === SingleCableTray.getObjectType()) {
                    //     const singleCableTray = new SingleCableTray(this.stage);
                    //     this.addChild(singleCableTray);
                    //     singleCableTray.loadObject(children[i], isPaste);
                    //     this.allConduitAndCabletary.push(singleCableTray);
                    // }
                    // else if (children[i].type === DoubleCableTray.getObjectType()) {
                    //     const doubleCableTray = new DoubleCableTray(this.stage);
                    //     this.addChild(doubleCableTray);
                    //     doubleCableTray.loadObject(children[i], isPaste);
                    //     this.allConduitAndCabletary.push(doubleCableTray);
                    // }
                    // else if (children[i].type === DoubleSeparateCableTray.getObjectType()) {
                    //     const doubleSeparateCableTray = new DoubleSeparateCableTray(this.stage);
                    //     this.addChild(doubleSeparateCableTray);
                    //     doubleSeparateCableTray.loadObject(children[i], isPaste);
                    //     this.allConduitAndCabletary.push(doubleSeparateCableTray);
                    // }
                    else {
                        console.error('PolygonModel: Invalid object type in loadObject');
                    }
                } catch (error) {
                    console.error('Unable to load children of Polygon', error);
                    notificationsAssistant.error({
                        title: 'Load Error',
                        message: 'Error loading object. Please contact support.',
                    });
                    this.errors.push(children[i]);
                    currentObject.removeObject();
                }
            }

            this.attachedData = polygonModelData.attachedObjects;

            if (this.stage.isDesignMode) this.polygonMeasurement.hide();
            this.makeCanvasText();

            if (isPaste) {
                this.saveState({ withoutContainer: false });
            } else {
                this.saveState({ withoutContainer: true });
            }
        }
        else {
            super.loadObject(polygonModelData, isPaste);
        }
    }

    getState() {
        const polygonData = super.getState();
        if(this.stage.mode === SALES_MODE){
            if(this.canvasTextMesh) {
                polygonData.canvasName= this.canvasName;
                polygonData.canvasTextMeshData = {
                    position: this.canvasTextMesh.position,
                    angle: this.canvasTextMesh.angle,
                } 
            }
        }
        return polygonData;
    }
    loadState(state, fromState){
        super.loadState(state,fromState);
        if (this.stage.mode === SALES_MODE) {
            if(state.canvasTextMeshData) {
                this.canvasName = state.canvasName;
                this.canvasTextMesh.update(this.canvasName, state.canvasTextMeshData.position, state.canvasTextMeshData.angle);
            }
            if (fromState === CREATED_STATE || fromState === DELETED_STATE) {
                this.canvasName = state.canvasName;
                const getRecObstructionChildren = [];
                getRectangleObstruction(this.stage.ground, getRecObstructionChildren);
                if(!this.canvasName){
                    utils.assignSalesModeNames(getRecObstructionChildren);
                }
                this.makeCanvasText();
            }
            this.stage.selectionControls.setSelectedObject(this.stage.ground);
        }
    }

    async onComplete(geometry, geomVertices){
        const notificationObject = this.stage.eventManager.setRectangleCreating();
        const vertices = [];
        for (let i = 0; i < (geomVertices.length/3)-1; i += 1) {
            vertices.push(new THREE.Vector3(
                geomVertices[i * 3],
                geomVertices[(i * 3) + 1],
                geomVertices[(i * 3) + 2],
            ));
        }
        for (let i = 0, len = vertices.length; i < len; i += 1) {
            this.outlinePoints.push(this.stage.initializeOutlinePoints(
                vertices[i].x,
                vertices[i].y,
                vertices[i].z,
                this,
                this.stage,
            ));
        }
        // create polygon measurement
        this.polygonMeasurement = new PolygonMeasurement([...this.outlinePoints], this, this.stage);
        if (this.computeArea() > this.getDefaultValues().heatMapThreshold) {
            this.placable = true;
        } else {
            this.placable = false;
        }
        try {
            await this.placeObject();
            this.azimuth = this.getParent().azimuth===undefined ? 180 : this.getParent().azimuth;
            this.stage.eventManager.completeRectangleCreation(notificationObject);
            return Promise.resolve(true);
        } catch (error) {
            console.error('ERROR: PolygonModel: OnComplete failed.', error);
            this.onCancel();
            this.stage.eventManager.errorRectangleCreation(notificationObject);
            return Promise.reject(error);
        }
    }

    async generateFromVertices(vertices, parent) {
        for (let i = 0, len = vertices.length; i < len; i += 1) {
            this.outlinePoints.push(new OutlinePoints(
                vertices[i].x,
                vertices[i].y,
                vertices[i].z,
                this,
                this.stage,
            ));
        }
        // create polygon measurement
        this.polygonMeasurement = new PolygonMeasurement([...this.outlinePoints], this, this.stage);
        try {
            await this.placeOnParent(parent);

            const parentAzimuth = this.getParent().azimuth;
            // The azimuths of the rectangle edges are always 0 and 90 degrees
            const [azimuth1, azimuth2] = [0, 90];
            
            // Calculate the smallest rotation needed to align with parent azimuth
            const { magnitude, direction } = this.calculateSmallestRotation(azimuth1, azimuth2, parentAzimuth);
            this.rat = -magnitude*direction;
            // Rotate the object to align with the parent azimuth
            this.rotateObjectHelper(-magnitude * direction * Math.PI/180, this.getPosition());

            this.azimuth = parentAzimuth;
            this.updateGeometry();

            if(!this.isOverlapping(this.get2DVertices().map( v => new THREE.Vector2(v[0], v[1])), this.getParent().get2DVertices().map( v => new THREE.Vector2(v[0], v[1])))) {
                this.removeObject();
                return Promise.resolve(true);
            }
            
            if (this.stage.visualManager.in3D) {
                this.addMapTexture();
            }
            return Promise.resolve(true);
        } catch (error) {
            console.error('ERROR: PolygonModel: OnComplete failed.', error);
            this.onCancel();
            return Promise.reject(error);
        }
    }

    getPlacingInformationParent(parent) {
        const response = {};
        response.parent = parent;
        response.height = parent.getZOnTopSurface(...this.get2DVertices()[0]);
        const tiltAndHeightsParams = {
            coreHeight: this.coreHeight,
            topHeight: this.topHeight,
            tilt: this.tilt,
            azimuth: this.azimuth,
            lockedParameter: this.lockedParameter,
            parent: parent,
        };

        response.tiltAndHeights = this.computeTiltAndHeights(tiltAndHeightsParams);
        return response;
    }

    async placeOnParent(parent) {
        const placingInformation = this.getPlacingInformationParent(parent);
        // get new parent and height while placing
        const newParent = placingInformation.parent;
        const newHeight = placingInformation.height;

        // update new parent
        this.changeParent(newParent);

        // update new base height
        this.baseHeight = newHeight;

        const oldHeight = this.getZOnTopSurface(...this.get2DVertices()[0]);
        if (this.isObstruction && this.flushType) {
            this.tilt = this.getParent().tilt == undefined ? 0 : Number(this.getParent().tilt.toFixed(2));
            this.azimuth = this.getParent().azimuth == undefined ? 0: Number(this.getParent().azimuth.toFixed(2));
        }
        this.updateGeometry();

        if (!this.rotationPoints) {
            this.createRotation();
        }

        const deltaZ = this.getZOnTopSurface(...this.get2DVertices()[0]) - oldHeight;

        // update dimensions
        const keys = Object.keys(this.dimensionObjects);
        for (let i = 0; i < keys.length; i += 1) {
            this.dimensionObjects[keys[i]].handleAssociatedObjectPlace(this);
        }

        // Update locked parameter
        this.updateCurrentlyLockedParameter(placingInformation.tiltAndHeights);

        // update children
        for (let i = 0; i < this.getChildren().length; i += 1) {
            this.getChildren()[i].moveObject(0, 0, deltaZ, false);
        }

        // TODO: Jugaad, fix for moveObject of safety line and handrail
        utils.updateHandrailAndSafetyLineForMove(this);

        // update siblings
        try {
            await this.handleSiblingConsequences();
            await this.handleAttachmentConsequences();

            // remove object from hover manager and add it again
            this.stage.quadTreeManager.handlePlaceObject(this);

            this.resetGrandParentSolarAccess();
            this.stage.heatMap.removeHeatMapOnModelPlace();
            this.saveState();
        } catch (error) {
            console.error('ERROR: PolygonModel: placeObject failed', error);
            return Promise.reject(error);
        }
        return Promise.resolve(true);
    }

    isOverlapping(vertices, parentVertices) {
        // Function to calculate the area of a polygon
        const calculateArea = (vertices) => {
            let area = 0;
            for (let i = 0; i < vertices.length; i++) {
                let j = (i + 1) % vertices.length;
                area += vertices[i].x * vertices[j].y;
                area -= vertices[j].x * vertices[i].y;
            }
            return Math.abs(area) / 2;
        };
    
        // Function to check if a point is inside a polygon
        const isPointInPolygon = (point, polygon) => {
            let inside = false;
            for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
                const xi = polygon[i].x, yi = polygon[i].y;
                const xj = polygon[j].x, yj = polygon[j].y;
                
                const intersect = ((yi > point.y) !== (yj > point.y))
                    && (point.x < (xj - xi) * (point.y - yi) / (yj - yi) + xi);
                if (intersect) inside = !inside;
            }
            return inside;
        };
    
        // Function to find the intersection point of two line segments
        const lineIntersection = (p1, p2, p3, p4) => {
            const a = new THREE.Vector2().subVectors(p2, p1);
            const b = new THREE.Vector2().subVectors(p4, p3);
            const c = new THREE.Vector2().subVectors(p3, p1);
    
            const denominator = a.cross(b);
            if (Math.abs(denominator) < 1e-10) return null; // Lines are parallel
    
            const t = c.cross(b) / denominator;
            const u = c.cross(a) / denominator;
    
            if (0 <= t && t <= 1 && 0 <= u && u <= 1) {
                return new THREE.Vector2(p1.x + t * a.x, p1.y + t * a.y);
            }
    
            return null;
        };
    
        // Function to calculate the area of intersection between two polygons
        const calculateIntersectionArea = (poly1, poly2) => {
            const intersectionPoints = [];
    
            // Find intersection points
            for (let i = 0; i < poly1.length; i++) {
                for (let j = 0; j < poly2.length; j++) {
                    const p1 = poly1[i];
                    const p2 = poly1[(i + 1) % poly1.length];
                    const p3 = poly2[j];
                    const p4 = poly2[(j + 1) % poly2.length];
    
                    const intersection = lineIntersection(p1, p2, p3, p4);
                    if (intersection) {
                        intersectionPoints.push(intersection);
                    }
                }
            }
    
            // Add vertices of poly1 that are inside poly2
            for (const vertex of poly1) {
                if (isPointInPolygon(vertex, poly2)) {
                    intersectionPoints.push(vertex);
                }
            }
    
            // Add vertices of poly2 that are inside poly1
            for (const vertex of poly2) {
                if (isPointInPolygon(vertex, poly1)) {
                    intersectionPoints.push(vertex);
                }
            }
    
            // If no intersection points found, return 0
            if (intersectionPoints.length === 0) {
                return 0;
            }
    
            // Sort intersection points to form a convex hull
            const center = intersectionPoints.reduce((sum, p) => sum.add(p), new THREE.Vector2())
                .divideScalar(intersectionPoints.length);
    
            intersectionPoints.sort((a, b) => {
                const angleA = Math.atan2(a.y - center.y, a.x - center.x);
                const angleB = Math.atan2(b.y - center.y, b.x - center.x);
                return angleA - angleB;
            });
    
            // Calculate the area of the intersection polygon
            return calculateArea(intersectionPoints);
        };
    
        const obstructionArea = calculateArea(vertices);
    
        const intersectionArea = calculateIntersectionArea(vertices, parentVertices);
    
        const overlapRatio = intersectionArea / obstructionArea;
    
        return overlapRatio >= 0.4; // 40% overlap threshold
    }
    
    calculateSmallestRotation(azimuth1, azimuth2, targetAzimuth) {
        const rotations1 = this.getPossibleRotations(azimuth1, targetAzimuth);
        const rotations2 = this.getPossibleRotations(azimuth2, targetAzimuth);
        
        // Combine all possible rotations
        const allRotations = [...rotations1, ...rotations2];
        
        // Find the rotation with the smallest absolute value
        const smallestRotation = allRotations.reduce((smallest, current) => 
            Math.abs(current) < Math.abs(smallest) ? current : smallest
        );
    
        // Determine the direction: 1 for clockwise, -1 for counterclockwise
        const direction = smallestRotation >= 0 ? 1 : -1;
    
        return {
            magnitude: Math.abs(smallestRotation),
            direction: direction
        };
    }
    
    getPossibleRotations(currentAzimuth, targetAzimuth) {
        let baseRotation = targetAzimuth - currentAzimuth;
        
        // Generate rotations at 0, 90, 180, and 270 degrees from the base rotation
        return [0, 90, 180, 270].map(angle => {
            let rotation = (baseRotation + angle) % 360;
            // Normalize rotation to be between -180 and 180 degrees
            if (rotation > 180) rotation -= 360;
            if (rotation <= -180) rotation += 360;
            return rotation;
        });
    }

    handleVertexMove(vertex, deltaX = 0, deltaY = 0, deltaZ = 0, vertexValueBeforeMoved = 0) {
        if (!(vertex instanceof RotationPoint) && this.outlinePoints.indexOf(vertex) < 0) {
            console.error('ERROR: PolygonModel: vertex not in outlinePoints in handleVertexMove');
        }
        const displacementVector = new THREE.Vector3(deltaX, deltaY, 0);
        const movedVertexIndex = this.outlinePoints.indexOf(vertex);
        const prevPoint = this.outlinePoints[(movedVertexIndex-1)<0 ? this.outlinePoints.length - 1 : movedVertexIndex-1];
        const nextPoint = this.outlinePoints[(movedVertexIndex+1)>=(this.outlinePoints.length) ? 0 : movedVertexIndex+1];
        const prevLine = prevPoint.getPosition().sub(vertexValueBeforeMoved);
        const nextLine = nextPoint.getPosition().sub(vertexValueBeforeMoved);
        prevLine.z = 0;
        nextLine.z = 0;
        let prevLineMoved = new THREE.Vector3(0,0,0);
        let nextLineMoved = new THREE.Vector3(0,0,0);
        prevLineMoved = displacementVector.clone().projectOnVector(prevLine);
        nextLineMoved = displacementVector.clone().projectOnVector(nextLine);
        prevPoint.moveObjectWithoutConsequences(nextLineMoved.x, nextLineMoved.y, deltaZ);
        nextPoint.moveObjectWithoutConsequences(prevLineMoved.x, prevLineMoved.y, deltaZ);
        // update geometry
        this.updateGeometry();

        this.saveState();
    }

    updateWhilePlacing(placingInformation) {
        if (!this.isRotationAllowed && placingInformation.parent && (placingInformation.parent.azimuth !== this.azimuth)) {
            const rotationPoint = this.getPosition();
            let angleToRotate = this.azimuth - (placingInformation.parent.azimuth ? placingInformation.parent.azimuth : 180);
            // if (angleToRotate < 0) {
            //     angleToRotate += 360;
            // }
            const angleInRad = THREE.MathUtils.degToRad(angleToRotate);
            for (let i = 0; i < this.outlinePoints.length; i++) {
                const outlinePointX = this.outlinePoints[i].getPosition().x;
                const outlinePointY = this.outlinePoints[i].getPosition().y;
                const outlineDeltaXY = utils.rotationAroundPoint(
                    rotationPoint.x,
                    rotationPoint.y,
                    outlinePointX,
                    outlinePointY,
                    angleInRad,
                );

                this.outlinePoints[i].moveObjectWithoutConsequences(
                    outlineDeltaXY[0] - outlinePointX,
                    outlineDeltaXY[1] - outlinePointY,
                );
            }
            this.azimuth = placingInformation.parent.azimuth ? placingInformation.parent.azimuth : 180;
            this.updateGeometry(true);
            //fix to show edges over every object while moving. 
            this.coreEdges.position.z = utils.getHighestZ(this.stage.ground) + 5;
            this.polygonMeasurement.hide();
            const children = this.getChildren();
            for (let i = 0, l = children.length; i < l; i += 1) {
                const grandChildren = [...children[i].getChildren()];
                for (let j = 0, k = grandChildren.length; j < k; j++) {
                    if (grandChildren[j] instanceof Subarray) {
                        grandChildren[j].removeObject();
                        continue;
                    }
                }
                children[i].updateGeometry();
                children[i].rotateObjectHelper(angleInRad, rotationPoint);
            }
        }
    }

    getPlacingInformation(vertices) {
        const response = {};
        let numberOfPoints;

        // Getting vertices
        let vertices2DArray;
        if (vertices === null || vertices === undefined) {
            vertices2DArray = this.get2DVertices();
            numberOfPoints = vertices2DArray.length;
        } else {
            vertices2DArray = vertices;
            numberOfPoints = vertices2DArray.length - 1;
        }
        let parentExists = true;
        let polygonExists = true;
        response.errors = [];
        // This is the error that is displayed to the user
        response.pointUnplaceableError = null;

        const vertices2DVectorArray = utils.convertArrayToVector(vertices2DArray);
        if (!raycastingUtils.areVerticesOnGround(vertices2DVectorArray, this.stage)) {
            const error = new Error(OUT_OF_GROUND_ERROR);
            response.errors.push(error);
            parentExists = false;
            response.pointUnplaceableError = error;
        }
        if (vertices2DVectorArray.length > 0) {
            vertices2DArray.forEach(vertex => {
                if (this.stage.ground.patiosForModelConstraint.length > 0) this.stage.ground.patiosForModelConstraint.forEach((patio) => {
                    if (utils.checkPointInsideVertices(utils.convertVectorToArray(patio.setbackVertices), vertex)) {
                        const error = new Error(DRAWING_POINT_INSIDE_PATIO_SETBACK_AREA_ERROR);
                        response.errors.push(error);
                        parentExists = false;
                        response.pointUnplaceableError = error;
                    }
                });
            });
        }
        if (utils.checkLastEdgeIntersectionWithEdges(vertices2DVectorArray)) {
            const error = new Error(LAST_EDGE_INTERSECTION_ERROR);
            response.errors.push(error);
            parentExists = false;
            polygonExists = false;
            response.pointUnplaceableError = error;
        }
        if (vertices2DArray.slice(0, numberOfPoints).length > MINIMUM_NUMBER_OF_POINTS &&
            utils.checkComplexGeometry(vertices2DArray.slice(0, numberOfPoints))) {
            const error = new Error(COMPLEX_GEOMETRY_ERROR);
            response.errors.push(error);
            response.cannotCompleteError = error;
            parentExists = false;
        }
        if (utils.checkVertexEquivalency(vertices2DArray)) {
            const error = new Error(VERTEX_EQUIVALENT_ERROR);
            response.errors.push(error);
            parentExists = false;
            response.pointUnplaceableError = error;
        }
        if (utils.checkIfLastVertexOnEdges(vertices2DArray)) {
            const error = new Error(VERTEX_OVER_EDGE_ERROR);
            response.errors.push(error);
            parentExists = false;
            response.pointUnplaceableError = error;
        }

        let erodedVertices;
        if (polygonExists) {
            // To accommodate for snapping
            erodedVertices = vertices2DArray;
            if (erodedVertices.length === 0) {
                response.errors.push(new Error(POLYGON_WITH_NO_AREA_ERROR));
                parentExists = false;
            }

            if (parentExists) {
                // using raycaster to get the top most model in new place
                // and get height of it for deltaZ
                // but ignoring this model or its child as we don't want to place over them
                const idsToIgnore = [this.uuid];
                this.getChildrenModelUuids(idsToIgnore);

                const allBelowModels =
                    raycastingUtils.getAllModelsBelowVertices(erodedVertices, this.stage);
                let [newParent, newHeight] = [-1, -1];
                for (let i = 0; i < allBelowModels.length; i += 1) {
                    const model = allBelowModels[i][0];
                    const height = allBelowModels[i][1];
                    if (!idsToIgnore.includes(model.uuid) && !(model instanceof SmartroofModel)) {
                        if (newHeight < height) {
                            [newParent, newHeight] = [model, height];
                        }
                    }
                }

                response.parent = newParent;
                response.height = newHeight;

                if (vertices2DVectorArray.length >= 3) {
                    const tiltAndHeightsParams = {
                        coreHeight: this.coreHeight,
                        topHeight: this.topHeight,
                        tilt: this.tilt,
                        azimuth: this.azimuth,
                        lockedParameter: this.lockedParameter,
                        parent: newParent,
                        vertices: vertices2DVectorArray,
                    };

                    response.tiltAndHeights = this.computeTiltAndHeights(tiltAndHeightsParams);
                    if (response.tiltAndHeights.coreHeight <= 0) {
                        const error = new Error(INVALID_CORE_HEIGHT_ERROR);
                        response.errors.push(error);
                        parentExists = false;
                        response.cannotCompleteError = error;
                    }
                    if (response.tiltAndHeights.topHeight <= 0) {
                        const error = new Error(INVALID_TOP_HEIGHT_ERROR);
                        response.errors.push(error);
                        parentExists = false;
                        response.cannotCompleteError = error;
                    }
                    if (response.tiltAndHeights.tilt < 0 ||
                        response.tiltAndHeights.tilt === null) {
                        const error = new Error(INVALID_TILT_ERROR);
                        response.errors.push(error);
                        parentExists = false;
                        response.cannotCompleteError = error;
                    }
                }
            }
        }

        return response;
    }

    moveObject(deltaX, deltaY, deltaZ = 0) {
        super.moveObject(deltaX, deltaY, deltaZ);
        if (this.stage.mode === SALES_MODE){
            if(this.canvasTextMesh)this.canvasTextMesh.update(this.canvasName, this.getPosition());
        }
    }
    // making the canvas text for rectangle.
    makeCanvasText() {
        const text = this.canvasName;
        const position = this.getPosition();
        const angle = 0;

        this.canvasTextMesh = new ThreejsText(text, position, angle, this.stage, this, this.editable,'center','middle', () => {}, true, false, true);
        this.canvasTextMesh.textMesh.fontSize = 0.3;
        this.canvasTextMesh.showObject();
    }

    updateCanvasText() {
        if(this.canvasTextMesh) {
            this.canvasTextMesh.text = this.canvasName;
            this.canvasTextMesh.update();
        }
    }

    removeObject(isTemporaryDuplicate = false) {
        super.removeObject(isTemporaryDuplicate);
        if (this.stage.mode === SALES_MODE){
            if(this.canvasTextMesh)this.canvasTextMesh.removeObject();
        }
    }

    clearState() {
        super.clearState();
        if (this.stage.mode === SALES_MODE){
            this.canvasTextMesh.removeObject();
        }
    }

    static getObjectType() {
        return 'RectangleObstruction';
    }
}