<!-- This component is a replacement for the bootstrap-icons-vue library -->
<template>
    <i
        v-if="icon"
        :class="'bi-' + icon"
        :style="style"
    />
    <span v-else></span>
</template>

<script>

export default {
    props: {
        icon: {
            type: String,
        },
        fontScale: {
            type: Number,
        },
        color: {
            type: String,
        },
    },
    computed: {
        style() {
            let styles = {}
            this.fontScale && (styles.fontSize = `${this.fontScale * 100}%`)
            this.color && (styles.color = this.color)
            return styles
        }
    },
}
</script>

<style scoped>
</style>