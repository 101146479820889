import { createApp, defineAsyncComponent, toRaw } from 'vue'
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueStatic from "vue-static";
import ElTableInfiniteScroll from "el-table-infinite-scroll";
// import Vue2TouchEvents from "vue2-touch-events";
import router from "./router";
// import Vuebar from "vuebar";
import InputLength from "./components/ui/length/inputLength.vue";
import DisplayLength from "./components/ui/length/displayLength.vue";
import 'vue3-perfect-scrollbar/style.css';
import "./styles/main.css";
import "./styles/variables.css";
import App from "./App.vue";
import BIcon from "./components/BIcon.vue";
import ElCustomIcon from './components/ElCustomIcon.vue';
import ScrollObserver from './components/ui/ScrollObserver.vue';
import 'bootstrap-icons/font/bootstrap-icons.css'
import * as Sentry from "@sentry/vue";
import { VITE_APP_ENVIRONMENT } from "./constants.js"
import { decodeHtml,decodeHtml2 } from "./utils/stringFormatters";
import Mousetrap from './plugins/mousetrap';
import {triggerValidation, validateAllInputs, isAllInputsValidOnSubmit, triggerValidationOnInput} from './utils/fieldValidations.js'
import { serverBus } from './plugins/serverBus';

import _ from "lodash";
window._ = _;

window.global ||= window;

let isSentryInitialized = false;

const Vue = createApp(App)

const pinia = createPinia()
Vue.use(pinia)
Vue.use(router)
Vue.use(ElementPlus)
Vue.use(ElTableInfiniteScroll);


let VueGlobalProperties = Vue.config.globalProperties
if (!VueGlobalProperties.$eventBus) {
  Object.defineProperty(VueGlobalProperties, "$eventBus", { value: serverBus });
}

// Common methods that are used globally
if (!VueGlobalProperties.$globalMethods) {
  Object.defineProperty(VueGlobalProperties, "$globalMethods", {
    value: {
      htmlDecode: decodeHtml,
      htmlDecode2: decodeHtml2,
    }
  });
}

if(!VueGlobalProperties.$triggerValidation){
  VueGlobalProperties.$triggerValidation = triggerValidation
}
if(!VueGlobalProperties.$validateAllInputs){
  VueGlobalProperties.$validateAllInputs = validateAllInputs
}
if(!VueGlobalProperties.$isAllInputsValidOnSubmit){
  VueGlobalProperties.$isAllInputsValidOnSubmit = isAllInputsValidOnSubmit
}
if(!VueGlobalProperties.$triggerValidationOnInput){
  VueGlobalProperties.$triggerValidationOnInput = triggerValidationOnInput
}

// Mousetrap
if (!VueGlobalProperties.$mousetrap) {
  Object.defineProperty(VueGlobalProperties, '$mousetrap', { value: Mousetrap });
}


// Vue.config.productionTip = false;
// // Vue.config.silent = true;
// // Making modules available to vue
// Vue.use(Vuebar);
Vue.use(VueStatic, {
  name: "nonReactiveData",
});

// Vue.use(Vue2TouchEvents);

Vue.component("input-length", InputLength);
Vue.component("display-length", DisplayLength);
Vue.component("b-icon", BIcon);
Vue.component("el-custom-icon", ElCustomIcon);
Vue.component("ScrollObserver", ScrollObserver);


Vue.component("add-credit-popup", defineAsyncComponent(() => import("./components/ui/AddCreditPopup.vue")))
Vue.component("project-upgrade-popup", defineAsyncComponent(() => import("./components/ui/ProjectUpgradePopup.vue")))
Vue.component("self-design-popup", defineAsyncComponent(() => import("./components/ui/SelfDesignPopup.vue")))
Vue.component("all-drawer", defineAsyncComponent(() => import("./pages/commonComponents/allDrawer/allDrawer.vue")))


// Modifying the original structuredClone method because in the three.js code,
// structuredClone is used to clone the objects and it does not work with Vue 3 Proxies,
// so we need to remove the Proxies by using the toRaw method.
// References:
// https://developer.mozilla.org/en-US/docs/Web/API/Window/structuredClone
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
// https://vuejs.org/api/reactivity-advanced.html#toraw
const originalStructuredClone = window.structuredClone;
window.structuredClone = function (obj) {
  return originalStructuredClone(toRaw(obj));
}


// Sentry
let enableSentry = true;
try{
  const allowedHosts = ["app.arka360.com","d2c.arka360.com"];
  const currentHost = window.location.hostname;
  if(enableSentry && !isSentryInitialized&&allowedHosts.includes(currentHost)){
  Sentry.init({
    Vue,
    dsn: "https://2b52abb7a79dda75ce6e9c3ae42c83c5@o4507464086650880.ingest.us.sentry.io/4507542284402688",
    environment: VITE_APP_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [ 
      /^https:\/\/dev\.arka360\.com\//,
      /^https:\/\/beta\.arka360\.com\//,
      /^https:\/\/app\.arka360\.com\//,
    ],
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event) {
      // Check if the event contains an exception
      if (event.exception) {
        const exception = event.exception.values?.[0];
        // Check if the exception matches the "Event" type and specific value
        if (
          exception &&
          exception.type === "Event" &&
          exception.value === "Event `Event` (type=error) captured as promise rejection"
        ) {
          // Ignore this event
          return null;
        }
      }
      // Allow all other events to be sent
      return event;
    },
  });
  }
  isSentryInitialized = true;
} catch(error) {
  console.error(error, 'Error in setting up Sentry');
  isSentryInitialized = false;
} finally {
  Vue.mount("#app");
}

