<!-- This component is a replacement for the element ui icons -->
<template>
    <el-icon :class="loadingClass">
        <component :is="iconComponent" />
    </el-icon>
</template>

<script>
import {
    Phone,
    HomeFilled,
    Tools,
    Comment,
    ChatLineSquare,
    Message,
    DocumentAdd,
    BottomLeft,
    TopRight,
    Loading,
    InfoFilled,
    Close,
    Files,
    ArrowLeft,
    ArrowRight,
    ArrowDown,
    ArrowUp,
    Plus,
    Search,
    Delete,
    UserFilled,
    Back,
    PhoneFilled,
    SuccessFilled,
    Remove,
    Check,
    CirclePlus,
    EditPen,
    Edit,
    DocumentChecked,
    Top,
    Bottom,
    Document,
    UploadFilled,
    LocationFilled,
    Share,
    Refresh,
    StarFilled,
    Right,
    Tickets,
    Calendar,
    CaretTop,
    CaretBottom,
    CaretRight,
    WarningFilled,
    MoreFilled,
    VideoPause,
    VideoPlay,
    Rank,
    Position,
    Download,
    CameraFilled,
    CircleCloseFilled,
} from '@element-plus/icons-vue';

export default {
    components: {
        Phone,
        HomeFilled,
        Tools,
        Comment,
        ChatLineSquare,
        Message,
        DocumentAdd,
        BottomLeft,
        TopRight,
        Loading,
        InfoFilled,
        Close,
        Files,
        ArrowLeft,
        ArrowRight,
        ArrowDown,
        ArrowUp,
        Plus,
        Search,
        Delete,
        UserFilled,
        Back,
        PhoneFilled,
        SuccessFilled,
        Remove,
        Check,
        CirclePlus,
        EditPen,
        Edit,
        DocumentChecked,
        Top,
        Bottom,
        Document,
        UploadFilled,
        LocationFilled,
        Share,
        Refresh,
        StarFilled,
        Right,
        Tickets,
        Calendar,
        CaretTop,
        CaretBottom,
        CaretRight,
        WarningFilled,
        MoreFilled,
        VideoPause,
        VideoPlay,
        Rank,
        Position,
        Download,
        CameraFilled,
        CircleCloseFilled,
    },
    computed: {
        elIconClass() {
            let classes = this.$attrs.class.split(" ")
            return classes.find(className => className.includes('el-icon'))
        },
        iconComponent() {
            let iconDict = {
                "el-icon-phone-outline": Phone,
                "el-icon-s-home": HomeFilled,
                "el-icon-s-tools": Tools,
                "el-icon-s-comment": Comment,
                "el-icon-chat-line-square": ChatLineSquare,
                "el-icon-message": Message,
                "el-icon-document-add": DocumentAdd,
                "el-icon-top-right": TopRight,
                "el-icon-bottom-left": BottomLeft,
                "el-icon-loading": Loading,
                "el-icon-info": InfoFilled,
                "el-icon-close": Close,
                "el-icon-files": Files,
                "el-icon-arrow-left": ArrowLeft,
                "el-icon-arrow-right": ArrowRight,
                "el-icon-arrow-down": ArrowDown,
                "el-icon-arrow-up": ArrowUp,
                "el-icon-plus": Plus,
                "el-icon-search": Search,
                "el-icon-delete": Delete,
                "el-icon-user-solid": UserFilled,
                "el-icon-back": Back,
                "el-icon-phone": PhoneFilled,
                "el-icon-success": SuccessFilled,
                "el-icon-remove-outline": Remove,
                "el-icon-check": Check,
                "el-icon-circle-plus-outline": CirclePlus,
                "el-icon-edit": EditPen,
                "el-icon-edit-outline": Edit,
                "el-icon-document-checked": DocumentChecked,
                "el-icon-top": Top,
                "el-icon-bottom": Bottom,
                "el-icon-document": Document,
                "el-icon-upload": UploadFilled,
                "el-icon-location": LocationFilled,
                "el-icon-share": Share,
                "el-icon-refresh": Refresh,
                "el-icon-star-on": StarFilled,
                "el-icon-right": Right,
                "el-icon-tickets": Tickets,
                "el-icon-date": Calendar,
                "el-icon-caret-top": CaretTop,
                "el-icon-caret-bottom": CaretBottom,
                "el-icon-caret-right": CaretRight,
                "el-icon-warning": WarningFilled,
                "el-icon-more": MoreFilled,
                "el-icon-video-pause": VideoPause,
                "el-icon-video-play": VideoPlay,
                "el-icon-rank": Rank,
                "el-icon-position": Position,
                "el-icon-download": Download,
                "el-icon-camera-solid": CameraFilled,
                "el-icon-error": CircleCloseFilled,
            }

            return iconDict[this.elIconClass]
        },
        loadingClass() {
            if (this.elIconClass === "el-icon-loading") {
                return "is-loading"
            }

            return ""
        },
    }
}
</script>

<style scoped>
</style>