import axios from 'axios';

export default {
    GET_ORG_SETTINGS(orgSettingId) {
        return axios.get(`/api/organisation-settings/${orgSettingId}/`);
    },
    CONNECT_DOCUSIGN() {
        return axios.get('/api/integration/docusign/oauth/')
    },
    AUTHORIZE_DOCUSIGN(code) {
        return axios.get(`/api/integration/docusign/oauth_callback/?code=${code}`)
    },
    DISINTEGRATE_DOCUSIGN() {
        return axios.post(`/api/integration/docusign/disintegrate/`)
    },
    AUTHORIZE_GOOGLE(code, state) {
        return axios.get(`/api/google-connect/google_auth2callback/?state=${state}&code=${code}`)
    },
    GET_GOOGLE_INTEGRATION_INFO() {
        return axios.get('/api/google-connect/get_google_account_status/')
    },
    INTEGRATE_OUTLOOK() {
        return axios.get('/api/microsoft-connect/connect_microsoft_account/')
    },
    INTEGRATE_GOOGLE() {
        return axios.get('/api/google-connect/google_login/')
    },
    AUTHORIZE_OUTLOOK(code) {
        return axios.get(`/api/microsoft-connect/microsoft_callback/?code=${code}`)
    },
    DISINTEGRATE_GOOGLE() {
        return axios.post('/api/google-connect/deintegrate_google_account/')
    },

    GET_GOOGLE_USER_INFO() {
        return axios.get('api/google-connect/get_user_profile/')
    },

    LIST_EMAIL(body) {
        return axios.post(`/api/google-connect/list_emails_from_and_to_user/`, body)
    },

    GET_EMAIL_INFO_BASED_ON_ID(id) {
        return axios.get(`/api/google-connect/get_gmail_email_details/?message_id=${id}`)
    },

    CREATE_EMAIL(body) {
        return axios.post('/api/google-connect/send_email/', body)
    },

    FORWARD_EMAIL(id, body) {
        return axios.post(`/api/google-connect/${id}/forward_email/`, body)
    },

    REPLY_EMAIL(id, body) {
        return axios.post(`/api/google-connect/${id}/reply_email/`, body)
    },

    DELETE_EMAIL(id) {
        return axios.delete(`/api/google-connect/${id}/delete_email/`)
    },


    LIST_EVENTS(body) {
        return axios.post('api/google-connect/list_meetings/', body)
    },

    CREATE_EVENT(body) {
        return axios.post(`/api/google-connect/create_event/`, body)
    },

    EDIT_EVENT(id, body) {
        return axios.put(`/api/google-connect/${id}/edit_event/`, body)
    },

    CANCEL_EVENT(id) {
        return axios.delete(`/api/google-connect/${id}/cancel_event/`)
    },

    CHANGE_EMAIL_STATUS(id, body) {
        return axios.post(`/api/google-connect/${id}/mark_email_status/`, body)
    },

    INTEGRATE_SUNLIGHT(postData) {
        return axios.post(`/api/external-integrations/sunlight/`, postData)
    },
    DISCONNECT_SUNLIGHT() {
        return axios.delete(`/api/external-integrations/sunlight/`)
    },
    INTEGRATE_FINANCIER(postData) {
        return axios.post(`/api/financier/authentication/`, postData)
    },
    DISCONNECT_FINANCIER(postData) {
        console.log('postData', { data: postData })
        return axios.delete(`/api/financier/authentication/`, { data: postData })
    },

}