import { PRODUCTION_ENV, defaultGazeboModuleId, defaultPatioModuleId } from "../constants"
export { PRODUCTION_ENV } from "../constants"

export { AUTOMATION_ENABLED } from "../constants"

export const CAMERA_TYPE_ALL = 'all';
export const CAMERA_TYPE_3D = 'perspective';
export const CAMERA_TYPE_2D = 'orthographic';
export const ORTHO_CAMERA_Z = 500;

export const MAX_DRAWING_POINTS = 500;

export const SUN_REVOLUTION_RADIUS = 200;
export const LOW_SHADOW_MAP_RESOLUTION = 2048;

export const MAX_STRINGING_VERTICES = 1000;

export const OUTEREDGE = 'OuterEdge';
export const INNEREDGE = 'InnerEdge';
export const PV_TILE_MOUNTING_METHODS = {
    ARKA_BLOCK_FOOTING: 'Arka Block Footing',
    ARKA_RAILS: 'Arka Rails',
}
//For api query params
export const MOUNTING_TYPES = {
    BLOCK_FOOT: 'Block_Foot',
    RAILS: 'Rails',
};
export const BLOCK_FOOT_COMPONENT_TYPES = {
    FOOTING: 'Footing',
    VALLEY_GUTTER: 'Valley_Gutter',
    RIDGE_CAP: 'Ridge_Cap',
    HIP_CAP: 'Hip_Cap',
    RIDGE_JUNCTION_CAP: 'Ridge_Junction_Cap',
};
// Sales studio constants
export const IS_DESIGN_SAVED_ON_SALES_MODE = 'sales_mode';
export const IS_DESIGN_SAVED_ON_STUDIO ='design_studio';
export const SALES_MODE = 'salesMode';
export const ROOF_NAME = 'Roof Face ';
export const FLAT_ROOF_NAME = 'Flat Roof ';
export const GROUND_MOUNT_NAME = 'Ground Mount';
export const POLYGON_NAME = 'Polygon ';
export const RECTANGLE_NAME = 'Rectangle ';
export const CYLINDER_NAME = 'Circle ';
export const TREE_NAME = 'Tree ';
export const SUBARRAY_NAME = 'Subarray ';
export const CANVAS_ROOF_NAME= 'R';
export const CANVAS_FLAT_ROOF_NAME= 'F';
export const CANVAS_POLYGON_NAME= 'PO';
export const CANVAS_CYLINDER_NAME= 'CO';
export const CANVAS_RECTANGLE_NAME= 'RO';
export const CANVAS_TREE_NAME= 'TO';
export const CANVAS_GROUND_MOUNT_NAME= 'GM';
export const CANVAS_SUBARRAY_NAME= 'S';

export const UPSCALED_IMAGE_NAME = "UPSCALED IMAGE";
export const MINIMUM_POINTS_FOR_PROPERTY = 3;

export const HYBRID_INVERTER_TYPE = 'Hybrid'
export const POWER_PATIO = 'Patio';
export const POWER_TABLE = 'Power Table';
export const TABLE = 'Table';

export const DEFAULT_GAZEBO_STRUCTURE = 'PGUS01-M1-77-180-24';
export const DEFAULT_GAZEBO_INVERTER_TYPE = 'Enphase IQ7PLUS-72-2-US';
export const DEFAULT_PATIO_INVERTER_TYPE = 'Enphase IQ8PLUS-72-2-US-240';
export const ALL_GAZEBO_INVERTER_TYPE = [
    'Enphase IQ7PLUS-72-2-US',
    'Enphase IQ8PLUS-72-2-US-240',
]
export const ALL_PATIO_INVERTER_TYPE = [
    'Enphase IQ8PLUS-72-2-US-240',
]

export const CUSTOM_INVERTER_PGPP = 'None (Custom)'
export const DEFAULT_GAZEBO_MODULEMAKE = 'Arka Energy Arka_E_77_SP(N)';
export const DEFAULT_GAZEBO_MOUNTHEIGHT = 2.1336;
export const DEFAULT_GAZEBO_TILT = 10;  //6.75
export const GAZEBO_MICROINVERTER_1Q7 = 'IQ7PLUS-72-2-US Enphase';
export const GAZEBO_MICROINVERTER_1Q8 = 'IQ8PLUS-72-2-US-240 Enphase';
export const GAZEBO_MICROINVERTER_1Q7_MAX_EFFICIENCY = 98;
export const GAZEBO_MICROINVERTER_1Q8_MAX_EFFICIENCY = 97.6;

export const POWER_MODEL_HEIGHT = 2.4384;

export const DEFAULT_PATIO_STRUCTURE = 'PPUS02-M1-90-202-28';
export const DEFAULT_PATIO_MODULE_MAKE = 'Arka energy PV SOLAR TILE – 90Wp PV SOLAR TILE – 90Wp';
export const ARKA_MODULE_PROPERTIES_77 = {
    moduleId: defaultGazeboModuleId,
    moduleLength: 1.12,
    moduleMake: DEFAULT_GAZEBO_MODULEMAKE,
    moduleSize: 0.077,
    moduleWidth: 0.465,
};

export const ARKA_MODULE_PROPERTIES_90 = {
    moduleId: defaultPatioModuleId,
    moduleLength: 1.26,
    moduleMake: DEFAULT_PATIO_MODULE_MAKE,
    moduleSize: 0.09,
    moduleWidth: 0.465,
}

export const ARKA_PANEL_PROPERTIES_90 = {
    id: defaultPatioModuleId,
    model: "PV SOLAR TILE – 90Wp PV SOLAR TILE – 90Wp",
    characteristics: {
        length: 1.26,
        width: 0.465,
        p_mp_ref: 90,
        manufacturer: "Arka energy",
        series: "PV SOLAR TILE – 90Wp",
        model: "PV SOLAR TILE – 90Wp",
        cell_number: 13,
        cell_type: "PERC",
        v_max: 600,
        pdf_link: "https://s3.ap-south-1.amazonaws.com/componentpdf/Tile_DS__90_020_PV_TILE__R01.pdf"
    },
    image: null,
    image_link: null
}

export const ARKA_PANEL_PROPERTIES_77 = {
    id: defaultGazeboModuleId,
    model: "Arka_E_77_SP Arka_E_77_SP",
    characteristics: {
        length: 1.12,
        width: 0.465,
        p_mp_ref: 76.32,
        manufacturer: "Arka Energy",
        series: "Arka_E_77_SP",
        model: "Arka_E_77_SP",
        cell_number: 34,
        cell_type: "Monocrystalline",
        v_max: null,
        pdf_link: null
    },
    image: null,
    image_link: ""
}
// Patio Constants
export const PATIO_HEIGHT = 3.098;
export const LEAST_PATIO_WIDTH = 4.55;
export const PATIO_ATTACHMENT_TYPE = 'PatioAttachment';
export const DEFAULT_COLOR_ATTACHMENT_EDGE = 0x383535;
export const HIGHLIGHT_COLOR_ATTACHMENT_EDGE = 0xffff00;
export const COMBINER_BOX_ATTACHMENT = 'CombinerBoxAttachment';

export const PANEL_ORIENTATION_LANDSCAPE = 'Landscape';
export const PANEL_ORIENTATION_PORTRAIT = 'Portrait';
export const SUBARRAY_RACK_STYLE_FIXED = 'Fixed Tilt';
export const SUBARRAY_RACK_STYLE_FLUSH = 'Tilted Mount';
export const SUBARRAY_RACK_STYLE_SINGLE_AXIS = 'Single Axis Tracker';
export const SUBARRAY_STRUCTURE_DUAL_AXIS = 'Dual Axis Tracker';
export const SUBARRAY_RACK_STYLE_EWRACKING = 'East West Racking';
export const ARKA_PERGOLAS_TILT_VALUES = ['PGIN-01S1-250-D01', 'PGUS01-01M1-77-4.8', 'PGUS01-01M1-77-5.8'];
export const STRUCTURE_TYPES = ['Default Fixed Tilt', 'Pergola',
    'Low Foundation Fixed Tilt', 'Four MMS One Leg', 'Four MMS Two Leg',
    'Ballast Type 1', 'Ballast Type 2', 'Ballast Type 3',
    'General Ballast', 'UNIRAC RM 5', 'UNIRAC RM 10', 'Ground Mount MMS',
    'Elevated MMS', 'Fixed Tilt 2500mm'
];
export const GAZEBO_OBJECT = 'Gazebo';
export const PATIO_OBJECT = 'Patio';
export const SUBARRAY_OBJECT = 'PV';
export const ADD_EAST_TABLE = 'East Table';
export const ADD_WEST_TABLE = 'West Table';
export const ADD_EW_TABLE = 'East West Table';
export const ROW_SPACING_MODE_AUTO = 'Auto';
export const ROW_SPACING_MODE_MANUAL = 'Manual';
export const TABLE_SPACING_MODE_AUTO = 'Auto';
export const TABLE_SPACING_MODE_MANUAL = 'Manual';
export const TILT_LOCKED = 'tilt';
export const RAFTER_ORIENTATION_PARALLEL = 'Parallel';
export const RAFTER_ORIENTATION_PERPENDICULAR = 'Perpendicular';
export const ATTACHMENT_ORIENTATION_PARALLEL = 'Parallel';
export const ATTACHMENT_RADIUS = 0.0758;
export const ATTACHMENT_ORIENTATION_STAGGERED = 'Staggered';
export const ATTACHMENT_ALIGNMENT = 'attachmentAlignment';
export const RAFTER_ALIGNMENT = 'rafterAlignment';
export const TOP_HEIGHT_LOCKED = 'topHeight';
export const CORE_HEIGHT_LOCKED = 'coreHeight';
export const EDIT_SETBACK_INSIDE = 'setbackInside';
export const EDIT_SETBACK_OUTSIDE = 'setbackOutside';
export const OBSTRUCTION_TYPES = ['Chimney,Skylight,AC Unit'];
export const ACCABLE_MATERIAL_TYPE_COPPER = 'copper';
export const ACCABLE_MATERIAL_TYPE_ALUMINIUM = 'aluminium';
export const ACCABLE_SIZE_AWG = ['0000', '000', '00', '0', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26];
export const ACCABLE_SIZE_MM = [1.5, 2.5, 4, 6, 10, 16, 25, 35, 50, 70, 95, 120, 150, 185, 240, 300, 400, 500, 630, 800, 1000];
export const MANUAL_ACCABLE_SIZE_MMSQ = [1.5, 2.5, 4, 6, 10, 16, 25, 35, 50, 70, 95, 120, 150, 185, 240, 300, 400, 500, 630, 800, 1000];
export const CABLE_TYPE = ['Copper', 'Aluminium'];
export const MANUAL_STRING_AWG = [17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, '0 (1/0)', '00 (2/0)', '000 (3/0)', '0000 (4/0)', '250MCM', '300MCM', '350MCM', '400MCM', '450MCM', '500MCM', '550MCM', '600MCM', '650MCM', '700MCM', '750MCM', '800MCM', '900MCM', '1000MCM', '1100MCM'];
export const SNAP_RADIUS = 3;
export const NO_OF_CELLS = 128;
export const SOLAR_API_LAYERS = {
    DSM: 'DSM',
    MASK: 'MASK',
    FLUX: 'FLUX',
    RGB: 'RGB',
};


export const CYLINDER_SEGMENTS = 32;
export const CYLINDER_PLUS_SIZE_PERCENT = 10;
export const TREE_SEGMENTS = 32;
export const BLOCK_UTILS_CYLINDER_SEGMENTS = 12;

export const BASE_URL = PRODUCTION_ENV ? '13.234.123.80' :
    'https://betaapi.thesolarlabs.com:5000/';

export const CONVERTER_URL = 'designs/dae-to-3ds';
export const DESIGN_MODE_URL_FLAGS = ['studio', 'salesStudio'];
export const INDEXED_DB_MESH = 'MeshDB';
export const INDEXED_DB_MESH_STORE = 'meshes';
// Experimental feature, flag to enable/disable local meshes
export const ENABLE_LOCAL_MESHES = true;

export const COMPLEX_GEOMETRY_ERROR = 'Complex geometries not supported';
export const VERTEX_EQUIVALENT_ERROR =
    'New vertex is same as another placed vertex';
export const OUT_OF_GROUND_ERROR = 'Model is outside ground';
export const DRAWING_POINT_INSIDE_PATIO_SETBACK_AREA_ERROR = 'Restricted area for drawing inside power model setback.';
export const OUT_OF_BASE_MODEL_ERROR = 'Model is outside roof.';
export const MODEL_INTERSECTION_WITH_OTHER_MODELS = 'Model is intersecting with other models.';
export const TABLE_OUT_OF_GROUND_ERROR = 'Table is outside ground';
export const OUT_OF_ASSOCIATED_MODEL_ERROR = 'Table outside the roof. Table deleted';
export const OUT_OF_POLYGON_MODEL = 'Cannot place power model on the edge of the polygon';
export const OUT_OF_POLYGON_EDGE = 'PowerPatio cannot be placed outside the edges.';
export const POWERMODEL_INTERSECTS_WITH_OTHER_MODELS = 'PowerModel cannot be placed as it intersects other model.';
export const PATIO_WITHOUT_SETBACKS = 'Patio boundary exceeds placeable area.';
export const POWERMODEL_INTERSECTS_WITH_PROPERTY_LINE = 'PowerModel cannot be placed as it overlaps with property line.';
export const POWERMODEL_OUTSIDE_OF_EDGE = 'PowerPatio must be connected to a building. It cannot be moved away.';
export const MINIMUM_DISTANCE_FOR_JOINED_PATIOS_WARNING = 'Distance between two patios cannot be less than 3 Feet (0.914 meters)';
export const METRIC_THREE_FEET = 0.9144;
export const COMBINATION_IS_NOT_POSSIBLE_ERROR = 'This combination of azimuth and tilt is not possible on this Surface. Cannot place.';
export const POWER_MODEL_CANT_BE_PALCED_ON_TILTED_SURFACE = 'Power model can\' be placed on tilted surface';
export const PARENT_WITHIN_CHILD_SO_NO_SETBACK_OUTSIDE_ERROR =
    'ParentWithinChildSoNoSetbackOutsidePossibleError';
export const LESS_VERTICES_THAN_NEEDED_FOR_PLACING_ERROR = 'Need more vertices to complete object';
export const LESS_VERTICES_THAN_NEEDED_FOR_DRAWING_ERROR =
    'Less vertices than needed for drawing object';
export const LAST_EDGE_INTERSECTION_ERROR = 'Intersecting models are not supported';
export const POLYGON_WITH_NO_AREA_ERROR = 'Polygon has no area';
export const CYLINDER_WITH_NO_AREA_ERROR = 'Cylinder has no area';
export const TREE_WITH_NO_AREA_ERROR = 'Tree has no area';
export const INVERTER_WITH_NO_AREA_ERROR = 'Inverter has no area';
export const ACDB_WITH_NO_AREA_ERROR = 'ACDB has no area';
export const DCDB_WITH_NO_AREA_ERROR = 'DCDB has no area';
export const INTERSECTING_AC_COMPONENT_ERROR = 'Intersecting model placement are not allowed';
export const DORMER_INVALID_PARENT_ERROR = 'Dormer can only be placed on Pitched Roof';
export const DIMENSION_INVALID_ERROR = 'Dimension is not valid';
export const VERTEX_OVER_EDGE_ERROR = 'Vertex is over an edge';
export const INSUFFICIENT_VERTICES = "Insufficient number of vertices";
export const TREE_WITH_SMALL_CROWN_ERROR = 'Tree has small crown than trunk';
export const INVALID_PROPERTIES_ERROR = 'Cannot complete with invalid properties';
export const INVALID_PROPERTIES_FOR_TABLE_ERROR = 'Cannot add table with invalid properties';
export const INVALID_TILT_ERROR = 'Tilt is invalid';
export const INVALID_TOP_HEIGHT_ERROR = 'Top height is invalid';
export const INVALID_CORE_HEIGHT_ERROR = 'Core height is invalid';
export const TABLE_TILT_LOWER_THAN_PARENT_TILT_ERROR = 'Table tilt is lower than roof tilt';
export const PASTE_TABLE_WITHOUT_SUBARRAY_ERROR =
    'Cannot paste table(s) since subarray does not exist in the design';
export const PASTE_TABLE_DATA_DIFFERENT_TO_SUBARRAY_DATA = "Copied module data is different than the Subarray data, Please try again."
export const DC_CAP_REACHED_ERROR = 'Cannot complete operation properly because you have reached the maximum allowed DC size';
export const PEN_TOOL_GOOGLE_3D_MESSAGE = "Pen Tool works best when Google 3D is ON. Would you like to turn on Google 3D?"
export const AI_WARNING_MESSAGE = "AI auto roof detection works best if there are no existing designs. Do you want to remove existing design and move ahead?"

export const SCENE_UPDATED = 'scene-updated';
export const CAMERA_UPDATED = 'camera-updated';
export const ENABLE_TEXT_SELECTION = 'enable-text-selection';
export const DISABLE_TEXT_SELECTION = 'disable-text-selection';

export const CREATED_STATE = 'created-state';
export const DELETED_STATE = 'deleted-state';
export const SAVED_STATE = 'saved-state';

export const TEMP_STACK_USED_BY_DRAW_MANAGER = 'temp-stack-used-by-draw-manager';
export const TEMP_STACK_USED_BY_EDIT_MODE = 'temp-stack-used-by-edit-mode';

export const CLIPBOARD_READ_ACCESS = 'clipboard-read';
export const CLIPBOARD_WRITE_ACCESS = 'clipboard-write';

export const DEFAULT_WALKWAY_DIRECTION = 'default-walkway-direction';
export const ALTERNATE_WALKWAY_DIRECTION = 'alternate-walkway-direction';

export const DEFAULT_HANDRAIL_DIRECTION = 'default-handrail-direction';
export const ALTERNATE_HANDRAIL_DIRECTION = 'alternate-handrail-direction';

export const DEFAULT_VERTICES_DIRECTION = 'default-direction';
export const ALTERNATE_VERTICES_DIRECTION = 'alternate-direction';

export const COORDINATE_CLOSENESS_PRECISION = 0.007;

export const PANEL_TYPE_POLYCRYSTALLINE = 'Polycrystalline';
export const PANEL_TYPE_MONOCRYSTALLINE = 'Monocrystalline';
export const HIGH_PANEL_COVERAGE_AREA = 33;

// TODO: Temp fix. Needs rework.
export const DYNAMIC_OFFSET_ITERATION_STEPS = {
    5000: 200,
    10000: 40,
    Infinity: 15,
};

export const SHADOW_VIEW_SUMMER_SOLSTICE = 'Summer';
export const SHADOW_VIEW_WINTER_SOLSTICE = 'Winter';
export const SHADOW_VIEW_MORNING_TIME = 'Morning';
export const SHADOW_VIEW_EVENING_TIME = 'Evening';

export const QUOTA_TYPES_DC_CAP_SIZE = {
    SMALL: 25,
    MEDIUM: 200,
    LARGE: Math.Infinity,
};

export const CROSSHAIR = "url('data:image/x-icon;base64,AAACAAEAICACAA8AEAAwAQAAFgAAACgAAAAgAAAAQAAAAAEAAQAAAAAAgAAAAAAAAAAAAAAAAgAAAAAAAAAAAAAA////AAAAAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAAAAAAAAAAAAAAAAH/wH/wAAAAAAAAAAAAAAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAAAAAAAAAAA//x////8f////H////x////8f////H////x////8f////H////x////8f////H////x///////8ABEABAAVAAQAEQAH///////x////8f////H////x////8f////H////x////8f////H////x////8f////H////x///////8='), auto";
export const TIERS_WITH_ALL_TIERS_DIFF = {
    T1:25,
    T2:200,
    T3:500,
    T4:5000
};
export const TIERS_WITH_ALL_TIERS_NOT_DIFF = {
    T1:25,
    T2:200,
    T3:Math.Infinity,
    T4:Math.Infinity,
};
export const INVALID_SCALE = 0;
// View Manager Constants
export const POLYGON_EDGE_CENTER_VISIBILTY_DEFAULT = false;
export const BARE_DECK_VISIBILTY_DEFAULT = true;
export const EDGE_LENGTH_VISIBILTY_DEFAULT = true;
export const ARC_VISIBILTY_DEFAULT = false;
export const RAFTER_VISIBILTY_DEFAULT = true;
export const PROPERTY_VISIBILTY_DEFAULT = true;
export const DIMENSION_VISIBILTY_DEFAULT = true;
export const SETBACK_VISIBILTY_DEFAULT = true;
export const MAPIMAGE_VISIBILTY_DEFAULT = true;
export const STRINGING_VISIBILTY_DEFAULT = false;
export const ROOF_MARKER_VISIBILTY_DEFAULT = true;

// UPTO 32 camera layers each for 32 different object types
export const VISIBILITY_LAYERS = {
    DEFAULT_LAYERS: 0,
    POLYGON_EDGE_CENTER: 1,
    BARE_DECK: 2,
    EDGE_LENGTH: 3,
    ARC: 4,
    RAFTER: 5,
    PROPERTY: 6,
    DIMENSION: 7,
    SETBACK: 8,
    MAPIMAGE: 9,
    STRINGING: 10,
}

// Layers Constant for design dxf export
export const PANEL_COLOR = 5;
export const OBSTACLES_COLOR = 31;
export const ROOF_COLOR = 4;
export const SHADOW_COLOR = 5;
export const HANDRAIL_COLOR = 16;
export const STRING_COLOR = 0;
export const FRAME_THICKNESS = 0.05;
export const ROOF_THICKNESS = 0.3;
export const STRING_THICKNESS = 0.05;
export const WALKWAY_COLOR = 1;
export const SAFETYLINE_COLOR = 21;
export const AC_CABLE_COLOR = 136;
export const DC_STRING_COLOR = 200;
export const RAFTERS_COLOR = 104;
export const RAILS_COLOR = 35;
export const MICROINVERTERS_COLOR = 2;
export const OPTIMIZERS_COLOR = 2;
export const SETBACK_COLOR = 4;
export const PROPERTY_COLOR = 160;
export const PROPERTY_DIMENSION_COLOR = 10;
export const ATTACHMENT_COLOR = 13;

export const SETBACKDROPDOWNLIST = [
    '0 INCHES',
    '18 INCHES',
    '36  INCHES',
];
export const ZERO_INCH_SETBACK = 0;
export const EIGHTEEN_INCH_SETBACK = 18 * 0.0254;
export const THIRTYSIX_INCH_SETBACK = 36 * 0.0254;
export const fancyConsoleMessage = '%c Welcome To Design Studio';
export const fancyConsoleProperty = 'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)';

export const MOUSE_EVENT = 'mouse Event';
export const TOUCH_EVENT = 'touch Event';

export const EDGE_PRECISION = 3;

export const PENTOOL_ROOF_FACE = "PenToolRoofFace";

export const SETBACK_LAYER = 6;