<template>
    <span style="width: 100%;">
            <input
                v-model="displayValue.text"
                :type="inputType"
                :name="name"
                :disabled="disabled"
                :placeholder="holder"
                :class="classInput"
                :ref="name"
                class="validatable"
                :data-rules="JSON.stringify(inputValidation)"
                :data-vv-scope="errorScope !== null ? errorScope : null"
                autocomplete="off"
                @keyup.enter="enter"
                @input="onDisplayValueUpdate(); $emit('inputChanged')">
    </span>
</template>

<script>
import _ from 'lodash';
import {
    parseImperialMeasurement,
    stringifyMetricMeasurement,
    stringifyImperialMeasurement,
    convertMetricToImperial,
    convertImperialToMetric,
    convertMeterSqtToSqtFeet,
} from './utils';
import { FOOT_INCHES_VALIDATION_REGEX } from './constants';
import { mapState } from 'pinia';
import { useDesignStore } from '../../../stores/design';

const EPSILON = 10e-5;

export default {
    emits: [ "enterPressed", "update:modelValue", "inputChanged"],
    name: 'InputLength',
    props: {
        modelValue: {
            type: [Number, String],
            default: NaN,
        },
        name: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false, 
        },
        classInput: {
            type: String,
            default: '',
        },
        holder:{
            type: String,
            default: ""
        },
        convertValue: {
            type: Boolean,
            default: false,
        },
        metricValidation: {
            type: Object,
            default() {
                return {
                    required: true,
                    min_value: 0.001,
                    decimal: 3,
                };
            },
        },
        errorViewAncestor: {
            type: Object,
            default: null,
        },
        errorScope: {
            type: String,
            default: null,
        },
        setBackVal: {
            type: Boolean,
            default: false,
        },
        inputType: {
            type: String,
            default: "",
        },
        maxCharacterLimit: {
            type: Number,
            default: 0,
        },
    },
    data() {
        const convertToSqtFeet = this.convertValue;
        return {
            enterPressed: false,
            metricValue: {
                meters: NaN,
                toString() {
                    if (convertToSqtFeet) {
                        return stringifyMetricMeasurement(this.meters, { isMsqt: true });
                    }
                    return stringifyMetricMeasurement(this.meters);
                },
                set(meters) {
                    this.meters = meters;
                },
                get() {
                    return this.meters;
                },
                toImperialValue() {
                    if (convertToSqtFeet) {
                        return convertMeterSqtToSqtFeet(this.meters);
                    }
                    return convertMetricToImperial(this.meters);
                },
            },
            imperialValue: {
                feet: NaN,
                inches: NaN,
                toString() {
                    return stringifyImperialMeasurement(this.feet, this.inches);
                },
                set(feet, inches) {
                    this.feet = feet;
                    this.inches = inches;
                },
                toMetricValue() {
                    return convertImperialToMetric([this.feet, this.inches]);
                },
            },
            displayValue: {
                text: null,
                toMetricValue() {
                    return parseFloat(this.text);
                },
                toImperialValue() {
                    return parseImperialMeasurement(this.text);
                },
                set(text) {
                    this.text = text;
                },
            },
        };
    },
    computed: {
        ...mapState(useDesignStore, {
            metricSystem: 'IS_DESIGN_MEASUREMENT_SYSTEM_METRIC',
        }),
        inputValidation() {
            if (!(this.convertValue)) {
                return this.metricSystem ? this.metricValidation : this.imperialValidation;
            }
            else {
                return true;
            }
        },
        imperialValidation() {
            if (!(this.convertValue)) {
                return {
                    regex: FOOT_INCHES_VALIDATION_REGEX,
                    required: this.metricValidation.required,
                };
            }
            else {
                return {
                    required: this.metricValidation.required,
                };
            }       
        },
        isMetricValuePropNotEmptyNullAndUndefined() {
            return this.modelValue !== null && this.modelValue !== undefined && this.modelValue !== '';
        },
        errorViewAncestorLocal() {
            return this.errorViewAncestor || this.$parent;
        },
    },
    watch: {
        modelValue: {
            handler: 'onMetricValuePropUpdate',
            immediate: true,
        },
        metricSystem: {
            handler() {
                this.updateDisplayValue();
            },
        },
        'metricValidation.required': {
            handler() {
                this.handleESHValue();
            },
        },
        'errorViewAncestorLocal.errors': {
            handler(curValue, prevValue) {
                if (
                    this.errorViewAncestorLocal.errors?.length ||
                    curValue[this.name] == prevValue[this.name]
                ) {
                    return
                }

                this.onDisplayValueUpdate();
            },
        },
    },
    mounted() {
        this.onMetricValuePropUpdate(this.modelValue);
    },
    methods: {
        enter(){
            this.$emit("enterPressed",this.enterPressed = true);
        },
        onMetricValuePropUpdate(modelValue) {
            if (modelValue === 'custom') {
                this.displayValue.set('custom');
                return;
            }
            if (!Number.isNaN(modelValue) && this.isMetricValuePropNotEmptyNullAndUndefined) {
                if (Number.isNaN(this.metricValue.get())) {
                    this.updateMetricValue(modelValue);
                    this.updateDisplayValue();
                }
                else if (Math.abs(this.metricValue.get() - modelValue) > EPSILON) {
                    this.updateMetricValue(modelValue);
                    this.updateDisplayValue();
                }
            }
        },
        updateMetricValue(meters) {
            this.metricValue.set(meters);
            if (!this.convertValue) {
                this.imperialValue.set(...this.metricValue.toImperialValue());
            }
            else {
                this.imperialValue.set(this.metricValue.toImperialValue());
            }
        },
        updateImperialValue(feet, inches) {
            this.imperialValue.set(feet, inches);
            this.metricValue.set(this.imperialValue.toMetricValue());
        },
        async handleESHValue() {
            const isMetricValueNullUndefinedEmptyOrNaN = this.modelValue === '' ||
                Number.isNaN(this.modelValue) || this.modelValue === undefined ||
                this.modelValue === null;
            if (isMetricValueNullUndefinedEmptyOrNaN) {
                this.displayValue.set('');
                await this.$nextTick();
                await this.updateErrors();
                if (this.metricSystem) {
                    this.updateMetricValue(NaN);
                }
                else {
                    this.updateImperialValue(NaN, NaN);
                }
                this.$emit('update:modelValue', NaN);
            }
        },
        async updateDisplayValue() {
            if (!Number.isNaN(this.modelValue) && this.isMetricValuePropNotEmptyNullAndUndefined &&
                this.modelValue !== "custom") {
                this.displayValue.set(this.metricSystem ?
                    this.metricValue.toString() : this.imperialValue.toString());
                await this.$nextTick();
                await this.updateErrors();
            }
        },
        async updateErrors() {
            this.$isAllInputsValidOnSubmit(this.errors)
        },
        async onDisplayValueUpdate() {
            if(this.maxCharacterLimit && this.displayValue.text.length > this.maxCharacterLimit){
                this.displayValue.text = this.displayValue.text.slice(0, this.maxCharacterLimit);
            }
            if (this.setBackVal) {
                if (this.displayValue.text === 'custom') {
                    this.$emit('update:modelValue', 'custom');
                    delete this.errorViewAncestorLocal.errors[this.name]
                    return;
                }
            }
            await this.updateErrors();
            if (this.metricSystem) {
                if (
                    stringifyMetricMeasurement(this.displayValue.toMetricValue()) !==
                    this.metricValue.toString()
                ) {
                    this.updateMetricValue(this.displayValue.toMetricValue());
                    delete this.errorViewAncestorLocal.errors[this.name]
                } else if (Object.keys(this.errorViewAncestorLocal.errors).length > 0) {
                    this.updateMetricValue(NaN);
                }
                this.errorViewAncestorLocal.errors = this.errors
            }
            else {
                const displayImperialValue = this.displayValue.toImperialValue();
                // manually handling less than 0.03 inches
                if (this.metricValidation.min_value > 0) {
                    const [feet, inches] = [...displayImperialValue];
                    if (!FOOT_INCHES_VALIDATION_REGEX.test(this.displayValue.text)) {
                        this.errorViewAncestorLocal.errors[this.name] = "Please enter the length in x ft y in format"
                    } else if (feet === 0 && inches <= 0.03) {
                        this.errorViewAncestorLocal.errors[this.name] = `${this.name} field can't be less than 0.04 inches`
                    } else {
                        delete this.errorViewAncestorLocal.errors[this.name]
                    }
                }
                if (Object.keys(this.errorViewAncestorLocal.errors).length > 0) {
                    this.updateImperialValue(NaN, NaN);
                }
                else if (
                    stringifyImperialMeasurement(...displayImperialValue) !==
                    this.imperialValue.toString()
                ) {
                    this.updateImperialValue(...displayImperialValue);
                    
                }
            }
            this.updateParent();
        },
        updateParent() {
            if (Object.keys(this.errorViewAncestorLocal.errors).length > 0) {
                this.$emit('update:modelValue', NaN);
            }
            else {
                this.$emit('update:modelValue', _.round(this.metricValue.get(), 5));
            }
        },
    },
};
</script>


<style lang="scss" scoped>
    @import '../../../styles/components/input';
    @import '../../../styles/components/button';
</style>