import Subarray from "../../objects/subArray/Subarray";
import Row from "../../objects/subArray/Row";
import Table from "../../objects/subArray/Table";
import OutlinePoints from "../../objects/subObjects/OutlinePoints";
import * as utils from '../../../core/utils/utils';
import * as THREE from 'three';


export default class GroundMount extends  Subarray {
    constructor(stage) {
        super(stage);
        this.objectType = 'GroundMount';
        this.allInstancedMeshes = [];
        this.canvasName = '';
    }
    // TODO:save object creations and change the type
    saveObject() {
        const groundMountData = super.saveObject();
        groundMountData.type = GroundMount.getObjectType();
        groundMountData.salesModeProperties = this.salesModeProperties;
        return groundMountData;
    }
    
    loadObject(subarrayData, parentModel, isPaste = false) {
           // load id and name
           if(!subarrayData.rackParent && subarrayData.eastWestRackingEnabled) return;
           if (!isPaste) {
               this.id = subarrayData.id;
               this.name = subarrayData.name;
           }
   
           this.addTableFlow = subarrayData.addTableFlow;
           // load subarray properties
           this.moduleProperties = {
               moduleId: subarrayData.moduleProperties.moduleId,
               moduleMake: subarrayData.moduleProperties.moduleMake,
               moduleSize: subarrayData.moduleProperties.moduleSize,
               moduleLength: subarrayData.moduleProperties.moduleLength,
               moduleWidth: subarrayData.moduleProperties.moduleWidth,
           };
           this.panelProperties = subarrayData.panelProperties;
           this.rowSpacing = subarrayData.rowSpacing;
           this.rowSpacingMode = subarrayData.rowSpacingMode;
           this.tilt = subarrayData.tilt;
           this.structureType = subarrayData.structureType;
           this.azimuth = subarrayData.azimuth;
           this.axisTilt = subarrayData.axisTilt ? subarrayData.axisTilt : 0;
           this.axisAzimuth = (subarrayData.axisAzimuth!==null && subarrayData.axisAzimuth!==undefined) ? subarrayData.axisAzimuth : 180;
           //for dualaxis structure
           this.axisMinTilt = (subarrayData.axisMinTilt!==null && subarrayData.axisMinTilt!==undefined) ? subarrayData.axisMinTilt : -45;
           this.axisMaxTilt = (subarrayData.axisMaxTilt!==null && subarrayData.axisMaxTilt!==undefined) ? subarrayData.axisMaxTilt : 45;
           this.axisMinAzimuth = (subarrayData.axisMinAzimuth!==null && subarrayData.axisMinAzimuth!==undefined) ? subarrayData.axisMinAzimuth : -90;
           this.axisMaxAzimuth = (subarrayData.axisMaxAzimuth!==null && subarrayData.axisMaxAzimuth!==undefined) ? subarrayData.axisMaxAzimuth : 90;
           this.maxAxisRotation = subarrayData.maxAxisRotation;
           this.panelOrientation = subarrayData.panelOrientation;
           this.mountHeight = subarrayData.mountHeight;
           this.tableSizeUp = subarrayData.tableSizeUp;
           this.tableSizeWide = subarrayData.tableSizeWide;
           this.tableSpacing = subarrayData.tableSpacing;
           this.tableSpacingMode = (subarrayData.tableSpacingMode!==null && subarrayData.tableSpacingMode!==undefined) ? subarrayData.tableSpacingMode : TABLE_SPACING_MODE_MANUAL;
           this.moduleSpacingUp = subarrayData.moduleSpacingUp;
           this.moduleSpacingWide = subarrayData.moduleSpacingWide;
           this.mountType = subarrayData.mountType;
           this.bifacialEnabled = subarrayData.bifacialEnabled;
           this.eastWestRackingEnabled = subarrayData.eastWestRackingEnabled;
           this.rackParent = subarrayData.rackParent;
           this.interRowSpacing = subarrayData.interRowSpacing;
           this.intraRowSpacing = subarrayData.intraRowSpacing;
           if (subarrayData.salesModeProperties) this.salesModeProperties = subarrayData.salesModeProperties;
           // this.linkedSubarray = subarrayData.linkedSubarray;
           if (subarrayData.inverterLerpPosition !== undefined) {
               this.inverterLerpPosition = subarrayData.inverterLerpPosition;
           }
   
           // load subarray outline vertices (points)
           if (parentModel !== null) {
               this.associatedModel = parentModel;
               this.associatedModel.addChild(this);
           }
   
           if (subarrayData.inverterIds !== undefined) {
               this.inverterIds = subarrayData.inverterIds;
           }
           // load inverters accoding to this..
           // if (subarrayData.inverterIds !== undefined) {
           //     for (let i = 0, l = subarrayData.inverterIds.length; i < l; i += 1) {
           //         this.inverterIds.push(subarrayData.inverterIds);
           //     }
           // }
   
           // TODO: Identify the real cause
           if (subarrayData.outlinePoints.length === 0) {
               if (subarrayData.subarrayMap.rows.length === 0) {
                   this.removeObject();
                   return;
               }
               let coordinatePoints = [];
               for (let row of subarrayData.subarrayMap.rows) {
                   for (let table of row.frames) {
                       for (let panel of table.panels) {
                           for (let corner of panel.corners) {
                               coordinatePoints.push(new JSTS.geom.Coordinate(corner[0], corner[1]));
                           }
                       }
                   }
               }
               let convexHullCoordinates = new JSTS.geom.GeometryFactory().createMultiPointFromCoords(coordinatePoints).convexHull().getCoordinates().slice(0, -1);
               subarrayData.outlinePoints = convexHullCoordinates.map(coordinate => [coordinate.x, coordinate.y, 0]);
           }
   
           // set outline points
           for (let outlinePoint of subarrayData.outlinePoints) {
               this.outlinePoints.push(
                   new OutlinePoints(
                       outlinePoint[0],
                       outlinePoint[1],
                       outlinePoint[2],
                       this,
                       this.stage
                   )
               );
           }
           // update geometry
           this.updateGeometry();

   
           if (isPaste) {
               // Jugaad FIX NEED TO BE REMOVED AFTER EW REWORK
               // Normal Subarray Flow 
               if (!this.eastWestRackingEnabled) {
                   // load subarray map
                   this.makeSubarrayWithPanelProperties(subarrayData.subarrayMap, { withoutContainer: false }, isPaste);
                   this.saveState({ withoutContainer: false });
               }
               else {
                   // manuplating the subarray map to create row blocks
                   subarrayData.subarrayMap = this.createRowBlocksInSubarrayMap(subarrayData.subarrayMap);
                   this.makeSubarrayWithPanelProperties(subarrayData.subarrayMap, { withoutContainer: true }, isPaste);
                   // if(this.rackParent){
                   //     this.linkedSubarrayID = subarrayData.linkedSubarrayID;
                   //     if(!this.linkedSubarray){
                   //         this.populateSubarrayForEastWestRacking(true, this.linkedSubarrayID);
                   //         this.linkedSubarray.saveState({ withoutContainer: true });
                   //     }
                   // }
                   this.saveState({ withoutContainer: true });                
               }
           }
           else {
               // manuplating the subarray map to create row blocks
               subarrayData.subarrayMap = this.createRowBlocksInSubarrayMap(subarrayData.subarrayMap);
               this.makeSubarrayWithPanelProperties(subarrayData.subarrayMap, { withoutContainer: true }, isPaste);
               // if(this.eastWestRackingEnabled && this.rackParent){
               //     this.linkedSubarrayID = subarrayData.linkedSubarrayID;
               //     if(!this.linkedSubarray){
               //         this.populateSubarrayForEastWestRacking(true, this.linkedSubarrayID);
               //         this.linkedSubarray.saveState({ withoutContainer: true });
               //     }
               // }
               this.saveState({ withoutContainer: true });
           }
   
           this.validateStructures();
           this.ensureValidSubarrayDCSize();
    }

    getState(){
        const subarrayData = super.getState();
        if (this.canvasName) subarrayData.canvasName= this.canvasName;
        return subarrayData;
    }

    
    loadState(state, fromState) {
        super.loadState(state, fromState);
        if (state.canvasName) this.canvasName = state.canvasName;
    }
    
    createNewModel(properties) {
        const newGroundMount = new GroundMount(this.stage);
        this.getParent().addChild(newGroundMount);
        newGroundMount.associatedModel = this.getParent();
        newGroundMount.addTableFlow = true;
        if (properties !== null) {
            newGroundMount.changePropertiesDuringCreation(properties);
            newGroundMount.createBoundaryFromParent();
        }

        const rowMap = {
            id: 0,
            frames: [],
        };
        let row = new Row(this.stage, rowMap, { withoutContainer: false });
        newGroundMount.addChild(row);
        row.saveState({ withoutContainer: false });
        return newGroundMount;
    }

    createNewGroundMountTable(properties) {
        const newGroundMount = this.createNewModel(properties);
        const templateTableMap = newGroundMount.getTemplateTableMap({ withBBox: true });
        templateTableMap.hidden = false;
        templateTableMap.isMoved = true;
        let newTable;
      
        newTable = new Table(this.stage, templateTableMap, { withoutContainer: false }, false);
        newTable.showIndividualMesh();
        newTable.clickToAdd = true;
        newGroundMount.getChildren()[0].addChild(newTable);
        // updating the panels id for the gazebo as the table
        // was added without updating the ids of panels
        const panels = newTable.getChildren();
        for (let i = 0, l = panels.length; i < l; i += 1) {
            panels[i].setId(newGroundMount.getPanelId());
        }
        return newGroundMount;
    }

    
    createBoundaryFromBB() {
        const vertices = this.getChildren()[0].get3DBoundingBoxesExcludingHiddenTables();
        if (vertices[0]) {
            if (vertices[0].length > 0 && vertices[0].length === 4) {
                // remove subarray outlinePoints
                for (let i = this.outlinePoints.length - 1; i >= 0; i--) {
                    this.outlinePoints[i].removeObject();
                    this.outlinePoints.splice(i,1);
                }
                // adding new outlinePoints for the gazebo
                for (let i = 0, len = vertices[0].length; i < len; i += 1) {
                    this.outlinePoints.push(new OutlinePoints(
                        vertices[0][i].x,
                        vertices[0][i].y,
                        0,
                        this,
                        this.stage,
                    ));
                }
            }
        }
        else {
            let outlinePoints = [];
            const tablePosition = this.getTables()[0].getPosition();
            const tableDimension = this.getTableDimensions();

            outlinePoints.push([
                tablePosition.x + (tableDimension.width / 2) - 0.01,
                tablePosition.y + (tableDimension.length / 2 - 0.01),
                tablePosition.z,
            ])

            outlinePoints.push([
                tablePosition.x - (tableDimension.width / 2),
                tablePosition.y + (tableDimension.length / 2),
                tablePosition.z,
            ])

            outlinePoints.push([
                tablePosition.x + (tableDimension.width / 2),
                tablePosition.y - (tableDimension.length / 2),
                tablePosition.z,
            ])

            outlinePoints.push([
                tablePosition.x - (tableDimension.width / 2),
                tablePosition.y - (tableDimension.length / 2),
                tablePosition.z,
            ])

            let points = [];

            outlinePoints.forEach((outline) => {
                points.push(new THREE.Vector3(outline[0], outline[1], 40))
            })

            let newPoints = [];

            if (newPoints.length > 0 && newPoints.length === 4) {
                for (let i = this.outlinePoints.length - 1; i >= 0; i--) {
                    this.outlinePoints[i].removeObject();
                    this.outlinePoints.splice(i,1);
                }
                // adding new outlinePoints for the gazebo
                for (let i = 0, len = newPoints.length; i < len; i += 1) {
                    this.outlinePoints.push(new OutlinePoints(
                        newPoints[i][0],
                        newPoints[i][1],
                        0,
                        this,
                        this.stage,
                    ));
                }
            }
        }
    }

    updateObject(properties) {
        const newProperties = this.saveObject();
        let updatePanelsRequired = false;
        if (Object.prototype.hasOwnProperty.call(properties, 'azimuth') &&
        (properties.azimuth !== newProperties.azimuth)) {
            newProperties.azimuth = properties.azimuth;
            updatePanelsRequired = true;
        }
        if (properties.hasOwnProperty('name')
            && properties.name !== newProperties.name) {
            newProperties.name = properties.name;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'salesModeName') &&
        properties.salesModeName !== newProperties.salesModeProperties.name) {
            newProperties.salesModeProperties.name = properties.salesModeName;
            newProperties.salesModeProperties.updated = true;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'tilt') &&
        (properties.tilt !== newProperties.tilt)) {
            newProperties.tilt = properties.tilt;
            updatePanelsRequired = true;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'mountHeight') &&
        (properties.mountHeight !== newProperties.mountHeight)) {
            newProperties.mountHeight = properties.mountHeight;
            updatePanelsRequired = true;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'tableSizeUp') &&
        (properties.tableSizeUp !== newProperties.tableSizeUp)) {
            newProperties.tableSizeUp = properties.tableSizeUp;
            updatePanelsRequired = true;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'tableSizeWide') &&
        (properties.tableSizeWide !== newProperties.tableSizeWide)) {
            newProperties.tableSizeWide = properties.tableSizeWide;
            updatePanelsRequired = true;
        }
        if (Object.prototype.hasOwnProperty.call(properties, 'panelOrientation') &&
        (properties.panelOrientation !== newProperties.panelOrientation)) {
            newProperties.panelOrientation = properties.panelOrientation;
            updatePanelsRequired = true;
        }
        let moduleIdPropertyUpdated = false;
        if (properties.hasOwnProperty('moduleProperties')) {
            if (
                properties.moduleProperties.hasOwnProperty('moduleId') &&
                newProperties.moduleProperties.moduleId !== properties.moduleProperties.moduleId
            ) {
                newProperties.moduleProperties.moduleId = properties.moduleProperties.moduleId;
                newProperties.moduleProperties.moduleMake = properties.moduleProperties.moduleMake;
                newProperties.moduleProperties.moduleSize = properties.moduleProperties.moduleSize;
                newProperties.moduleProperties.panelProperties = properties.moduleProperties.panelProperties;

                if (
                    newProperties.moduleProperties.moduleLength !== parseFloat(properties.moduleProperties.moduleLength) ||
                    newProperties.moduleProperties.moduleWidth !== parseFloat(properties.moduleProperties.moduleWidth)
                ) {
                    updatePanelsRequired = true;
                }
                
                moduleIdPropertyUpdated = true
                newProperties.moduleProperties.moduleLength = parseFloat(properties.moduleProperties.moduleLength);
                newProperties.moduleProperties.moduleWidth = parseFloat(properties.moduleProperties.moduleWidth);
            }
        }
        let tablePositionFromChildrens;
        tablePositionFromChildrens = this.getChildren()[0].getChildren()[0];
        newProperties.tablePosition = this.getPositionForObjects(tablePositionFromChildrens);


        if(updatePanelsRequired) {
            const newGroundMount = this.createNewGroundMountTable(newProperties);
            newGroundMount.getTables()[0].canvasName = this.getTables()[0].canvasName;
            newGroundMount.makeCanvasText();
            newGroundMount.removeOutlinePoints()
            newGroundMount.createConvexHull()
            this.previousChildrens = newGroundMount.getChildren();
            newGroundMount.createBoundaryFromBB();
            this.newGroundMount = newGroundMount;
            if (newGroundMount.getChildren()[0].getChildren()[0].placeObject(newProperties.tablePosition.x, newProperties.tablePosition.y, newProperties.tablePosition.z)) {
                newGroundMount.saveState();
                newGroundMount.getChildren()[0].saveState();
                newGroundMount.getChildren()[0].getChildren()[0].saveState();
                newGroundMount.mergeGeometriesForAllPanels();
                newGroundMount.hideIndividualPanelMeshes();
                this.removeObject();
            }
            else {
                console.error('Error while updating GroundMount');
                this.stage.selectionControls.setSelectedObject(this.stage.ground)
            }

        }
    }
    getPositionForObjects(table) {
        if (!Array.isArray(table)) {
            return utils.getCentroidOfObjects([table]);
        }
        return utils.getCentroidOfObjects(table);
    }

    static getObjectType() {
        return 'GroundMount';
    }

    makeCanvasText() {
        this.getTables()[0].makeCanvasText();
    }
}