import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';

export function lerpDrawLine(start, end, line, duration = 100) {
    const current = { x: start.x, y: start.y, z: start.z };
    const tween = new TWEEN.Tween(current);
    const { x, y } = end;
    tween.to({ x, y }, duration);
    tween.onUpdate(() => {
        line.geometry.setPositions([
            start.x, start.y, start.z,
            current.x, current.y, current.z,
        ]);
    });
    tween.start();
    const promise = new Promise(resolve => tween.onComplete(resolve));
    return promise;
}

export async function animateClippingPlaneGroundUp(stage) {
    await stage.switchTo3d();
    let clippingPlane = new THREE.Plane(new THREE.Vector3(0, 0, -1), 0);
    stage.rendererManager.renderer.clippingPlanes = [clippingPlane];
    // use threejs keyframes to animate the clipping plane going from z = 0 to z = 10
    const duration = 2000;
    const easing = TWEEN.Easing.Linear.None;
    let p = {x : 0};
    const maxHeight = 50;
    const tween = new TWEEN.Tween(p)
        .to({x : maxHeight}, duration)
        .easing(easing)
        .onUpdate(() => {
            clippingPlane.constant = p.x;
            stage.rendererManager.renderer.clippingPlanes = [clippingPlane];
        }
    );


    tween.onComplete(() => {
        stage.rendererManager.renderer.clippingPlanes = [];
        // wait for a few seconds before switching back to 2d
        setTimeout(() => {
            stage.switchTo2d();
        }, 200);
    });

    tween.start();        
}
