import axios from "axios";

export default {
  FETCH_PAYMENT_METHOAD_TYPE(reqObj) {
    return axios.get(`/api/financial/payment-methods/?${reqObj}`);
  },
  GET_DETAIL_OF_PAYMENT_METHOAD(paymentMethoadID){
    return axios.get(`/api/financial/payment-methods/${paymentMethoadID}/`);
  },
  UPDATE_PAYMENT_METHOAD(updateObj,paymentMethoadID){
    return axios.patch(`/api/financial/payment-methods/${paymentMethoadID}/`,updateObj);
  },
  CHANGE_MODE(organisationId,updateMode) {
    return axios.patch(`/api/organisations/${organisationId}/`, updateMode);
  },
  GET_MODE(organisationId) {
    return axios.get(`/api/organisations/${organisationId}/`);
  },
  POST_NEW_FINANCIAL(postData) {
    return axios.post("/api/financial/payment-methods/", postData);
  },
  DELETE_FINANCIAL(financialId) {
    return axios.delete(`/api/financial/payment-methods/${financialId}/`);
  },
  LOAD_MORE_FINANCIAL(url) {
    return axios.get(url);
  },
  GET_ALL_EXERNAL_INTEGRATIONS(){
    return axios.get(`api/organisation-settings/external-integrations/`)
  },
  GET_ALL_EXERNAL_INTEGRATIONS_V2(){
    return axios.get(`api/financier/all-financier/`)
  },
  GET_ALL_EXERNAL_INTEGRATIONS_STATUS(){
    return axios.get(`api/organisation-settings/external-integrations/all-financier/`)
  },
  GET_GOODLEAP_DETAILS(){
    return axios.get(`api/organisation-settings/external-integrations/goodleap/`)
  },
  UPDATE_GOODLEAP_DETAILS(postData){
    return axios.patch("api/organisation-settings/external-integrations/goodleap/", postData);
  },
  GET_GOODLEAP_USERS(){
    return axios.get(`api/financier/user-details/`)
  },
  DELETE_GOODLEAP_DETAILS(){
    return axios.delete(`api/organisation-settings/external-integrations/goodleap/`)
  },
  GET_ALL_GOODLEAP_USERS(){
    return axios.get(`api/organisation-settings/external-integrations/goodleap/users/`)
  },
  REFRESH_GOODLEAP_USERS(){
    return axios.get(`api/organisation-settings/external-integrations/goodleap/refresh/`)
  },
  GET_DEALER_DETAILS(ID){
    return axios.get(`api/organisation-settings/${ID}/`)
  },
  UPDATE_DEALER_DETAILS(ID,patchData){
    return axios.patch(`api/organisation-settings/${ID}/`,patchData)
  },
  GET_ALL_EXTERNAL_INTEGRATIONS(){
    return axios.get(`api/organisation-settings/external-integrations/`)
  },
  GET_ALL_DROPDOWN_DETAILS(financier){
    return axios.get (`api/financing/dropdown-details/?${financier}`)
  },
  GET_ALL_CHANNELS_DETAILS(data){
    return axios.get(`api/financing/goodleap/channels/?${data}`)
  },
  GET_ALL_TERMS_RATES(data){
    return axios.get(`api/financier/terms-and-rates/?${data}`)
  },
  GET_ALL_FINANCIALS_DETAILS(design_id,financier){
    return axios.get(`api/financier/${design_id}/?${financier}`)
  },
  UPDATE_FINANCIALS_DETAILS(design_id,financier,patchaData){
    return axios.patch(`api/financier/${design_id}/?${financier}`,patchaData)
  },
  GET_PROJECT_TIMELINE(design_id,financier){
    return axios.get(`api/financier/${design_id}/timeline/?${financier}`)
  },
  POST_APPLY_LOAN(design_id,financier, payload){
    return axios.post(`api/financier/${design_id}/apply-loan/?${financier}`,payload)
  },
  GET_LOAN_DETAILS(pricingId) {
    return axios.get(`/financials/payment-details/${pricingId}`)
  },
  POST_LOAN_DETAILS(payload) {
    return axios.post(`/financials/payment-details`, payload)
  },
  GET_ENIUM_FORM_DETAILS(designId) {
    return axios.get(`/api/financier/${designId}/enium/drop-down/?financier=enium`)
  },
};
