import axios from 'axios';
import {
    DATABASE_URL,
    useBetaForBifacialGeneration,
    headEnvironment
} from '../../constants';
import API from "@/services/api/";
import {getUserTokenFromLocalStorage} from "../../utils"
export default {

    async FETCH_GENERATION_NUMBERS(designId) {
        let bifacialResponse, isBifacialEnabled;
        if (useBetaForBifacialGeneration) {
            bifacialResponse = await API.DESIGNS.FETCH_BIFACIAL_INFO(designId);
            isBifacialEnabled = bifacialResponse.data.is_bifacial_enabled;
        }
        if (useBetaForBifacialGeneration && isBifacialEnabled) {
            return axios.get(`https://betaapi.thesolarlabs.com/api/designs/${designId}/generation_engine/`);  
        }
        // return axios.get(DATABASE_URL + 'api/designs/' + designId + '/generation_engine/');
        let generationResonse = await axios.post(`https://daphneprod.thesolarlabs.com/webhook/${headEnvironment}/generation`,{
            "design_id":designId,
            "token": getUserTokenFromLocalStorage()
        })
        generationResonse.data = generationResonse.data[0] // because getting data in array, so taking it out 
        return generationResonse
    },
    FETCH_PROBABILITY(designId) {
        return axios.get(DATABASE_URL + 'api/designs/' + designId + '/probabilty_distribution/');
    },

};
