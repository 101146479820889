import * as THREE from 'three';
import axios from 'axios';
import PolygonModel from "../objects/model/PolygonModel";
import { SmartroofModel } from "../objects/model/smartroof/SmartroofModel";
import Patio from "../objects/subArray/PowerPatio";
import Row from "../objects/subArray/Row";
import Table from "../objects/subArray/Table";
import * as exporters from "../utils/exporters";
import * as utils from '../utils/utils';
import * as raycastingUtils from '../utils/raycastingUtils';
import * as notificationAssistant from '../../componentManager/notificationsAssistant';
import { useDesignStore } from '../../stores/design';
import chroma from "chroma-js";
import API from '../../services/api';
import { serverBus } from '../../plugins/serverBus';
import { INIT_SOLAR_ACCESS_COLOR_BAR } from '../../componentManager/componentManagerConstants';
import PowerTable from '../objects/subArray/PowerTable';
import { COMBINER_BOX_ATTACHMENT, HIGHLIGHT_COLOR_ATTACHMENT_EDGE, PATIO_ATTACHMENT_TYPE, PATIO_HEIGHT, POWER_PATIO } from '../coreConstants';
import PowerRoofCombinerBox from '../objects/ac/PowerRoofCombinerBox';

export default {
    /**
     * create the attachment according to the attachment type
     */
    createAttachment() {
        if (this.attachmentType === PATIO_ATTACHMENT_TYPE) {
            this.currentAttachedElement = this.createNewPatioTable().getSubarray();
            // hide the attachment element (i.e patio when created until its placed)
            this.currentAttachedElement.hideMergedMeshes();
        }
        else if (this.attachmentType === COMBINER_BOX_ATTACHMENT) {
            this.currentAttachedElement = new PowerRoofCombinerBox(this.stage);
        }

        // update sappane
        this.stage.eventManager.addAttachmentMode(this.currentAttachedElement);
    },

    // initialize notifications for attachments.
    initNotification() {
        if (this.attachmentType === PATIO_ATTACHMENT_TYPE) {
            // added notification for initialization of patio mode.
            notificationAssistant.longInfo({
                title: 'Click on the highlighted area to place the patio',
                type: "",
            });
        }
    },

    async patioEdgeHeatMap() {
        const mapExporter = exporters.roofMapExporter(this.stage);
        const payload = {
            image_dimensions: {
                width: this.stage.imageDimensions.width,
                height: this.stage.imageDimensions.height,
            },
            patioEdgeMap: exporters.patioMapExporter(this.stage),
            obstructions: mapExporter.obstructions,
            polygons: mapExporter.polygons,
            longitude: this.stage.longitude,
            latitude: this.stage.latitude,
        };

        const savingNotification = notificationAssistant.loading({
            title: 'Patio Edge Irradiance Map',
            message: 'Generating Patio edge Irradiance map.',
        });

        try {
            let refOrDesignID = ""
            if (this.stage.getDesignId()) {
                refOrDesignID = this.stage.getDesignId()
            } 
            if (this.isDesignChanged|| !this.heatMapResponse) {
                // calling API for patio edge heatmap
            const response = await this.patioEdgeHeatMapApi(refOrDesignID,payload);
            this.heatMapResponse = response.data;
            this.initializeStaticStackLength();
            }
            for (let i = 0; i < this.mappingMidpoint.length; i++) {
                this.showPatioEdgeHeatMapColor(this.mappingMidpoint[i], i);
            }
            // setting boolean for heatmap called
            this.heatMapCalled = true;
            // iterating through keys that is getting from response data
            // for the count of patio edges.
            notificationAssistant.close(savingNotification);
            notificationAssistant.success({
                title: 'Patio Edge Irradiance Map',
                message: 'Patio edge Irradiance map successfully generated.',
            });

            return Promise.resolve(true);
        }
        catch (error) {
            console.error('HeatMap: updateHeatMap: Request failed.', error);
            notificationAssistant.close(savingNotification);
            notificationAssistant.error({
                title: 'Patio Edge Irradiance Map',
                message: 'Patio edge Irradiance map is failed. Try again',
            }); 
           
            return Promise.reject(error);
        }
    },
    /**
     * patio heat map api.
     * @param {*design id} designId 
     * @param {*image dimensions} data 
     * @returns api
     */
    async patioEdgeHeatMapApi(designId,data) {
        // Create a cancel token
        this.source = axios.CancelToken.source();

        // Make the API call with the cancel token
        return API.POWERMODELS.GET_PATIO_EDGE_HEATMAP(designId,data,this.source)
    },
    
    /**
     * showing patio edge heatmap color by 
     * converting the chroma color.
     * @param {edge midpoint} midpoint 
     * @param {edge index} index 
     */
    showPatioEdgeHeatMapColor(midpoint, index) {
        // getting the index of the response.
        const key = parseInt(this.outlineMap.get(`[${midpoint.x}, ${midpoint.y}]`));

        const value = this.heatMapResponse[key];
                
        // Get the color from Chroma.js color scale
        const chromaColor = this.getPatioEdgeIrradianceChroma();

        // convert chroma color into rgb format for furthur calculation
        const rgbArray = chromaColor(value).rgb();

        // Convert RGB values to positive integers (assuming they are in the range 0-255)
        const positiveRGBArray = rgbArray.map(value => Math.max(0, Math.round(value)));

        // Convert RGB values to a decimal number with 0x prefix
        const decimalValue = (positiveRGBArray[0] << 16) | (positiveRGBArray[1] << 8) | positiveRGBArray[2];
        
        // Update the color of the instanced mesh
        const color = new THREE.Color(decimalValue);
        this._instancedMesh.setColorAt(index, color);
        this._instancedMesh.instanceColor.needsUpdate = true;
    },
    /**
     * initializing chroma colors for patio edge irradiance & 
     * emitting the solar access color bar data.
     * @returns solaraccess color map for patio edge.
     */
    getPatioEdgeIrradianceChroma() {
        // irradiance map color chart for patio edges
        const solarAccessColors = [
            "#ff0000",
            "#ffdd00",
            "#ffff00",
            "#b3ff00",
            "#00ff00"
        ];
        const solarAccessColorMap = chroma
            .scale(solarAccessColors)
            .domain([0, 0.25, 0.5, 0.75, 1.0])
            .gamma(4);

        // emitting the solar access color bar data for patio
         serverBus.$emit(
            INIT_SOLAR_ACCESS_COLOR_BAR,
            solarAccessColorMap,
            0.5, 1.0, 0.0165,
        );

        return solarAccessColorMap;
    },

    // move the attachment wrt edge
    moveAttachedElement(mousePoint) {
        // const mousePoint = this.stage.mousePoint;
        // finding the perpendicular distance from mousepoint to edge
        // distance: is storing the perpendicular distance from the point on edge to mousepoint 
        const distance = this.getDistanceBetweenLineAndPoint(this.instanceEdgeInfo[this.highLightedIndex].lineEquation, mousePoint)

        // getting azimuth of the selected edge
        const azimuth = this.instanceEdgeInfo[this.highLightedIndex].azimuth;

        // pointOnEdge : point where the attachment place. 
        const pointOnEdge = new THREE.Vector3(
            mousePoint.x - (distance * Math.cos(utils.deg2Rad(90 + azimuth))),
            mousePoint.y + (distance * Math.sin(utils.deg2Rad(90 + azimuth))),
            0
        );

        // length of attachment
        let halfLength = this.getAttachedElementDimension().length / 2;
        if(this.currentAttachedElement instanceof PowerRoofCombinerBox) halfLength -= 0.01;

        // finding the centre position of attachment
        const attachmentPosition = this.getAttachmentElementPosition();

        // finding the offset of centre of attachment & centre of edge
        const resultVector = new THREE.Vector3();
        resultVector.subVectors(pointOnEdge, attachmentPosition);

        // to set z position of the attached element
        this.currentAttachedElement.placeObject(
            resultVector.x + (halfLength * Math.sin(utils.deg2Rad(azimuth))),
            resultVector.y + (halfLength * Math.cos(utils.deg2Rad(azimuth))),
            false,
        );
        this.offsetForPosition = { 
            x :resultVector.x + (halfLength * Math.sin(utils.deg2Rad(azimuth))),
            y : resultVector.y + (halfLength * Math.cos(utils.deg2Rad(azimuth)))
        };
    },

    // rotate the attachment wrt edge
    async rotateAttachedElement(mousePoint) {
        // roatation angle for the attached element
        const angle = this.instanceEdgeInfo[this.highLightedIndex].azimuth;

        // TODO: remove this comment Ankit's code and make this general
        this.currentAttachedElement.attachedPatioEdge = this.instanceEdgeInfo[this.highLightedIndex].attachedEdge;

        // showing the mesh for the placed Attached element
        if(this.currentAttachedElement instanceof Patio)
            this.currentAttachedElement.showMesh();

        // properties to be updated of the attachment for rotation
        const updateProperties = {
            azimuth: angle,
            attachedPatioEdge: this.instanceEdgeInfo[this.highLightedIndex].attachedEdge,
            powerModelType: POWER_PATIO,
            offsetForPosition : this.offsetForPosition,
        }

        if(this.currentAttachedElement instanceof PowerRoofCombinerBox){
            this.currentAttachedElement.rotateObjectHelper(utils.deg2Rad(this.currentAttachedElement.azimuth-angle), this.currentAttachedElement.getPosition());
            return;
        }

        const placingInformation = this.currentAttachedElement.getTables()[0].getPlacingInformationWhilePlacing(mousePoint);
        const errorsWhilePlacing = placingInformation.errors;
        if (errorsWhilePlacing.length !== 0) {
            this.currentAttachedElement.removeObject(
                undefined,
                undefined, { objectSelected: true },
            );
            this.stage.eventManager.customErrorMessage(errorsWhilePlacing[0].message, 'Table');
            return errorsWhilePlacing[0];
        }
        else {
            // adding the number of placed attachments
            // in the scene to an array
            const { currentAttachment, intersectedAttachments } = await this.currentAttachedElement.updateObjectAndReturn(updateProperties, true);
            if (currentAttachment && currentAttachment.getChildren().length > 0) {
                currentAttachment.createBoundaryFromBB();
                this.attachments.push(currentAttachment);
                this.attachToModel(currentAttachment);
            }
            if (intersectedAttachments) this.intersectedAttachments.push(...intersectedAttachments);
        }
    },

    attachToModel(object) {
        const model = this.instanceEdgeInfo[this.highLightedIndex].model;
        model.attachedObjects.addAttachment(object);
    },

    /**
     * saving the attachment states for undo/redo
     */
    saveAttachmentStates() {
        this.stage.stateManager.stopTempStack();
        this.stage.stateManager.startContainer()

        this.intersectedAttachments.forEach(ele => {
            ele.GazeboRemoved.forEach((gazebo) => {
                gazebo.addTableMode = false;
                gazebo.showMergedMeshes();
                gazebo.removeObject();
                if(this.attachments.includes(gazebo)) this.attachments.splice(this.attachments.indexOf(gazebo), 1);
            });
            ele.subarrayToBeUpdated.forEach(subarray => subarray.removeObject());
        })

        this.attachments.forEach(attachments => {
            attachments.hasNewId = true;
            attachments.saveStateAttachments();
        })
        this.generalObjects.forEach(obj=>{
            obj.saveState();
        })
        this.stage.stateManager.stopContainer();
    },

    // updating the colors for the edges
    edgeColorUpdate(mousePoint) {
        const objects = raycastingUtils.getAllMeshesInScene(mousePoint, this.stage, undefined, this.meshObjectGroup.children, 0.0001);
        if (objects.length > 0) {
            // do not uncomment or remove the code below.
            // this.setColorAt(objects[0].instanceId, new THREE.Color(HIGHLIGHT_COLOR_ATTACHMENT_EDGE));
            this.highLightedIndex = objects[0].instanceId;
        }
        else {
             // do not uncomment or remove the code below.
            // this.setDefaultColor();
            this.highLightedIndex = null;
        }
    },

    // attached element dimensions
    getAttachedElementDimension() {
        return this.currentAttachedElement.getDimensions();
    },

    getAttachmentElementPosition() {
        return this.currentAttachedElement.getPosition();
    },

    // this is currently used for getting perpenndicular distance between mousepoint and edge
    getDistanceBetweenLineAndPoint(lineConstants, point) {
        return((lineConstants.Xcoefficient * point.x) + (lineConstants.Ycoefficient * point.y) + 
        (lineConstants.constant)) / (Math.sqrt(Math.pow(lineConstants.Xcoefficient, 2) + Math.pow(lineConstants.Ycoefficient, 2)));
    },

    /**
     * @param {edgePoint1} v1 
     * @param {edgePoint2} v2 
     * @returns {Xcoefficient,Ycoefficient,constant for calculating line equation}
     */
    findLineEquationForEdges(v1,v2) {
        return {
            Xcoefficient: v1.y - v2.y,
            Ycoefficient: v2.x - v1.x,
            constant: v1.x * (v2.y - v1.y) - v1.y * (v2.x - v1.x)
        }
    },

    getPositionAndLengthForInstanceMesh(array, currentAttachedElement = null) {
        const railsInfo = [];
        for (let i = 0; i < array.length; i++) {
            const edge = [
                new THREE.Vector3(array[i][0].point1.x, array[i][0].point1.y, array[i][0].point1.z),
                new THREE.Vector3(array[i][0].point2.x, array[i][0].point2.y, array[i][0].point2.z)
            ];
            const vector1 = new THREE.Vector3(array[i][0].point1.x, array[i][0].point1.y, array[i][0].point1.z);
            const vector2 = new THREE.Vector3(array[i][0].point2.x, array[i][0].point2.y, array[i][0].point2.z);
            const width = currentAttachedElement ? currentAttachedElement.getDimensions().width : this.getAttachedElementDimension().width;
            const data = {
                length: vector1.clone().distanceTo(vector2) - width,
                position: this.getPerpendicularPoint(vector1.clone(), vector2.clone(), array[i][0].azimuth, currentAttachedElement),
                positionForCorners: this.getPerpendicularPoint(vector1.clone(), vector2.clone(), array[i][0].azimuth, currentAttachedElement),
                azimuth: parseFloat(array[i][0].azimuth),
                midpoint: vector1.clone().add(vector2.clone()).multiplyScalar(0.5),
                attachedEdge: edge,
                lineEquation: this.findLineEquationForEdges(vector1.clone(), vector2.clone()),
                model: array[i][0].model,
            }
            if (data.length > 0) {
                railsInfo.push(data);
            }
        }
        return railsInfo;
    },

    getPerpendicularPoint(vector1, vector2, azimuth, attachedElement = null) {
        // Calculate the midpoint of the line
        const midpoint = vector1.clone().add(vector2.clone()).multiplyScalar(0.5);
        const halfLength = attachedElement ? attachedElement.getDimensions().length / 2 : this.getAttachedElementDimension().length / 2;
        const finalPoint = new THREE.Vector3(
            midpoint.x + (halfLength * Math.sin(utils.deg2Rad(azimuth))),
            midpoint.y + (halfLength * Math.cos(utils.deg2Rad(azimuth))),
            0
        )
        return finalPoint;
    },

    getAllEdgesWithAzimuthInScene() {
        const children = this.stage.ground.getChildren();

        const edges = [];
        children.forEach(ele => {
            if (ele instanceof SmartroofModel) {
                ele.outerEdgeObjects.forEach(edge => {
                    if (edge.point2.z >= PATIO_HEIGHT && edge.point1.z >= PATIO_HEIGHT) {
                        const obj = {
                            point1: edge.point1,
                            point2: edge.point2,
                            azimuth: edge.smartRoofFace.azimuth,
                            model: ele,
                        }
                        edges.push(obj);
                    }
                })
            }
            if (ele instanceof PolygonModel) {
                const azimuth = this.getPolygonEdgeWithAzimuth(ele);
                edges.push(...azimuth);
            }
        })
        return edges;
    },

    getPolygonEdgeWithAzimuth(model) {
        const vertices = model.get3DVertices();
        if (vertices.length === 0) {
            return [];
        }
        if (utils.checkClockwise(vertices)) {
            vertices.reverse();
        }

        // getting normal for each pair
        vertices.push(vertices[0]);
        const edgesWithAzimuth = [];
        for (let idx = 0; idx < vertices.length - 1; idx += 1) {
            let angle = utils.toDegrees(Math.atan2(
                (vertices[idx + 1][1] - vertices[idx][1]), -(vertices[idx + 1][0] - vertices[idx][0]),
            ));
            // atan2 returns between -pi and pi and we want between 0 and 360. 0 being in North
            const v1 = utils.convertArrayToVector3(vertices[idx]);
            const v2 = utils.convertArrayToVector3(vertices[idx + 1]);
            if (angle < 0) angle += 360;
            if (v1.z >= PATIO_HEIGHT && v2.z >= PATIO_HEIGHT)
            edgesWithAzimuth.push({
                azimuth: angle.toFixed(2),
                point1: v1,
                point2: v2,
                model: model,
            });
        }
        return edgesWithAzimuth;
    },


    // TODO: try to move this in powerpatio or create a generalized function for all attachments
    // functions specific to Patios
    createNewPatioTable() {
        const newPatio = this.createNewPatio();
        this.currentAttachedElement = newPatio;
        const templateTableMap = newPatio.getTemplateTableMap({ withBBox: true });
        templateTableMap.hidden = false;
        templateTableMap.isMoved = true;
        const newTable = new PowerTable(this.stage, templateTableMap, { withoutContainer: false }, false);
        newTable.hideIndividualMesh();
        newTable.clickToAdd = true;
        newPatio.getChildren()[0].addChild(newTable);
        // updating the panels id for the patio as the table
        // was added without updating the ids of panels
        const panels = newTable.getChildren();
        for (let i = 0, l = panels.length; i < l; i += 1) {
            panels[i].setId(newPatio.getPanelId());
        }
        return newTable;
    },

    /**
     * creating new patio from powerpatio class
     * and changing its properties
     * @returns new Patio
     */
    createNewPatio() {
        const newPatio = new Patio(this.stage);
        this.startingParent.addChild(newPatio);
        newPatio.associatedModel = this.startingParent;
        newPatio.createBoundaryFromParent();
        newPatio.addTableFlow = true;

        if (this.attachmentProperties) {
            newPatio.changePropertiesDuringCreation(this.attachmentProperties);
            newPatio.createBoundaryFromParent();
        }

        const rowMap = {
            id: 0,
            frames: [],
        };
        const row = new Row(this.stage, rowMap, { withoutContainer: false }, true, newPatio.isPowerTable);
        newPatio.addChild(row);
        row.saveState({ withoutContainer: false });
        newPatio.addTableMode = true;

        return newPatio;
    },

    /**
     * checking if the design is being changed in scene.
     * @returns undo stack
     */
    checkIfDesignChanged() {
        const undoStackCheck = this.stage.stateManager.undoStack.length === this.undoStackLength;
        return !undoStackCheck;
    },

    /**
     *   store the undo stack length as statemanager undostacklength.
     */
    initializeStaticStackLength() {
        this.undoStackLength = this.stage.stateManager.undoStack.length;
    }
}