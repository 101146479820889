import API from '../services/api';
import { defineStore } from 'pinia'
import { useStudioStageStore } from "./studio-stage"
import { useStudioSideBarStore } from "./studio-sideBar"
import { useStudioTextTopBarStore } from "./studio-topBar"
import { useStudioSapPaneStore } from "./studio-sapPane"
import { useStudioTextToolBarStore } from "./studio-textToolBar"
import { useStudioStatusBarStore } from "./studio-statusBar"
import { useMapImagesStore } from './mapImages';
import { useDesignStore } from './design';
import { useTilesStore } from './tilesStore';
import { getConstModuleProperties, getModuleProperties } from '../core/utils/utils';

function getInitialState() {
    return {
        solarAccess: {
            loading: false,
        },
        sunSimulation: {
            enabled: false,
            sliderTime:
                Date.now() - (parseInt(Date.now() / 24 / 60 / 60 / 1000, 10) * 24 * 60 * 60 * 1000),
            pickerTime:
                Date.now() - (parseInt(Date.now() / 24 / 60 / 60 / 1000, 10) * 24 * 60 * 60 * 1000),
            sliderDate: parseInt(Date.now() / 24 / 60 / 60 / 1000, 10) * 24 * 60 * 60 * 1000,
            pickerDate: parseInt(Date.now() / 24 / 60 / 60 / 1000, 10) * 24 * 60 * 60 * 1000,
            dayRunning: false,
            yearRunning: false,
            timer: null,
        },
        lidarSwitchEnabled:false,
        isGoogle3dSwitchEnabled: false,
        isFitToTilesEnabled: false,
        generationData: {
            intervalId: null,
            lastUpdated: 0,
            annualGeneration: 0,
            specificGeneration: 0,
            performanceRatio: 0,
            offset:0,
            lossData: {
                energy: {},
                losses: {},
            },
        },
        powerModels: {
            patios: [],
            gazebos: [],
        },
        isCustomPGPPAllowed: false,
        isInfinitePanelLoaderOpen: false,
        isVideoRecording: false,
    };
}

export const useStudioStore = defineStore('studio', {
    state: () => getInitialState(),
    getters: {
        GET_CURRENT_GENERATION: (state) => {
            return state.generationData;
        },
        GET_POWER_MODELS: (state) => {
            return state.powerModels;
        },
        GET_IS_CUSTOM_PGPP_ALLOWED: (state) => {
            return state.isCustomPGPPAllowed;
        }
    },
    actions: {
        SET_CUSTOM_PGPP_ALLOWED(val) {
            this.isCustomPGPPAllowed = val;
        },
        async SET_POWER_MODEL(isUsedIn3DLink = false) {
            const modules = new Map();
            const panels = new Map();
            try {
                // const patioResponse = await API.POWERMODELS.GET_PATIOS();
                // const gazeboResponse = await API.POWERMODELS.GET_GAZEBOS();
                await API.POWERMODELS.GET_ARKA_SOLUTION_MODELS().then((arkaSolutionModels)=>{
                    this.UPDATE_POWERMODELS(arkaSolutionModels.data);
                });
                const ids = new Set([...this.powerModels.patios.with_Inverter, ...this.powerModels.patios.without_Inverter, ...this.powerModels.gazebos.with_Inverter, ...this.powerModels.gazebos.without_Inverter].map(ele => ele.module.id))
                for(let id of ids) {
                    if(isUsedIn3DLink){
                        const {panelProp, moduleProp} = getConstModuleProperties(id);
                        modules.set(id, moduleProp);
                        panels.set(id, panelProp);
                    }
                    else{
                        const {panelProp, moduleProp} = await getModuleProperties(id);
                        modules.set(id, moduleProp);
                        panels.set(id, panelProp);
                    }
                }
                for (let i = 0; i < this.powerModels.patios.with_Inverter.length; i++) {
                    const moduleId = this.powerModels.patios.with_Inverter[i].module.id;
                    this.powerModels.patios.with_Inverter[i].module = modules.get(moduleId);
                    this.powerModels.patios.with_Inverter[i].panel = panels.get(moduleId);
                }
                for (let i = 0; i < this.powerModels.gazebos.with_Inverter.length; i++) {
                    const moduleId = this.powerModels.gazebos.with_Inverter[i].module.id;
                    this.powerModels.gazebos.with_Inverter[i].module = modules.get(moduleId);
                    this.powerModels.gazebos.with_Inverter[i].panel = panels.get(moduleId);
                }
                for (let i = 0; i < this.powerModels.patios.without_Inverter.length; i++) {
                    const moduleId = this.powerModels.patios.without_Inverter[i].module.id;
                    this.powerModels.patios.without_Inverter[i].module = modules.get(moduleId);
                    this.powerModels.patios.without_Inverter[i].panel = panels.get(moduleId);
                }
                for (let i = 0; i < this.powerModels.gazebos.without_Inverter.length; i++) {
                    const moduleId = this.powerModels.gazebos.without_Inverter[i].module.id;
                    this.powerModels.gazebos.without_Inverter[i].module = modules.get(moduleId);
                    this.powerModels.gazebos.without_Inverter[i].panel = panels.get(moduleId);
                }

                // sort the list
                // this.powerModels.gazebos.sort((a,b) => a.id - b.id);
                // this.powerModels.patios.sort((a,b) => a.id - b.id);
            }
            catch (error) {
                throw error;
            }
        },
        async FETCH_GENERATION(designId) {
            try {
                const response =
                    await API.UTILS.FETCH_GENERATION_NUMBERS(designId);
                this.UPDATE_GENERATION_DATA(response);
            }
            catch (error) {
                throw error;
            }
        },
        async FETCH_PROBABILITY(designId) {
            try {
                const response =
                    await API.UTILS.FETCH_PROBABILITY(designId);
                return response;
            }
            catch (error) {
                throw error;
            }
        },

        RESET_STATE() {
            Object.assign(this, getInitialState());
            useStudioStageStore().$reset()
            useStudioSideBarStore().$reset()
            useStudioTextTopBarStore().$reset()
            useStudioSapPaneStore().$reset()
            useStudioTextToolBarStore().$reset()
            useStudioStatusBarStore().$reset()
            useMapImagesStore().$reset();
            useDesignStore().$reset();
            useTilesStore().$reset();
        },
        SOLAR_ACCESS_LOADING(loading) {
            this.solarAccess.loading = loading;
        },
        SET_GOOGLE3D_STATUS(enable) {
            this.isGoogle3dSwitchEnabled = enable;
        },
        SET_FITGOOGLE3D_STATUS(enable) {
            this.isFitToTilesEnabled = enable;
        },
        SET_LIDAR_STATUS(enabled) {
            if(!this.lidarSwitchEnabled && !this.sunSimulation.enabled ){
                this.lidarSwitchEnabled = enabled;
            }
            //when lidar false and sun simulation is true
            else if(!this.lidarSwitchEnabled && this.sunSimulation.enabled){
                this.lidarSwitchEnabled = enabled;
                this.sunSimulation.enabled = !enabled;
            }
            //when lidar is true and sun simulation is false
            else if(this.lidarSwitchEnabled && !this.sunSimulation.enabled){
                this.lidarSwitchEnabled = enabled;
                // this.sunSimulation.enabled = !enabled;
            }
        },
        SET_SUN_SIMULATION_STATUS(enabled) {
            if(!this.lidarSwitchEnabled && !this.sunSimulation.enabled ){
                this.sunSimulation.enabled = enabled;
            }
             //when lidar false and sun simulation is true
             else if(!this.lidarSwitchEnabled && this.sunSimulation.enabled){
                // this.lidarSwitchEnabled = !enabled;
                this.sunSimulation.enabled = enabled;
            }
            //when lidar is true and sun simulation is false
            else if(this.lidarSwitchEnabled && !this.sunSimulation.enabled){
                this.lidarSwitchEnabled = !enabled;
                this.sunSimulation.enabled = enabled;
            }
        },
        SET_SLIDER_TIME(sliderTime) {
            this.sunSimulation.sliderTime = sliderTime;
        },
        SET_PICKER_TIME(pickerTime) {
            this.sunSimulation.pickerTime = pickerTime;
        },
        SET_SLIDER_DATE(sliderDate) {
            this.sunSimulation.sliderDate = sliderDate;
        },
        SET_PICKER_DATE(pickerDate) {
            this.sunSimulation.pickerDate = pickerDate;
        },
        SET_DAY_RUNNING(dayRunning) {
            this.sunSimulation.dayRunning = dayRunning;
        },
        SET_YEAR_RUNNING(yearRunning) {
            this.sunSimulation.yearRunning = yearRunning;
        },
        SET_TIMER(timer) {
            this.sunSimulation.timer = timer;
        },
        UPDATE_GENERATION_DATA(response) {
            this.generationData.annualGeneration =
                response.data.monthly.ac.reduce((a, b) => a + b, 0) / 1000;
            this.generationData.specificGeneration =
                response.data.monthly.specific_generation.reduce((a, b) => a + b, 0);
            this.generationData.performanceRatio =
                response.data.monthly.pr.reduce((a, b) => a + b, 0) / 12;
            this.generationData.lossData.losses = response.data.losses;
            this.generationData.lossData.energy = response.data.energy;
            this.generationData.offset = response.data.offset;
            if (this.generationData.intervalId !== null) {
                clearInterval(this.generationData.intervalId);
            }
            this.generationData.lastUpdated = 0;
            this.generationData.intervalId = setInterval(() => {
                this.generationData.lastUpdated += 1;
            }, 60000);
            this.generationData.dcSize = response.data.energy.dc.dc_size;
        },
        UPDATE_PROBABILITY_DATA(response) {
            this.probabilty_distribution = response
        },
        UPDATE_POWERMODELS(arkaSolutionModels) {
            const gazeboList = arkaSolutionModels.PowerGazebo;
            const patioList = arkaSolutionModels.PowerPatio;
            this.powerModels.gazebos.with_Inverter = gazeboList.with_inverter;
            this.powerModels.gazebos.without_Inverter = gazeboList.without_inverter;
            this.powerModels.patios.with_Inverter = patioList.with_inverter;
            this.powerModels.patios.without_Inverter = patioList.without_inverter;
        }
    },
});